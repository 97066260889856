import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Flex, 
  FormControl, 
  Input, 
  Link, 
  Text, 
  VStack, 
  useToast, 
  Select,
  FormLabel 
} from '@chakra-ui/react';
import { countryOptions } from 'types';
import { validateEmail, validatePhoneNumber, validateInstagram } from 'services/verificationService';
import { checkInstagramVerification, sendPhoneVerificationCode } from 'services/firebaseService';
import InstagramVerificationModal from './components/InstagramVerificationModal';
import { registerNewUser, sendInfluencerApplicationSubmittedNotifications } from 'services/flaskService';
import { VerificationContext } from 'contexts/PhoneVerificationContext';
import { formatPhoneNumber } from 'utils/authUtils';
const SignUpApplicationPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmPhoneNumber, setConfirmPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState<string>('+1');
  const [phoneError, setPhoneError] = useState('');
  const [isInstagramVerified, setIsInstagramVerified] = useState(false);
  const [isInstagramModalOpen, setIsInstagramModalOpen] = useState(false);
  const [isWaitingForInstagramVerification, setIsWaitingForInstagramVerification] = useState(false);
  const { setConfirmationResult } = useContext(VerificationContext);

  
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const postSignInPageInfo = useLocation().state;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  useEffect(() => {
    const checkVerification = async () => {
      if (isWaitingForInstagramVerification && isInstagramVerified) {
        setIsWaitingForInstagramVerification(false);
        await handleSubmit();
      }
    };

    checkVerification();
  }, [isInstagramVerified, isWaitingForInstagramVerification]);

  const handleVerificationSuccess = () => {
    setIsInstagramVerified(true);
  };

  const handleSubmit = async () => {
    // Validate email
    if (!validateEmail(email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Validate Instagram
    if (!validateInstagram(instagram)) {
      toast({
        title: "Error",
        description: "Please enter a valid Instagram handle.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Check if Instagram is already verified
    const isVerified = await checkInstagramVerification(instagram.replace('@', ''));
    if (!isVerified) {
      // Show verification modal and set waiting state
      setIsWaitingForInstagramVerification(true);
      setIsInstagramModalOpen(true);
      return;
    }

    // Validate names
    if (!firstName || !lastName) {
      toast({
        title: "Error",
        description: "Please enter both first and last name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const fullPhoneNumber = formatPhoneNumber(phoneNumber, countryCode);
    const fullConfirmPhoneNumber = formatPhoneNumber(confirmPhoneNumber, countryCode);

    if (fullPhoneNumber !== fullConfirmPhoneNumber) {
      setPhoneError("Phone numbers don't match");
      return;
    }

    if (!validatePhoneNumber(fullPhoneNumber)) {
      setPhoneError("Please enter a valid phone number");
      return;
    }

    setPhoneError('');

    setIsLoading(true);
    try {
      const result = await registerNewUser({
        email,
        firstName,
        lastName,
        instagramHandle: instagram,
        tiktokHandle: tiktok,
        phoneNumber: fullPhoneNumber
      });

      if (result.success) {
        sendInfluencerApplicationSubmittedNotifications(email, instagram);
        const result = await sendPhoneVerificationCode(fullPhoneNumber);
        setConfirmationResult(result);
        navigate('/verification', { state: postSignInPageInfo })
      } else {
        toast({
          title: "Error",
          description: result.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Sign up error:', error);
      toast({
        title: "Error",
        description: "An error occurred during sign up. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box minH="100vh" display="flex" flexDirection="column" bg="white" p="4" position="relative">
      <Button 
        onClick={() => navigate('/login')} 
        position="absolute"
        top="16px"
        left="16px"
        variant="ghost"
      >
        &larr;
      </Button>
      
      <VStack 
        spacing="6" 
        width="100%" 
        maxW="400px"
        mx="auto"
        flex="1"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        py="8"
      >
        {/* Top section */}
        <VStack spacing="6" width="100%">
          <Text fontSize="2xl" fontWeight="bold">Apply to Join Foodfluence</Text>
          
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input 
              type="email" 
              bg="#F7F8F9" 
              value={email} 
              onChange={e => setEmail(e.target.value)} 
              placeholder="Email" 
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>First Name</FormLabel>
            <Input 
              type="text" 
              bg="#F7F8F9" 
              value={firstName} 
              onChange={e => setFirstName(e.target.value)} 
              placeholder="First Name" 
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input 
              type="text" 
              bg="#F7F8F9" 
              value={lastName} 
              onChange={e => setLastName(e.target.value)} 
              placeholder="Last Name" 
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Instagram Handle</FormLabel>
            <Box position="relative">
              <Input 
                type="text" 
                bg="#F7F8F9" 
                value={`@${instagram}`}
                onChange={e => setInstagram(e.target.value.replace('@', '').toLowerCase())}
                placeholder="@username" 
              />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel>TikTok Handle (Optional)</FormLabel>
            <Input 
              type="text" 
              bg="#F7F8F9" 
              value={`@${tiktok}`}
              onChange={e => setTiktok(e.target.value.replace('@', '').toLowerCase())}
              placeholder="@username" 
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Phone Number</FormLabel>
            <Flex>
              <Select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                width="31%"
                bg="#F7F8F9"
                mr={2}
              >
                {countryOptions.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.code}
                  </option>
                ))}
              </Select>
              <Input
                type="tel"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                bg="#F7F8F9"
              />
            </Flex>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Confirm Phone Number</FormLabel>
            <Flex>
              <Select
                value={countryCode}
                isDisabled
                width="31%"
                bg="#F7F8F9"
                mr={2}
              >
                <option value={countryCode}>{countryCode}</option>
              </Select>
              <Input
                type="tel"
                placeholder="Confirm Phone Number"
                value={confirmPhoneNumber}
                onChange={(e) => setConfirmPhoneNumber(e.target.value)}
                bg="#F7F8F9"
              />
            </Flex>
          </FormControl>
          {phoneError && <Text color="red.500">{phoneError}</Text>}
        </VStack>

        {/* Bottom section */}
        <VStack spacing="4" width="100%">
          <Button 
            type="submit" 
            bg="black" 
            color="white" 
            width="full" 
            py="6"
            onClick={handleSubmit}
            isLoading={isLoading}
            loadingText="Submitting..."
          >
            Submit Application
          </Button>
          <Text>
            Already have an account? <Link color="green" href="/returning-user">Login Now</Link>
          </Text>
        </VStack>
      </VStack>

      <InstagramVerificationModal
        isOpen={isInstagramModalOpen}
        onClose={() => setIsInstagramModalOpen(false)}
        email={email}
        instagram={instagram}
        onVerificationSuccess={handleVerificationSuccess}
      />
    </Box>
  );
};

export default SignUpApplicationPage; 