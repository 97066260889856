import React from 'react';
import { Bundle, Influencer, InfluencerLocation, tagOptions, InfluencerProfileData } from "types";
import { parsePhoneNumber } from 'libphonenumber-js';

export const getFormattedBundleDescription = (bundle: Bundle): string => {
  const { deliverableCounts, cash } = bundle;
  const { instagramReels, instagramPosts, instagramStories, tiktoks } = deliverableCounts;

  const parts = [];
  if (instagramReels > 0) parts.push(`${instagramReels} IG Reel${instagramReels > 1 ? 's' : ''}`);
  if (instagramPosts > 0) parts.push(`${instagramPosts} IG Post${instagramPosts > 1 ? 's' : ''}`);
  if (instagramStories > 0) parts.push(`${instagramStories} IG Story Frame${instagramStories > 1 ? 's' : ''}`);
  if (tiktoks > 0) parts.push(`${tiktoks} TikTok${tiktoks > 1 ? 's' : ''}`);

  return `${parts.join(', ')} - $${cash}`;
};

// Helper function to compare rates
const ratesEqual = (formRate: number | undefined, convertedRate: number | undefined) => {
  return (formRate ?? 0) === (convertedRate ?? 0);
};

export const hasFormChanged = (influencerData: Influencer | null, formData: InfluencerProfileData | null) => {
  const convertedInfluencerData = InfluencerDataToInfluencerProfileData(influencerData);
  
  if (!convertedInfluencerData || !formData) {
    return true;
  }

  const fullPhoneNumber = `${formData.countryCode || ''}${formData.phoneNumber || ''}`;
  const convertedPhoneNumber = `${convertedInfluencerData.countryCode || ''}${convertedInfluencerData.phoneNumber || ''}`;

  // Original comparison logic remains the same
  return (
    formData.firstName !== convertedInfluencerData.firstName ||
    formData.lastName !== convertedInfluencerData.lastName ||
    formData.email_address !== convertedInfluencerData.email_address ||
    fullPhoneNumber !== convertedPhoneNumber ||
    formData.ethnicity !== convertedInfluencerData.ethnicity ||
    formData.birthday !== convertedInfluencerData.birthday ||
    formData.gender !== convertedInfluencerData.gender ||
    (formData.pronouns || []).join(',') !== (convertedInfluencerData.pronouns || []).join(',') ||
    (formData.location || []).map(loc => (
      loc.lat !== 0 && 
      loc.lng !== 0 && 
      loc.city !== '' && 
      loc.state !== '' && 
      loc.country !== '' && 
      loc.maxCollabDistance > 0
    )).every(Boolean) !== (convertedInfluencerData.location || []).map(loc => (
      loc.lat !== 0 && 
      loc.lng !== 0 && 
      loc.city !== '' && 
      loc.state !== '' && 
      loc.country !== '' && 
      loc.maxCollabDistance > 0
    )).every(Boolean) ||
    (formData.location || []).some((loc, index) => {
      const oldLoc = (convertedInfluencerData.location || [])[index];
      return !oldLoc || 
        loc.lat !== oldLoc.lat ||
        loc.lng !== oldLoc.lng ||
        loc.city !== oldLoc.city ||
        loc.state !== oldLoc.state ||
        loc.country !== oldLoc.country ||
        loc.maxCollabDistance !== oldLoc.maxCollabDistance;
    }) ||
    (formData.nonHiddenTags || []).join(',') !== (convertedInfluencerData.nonHiddenTags || []).join(',') ||
    formData.collabPreference !== convertedInfluencerData.collabPreference ||
    formData?.rateRanges?.instagramPosts?.offered !== convertedInfluencerData?.rateRanges?.instagramPosts?.offered ||
    formData?.rateRanges?.instagramReels?.offered !== convertedInfluencerData?.rateRanges?.instagramReels?.offered ||
    formData?.rateRanges?.instagramStories?.offered !== convertedInfluencerData?.rateRanges?.instagramStories?.offered ||
    formData?.rateRanges?.tiktoks?.offered !== convertedInfluencerData?.rateRanges?.tiktoks?.offered ||
    !ratesEqual(formData?.rateRanges?.gifting?.minRate, convertedInfluencerData?.rateRanges?.gifting?.minRate) ||
    !ratesEqual(formData?.rateRanges?.gifting?.maxRate, convertedInfluencerData?.rateRanges?.gifting?.maxRate) ||
    !ratesEqual(formData?.rateRanges?.instagramReels?.minRate, convertedInfluencerData?.rateRanges?.instagramReels?.minRate) ||
    !ratesEqual(formData?.rateRanges?.instagramReels?.maxRate, convertedInfluencerData?.rateRanges?.instagramReels?.maxRate) ||
    !ratesEqual(formData?.rateRanges?.instagramPosts?.minRate, convertedInfluencerData?.rateRanges?.instagramPosts?.minRate) ||
    !ratesEqual(formData?.rateRanges?.instagramPosts?.maxRate, convertedInfluencerData?.rateRanges?.instagramPosts?.maxRate) ||
    !ratesEqual(formData?.rateRanges?.instagramStories?.minRate, convertedInfluencerData?.rateRanges?.instagramStories?.minRate) ||
    !ratesEqual(formData?.rateRanges?.instagramStories?.maxRate, convertedInfluencerData?.rateRanges?.instagramStories?.maxRate) ||
    !ratesEqual(formData?.rateRanges?.tiktoks?.minRate, convertedInfluencerData?.rateRanges?.tiktoks?.minRate) ||
    !ratesEqual(formData?.rateRanges?.tiktoks?.maxRate, convertedInfluencerData?.rateRanges?.tiktoks?.maxRate) ||
    JSON.stringify(formData.bundlePricing || []) !== JSON.stringify(convertedInfluencerData.bundlePricing || []) ||
    formData.mediaKit !== convertedInfluencerData.mediaKit
  );
};

export const areProfileFieldsFilled = (formData: InfluencerProfileData) => {
  return (
    formData?.firstName?.trim() !== '' && formData?.firstName?.trim().toLowerCase() !== 'unknown' &&
    formData?.lastName?.trim() !== '' && formData?.lastName?.trim().toLowerCase() !== 'unknown' &&
    formData?.email_address?.trim() !== '' &&
    formData?.phoneNumber?.trim() !== '' &&
    formData?.countryCode?.trim() !== '' &&
    formData?.ethnicity?.trim() !== '' &&
    formData?.birthday?.trim() !== '' &&
    formData?.gender?.trim() !== '' &&
    formData?.pronouns?.length > 0 &&
    formData?.location?.length > 0 && formData?.location.every(loc => loc.lat !== 0 && loc.lng !== 0) &&
    formData?.nonHiddenTags?.length > 0 &&
    formData?.collabPreference !== null && formData?.collabPreference.trim() !== '' &&
    formData?.rateRanges?.instagramPosts?.offered !== undefined &&
    formData?.rateRanges?.instagramReels?.offered !== undefined &&
    formData?.rateRanges?.instagramStories?.offered !== undefined &&
    formData?.rateRanges?.tiktoks?.offered !== undefined
  );
};

const noSameLocation = (locations: InfluencerLocation[]) => {
  // First check that all locations have valid coordinates
  if (!locations.every(loc => loc.lat !== 0 && loc.lng !== 0)) {
    return false;
  }

  // Check for duplicates by comparing city, state, and country
  for (let i = 0; i < locations.length; i++) {
    for (let j = i + 1; j < locations.length; j++) {
      if (
        locations[i].city === locations[j].city &&
        locations[i].state === locations[j].state &&
        locations[i].country === locations[j].country
      ) {
        return false;
      }
    }
  }
  return true;
};

export const validateEmail = (newEmail_address: string) => {
  if (!newEmail_address.includes('@')) {
    return false;
  }
  return true;
}

export const isSubmitEnabled = (influencerData: Influencer | null, formData: InfluencerProfileData | null) => {
  // If formData is null, submit should be disabled
  if (!formData) {
    return false;
  }

  // media kit is optional
  const areRequiredFieldsFilled = areProfileFieldsFilled(formData);
  
  const instagramOrTiktok = 
    ((formData.rateRanges?.instagramPosts?.offered && 
      formData.rateRanges?.instagramReels?.offered && 
      formData.rateRanges?.instagramStories?.offered && 
      formData.instagramHandle?.trim() !== '') ||
    (formData.rateRanges?.tiktoks?.offered && 
      formData.tiktokHandle?.trim() !== '')) ?? false;

  const areGiftingRatesValid = 
    formData.collabPreference === 'cashOnly' || 
    ((formData.rateRanges?.gifting?.minRate ?? 0) >= 0 && 
     (formData.rateRanges?.gifting?.maxRate ?? 0) > 0 && 
     (formData.rateRanges?.gifting?.minRate ?? 0) < (formData.rateRanges?.gifting?.maxRate ?? 0));

  const areInstagramRatesValid = 
    !formData.rateRanges?.instagramReels?.offered || 
    ((formData.rateRanges?.instagramReels?.minRate ?? 0) >= 0 && 
     (formData.rateRanges?.instagramReels?.maxRate ?? 0) > 0 && 
     (formData.rateRanges?.instagramReels?.minRate ?? 0) < (formData.rateRanges?.instagramReels?.maxRate ?? 0)) &&
    ((formData.rateRanges?.instagramPosts?.minRate ?? 0) >= 0 && 
     (formData.rateRanges?.instagramPosts?.maxRate ?? 0) > 0 && 
     (formData.rateRanges?.instagramPosts?.minRate ?? 0) < (formData.rateRanges?.instagramPosts?.maxRate ?? 0)) &&
    ((formData.rateRanges?.instagramStories?.minRate ?? 0) >= 0 && 
     (formData.rateRanges?.instagramStories?.maxRate ?? 0) > 0 && 
     (formData.rateRanges?.instagramStories?.minRate ?? 0) < (formData.rateRanges?.instagramStories?.maxRate ?? 0));

  const areTiktokRatesValid = 
    !formData.rateRanges?.tiktoks?.offered || 
    ((formData.rateRanges?.tiktoks?.minRate ?? 0) >= 0 && 
     (formData.rateRanges?.tiktoks?.maxRate ?? 0) > 0 && 
     (formData.rateRanges?.tiktoks?.minRate ?? 0) < (formData.rateRanges?.tiktoks?.maxRate ?? 0));

  const areLocationsValid = 
    (formData.location?.length ?? 0) > 0 && 
    (formData.location ? noSameLocation(formData.location) : false);

  const isEmailValid = validateEmail(formData.email_address ?? '');

  return hasFormChanged(influencerData, formData) && 
         areRequiredFieldsFilled && 
         areGiftingRatesValid && 
         areInstagramRatesValid && 
         areTiktokRatesValid && 
         instagramOrTiktok && 
         areLocationsValid && 
         isEmailValid;
};

export const cashCap = 10000;

export const handleIncrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
  setter(value + 1);
};

export const handleDecrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
  if (value > 0) {
    setter(value - 1);
  }
}; 

export const getNonHiddenTags = (tags: string[]) => {
  return tags.filter(tag => tagOptions.some(option => option.value === tag));
};

export const getHiddenTags = (tags: string[]) => {
  return tags.filter(tag => !tagOptions.some(option => option.value === tag));
};

export const InfluencerDataToInfluencerProfileData = (influencerData: Influencer | null): InfluencerProfileData => {
  // Return default values if influencerData is null
  if (!influencerData) {
    return {
      firstName: '',
      lastName: '',
      email_address: '',
      phoneNumber: '',
      instagramHandle: '',
      tiktokHandle: '',
      countryCode: '',
      location: [],
      gender: '',
      pronouns: [],
      ethnicity: '',
      birthday: '',
      nonHiddenTags: [],
      collabPreference: '',
      rateRanges: {
        instagramPosts: { offered: false, minRate: 0, maxRate: 0 },
        instagramReels: { offered: false, minRate: 0, maxRate: 0 },
        instagramStories: { offered: false, minRate: 0, maxRate: 0 },
        tiktoks: { offered: false, minRate: 0, maxRate: 0 },
        gifting: { minRate: 0, maxRate: 0 }
      },
      bundlePricing: [],
      mediaKit: null
    };
  }

  const initialPhone = parsePhone(influencerData?.phoneNumber || '');
  const initialPhoneNumber = initialPhone.nationalNumber;
  const initialCountryCode = initialPhone.countryCode;
  return {
    firstName: influencerData.firstName,
    lastName: influencerData.lastName,
    email_address: influencerData.email_address,
    phoneNumber: initialPhoneNumber,
    countryCode: initialCountryCode,
    instagramHandle: influencerData.instagramHandle,
    tiktokHandle: influencerData.tiktokHandle,
    location: influencerData.location,
    gender: influencerData.gender,
    pronouns: influencerData.pronouns,
    ethnicity: influencerData.ethnicity,
    birthday: influencerData.birthday,
    nonHiddenTags: getNonHiddenTags(influencerData.tags || []),
    collabPreference: influencerData.collabPreference,
    rateRanges: influencerData.rateRanges,
    bundlePricing: influencerData.bundlePricing,
    mediaKit: influencerData.mediaKit,
  };
};

export const parsePhone = (fullNumber: string) => {
  try {
    const phoneNumber = parsePhoneNumber(fullNumber);

    if (!phoneNumber) {
      return {
        countryCode: '+1',
        nationalNumber: fullNumber.replace(/[^\d]/g, ''),
        country: 'US'
      };
    }
    return {
      countryCode: `+${phoneNumber.countryCallingCode}`,
      nationalNumber: phoneNumber.nationalNumber,
      country: phoneNumber.country
    };
  } catch (error) {
    return {
      countryCode: '+1',
      nationalNumber: fullNumber.replace(/[^\d]/g, ''),
      country: 'US'
    };
  }
};