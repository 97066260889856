import { FormControl, FormLabel, Text, Flex } from '@chakra-ui/react';
import Select from 'react-select';
import { collabPreferenceOptions } from '../../../../types';

const CollaborationPreferences = ({
  collabPreference,
  setCollabPreference,
  RequiredStar
}) => {
  return (
    <>
      <Text fontWeight="bold" fontSize="xl">Collaboration Preferences</Text>
      <FormControl>
        <Flex alignItems="center">
          <FormLabel mb="0">Preference<RequiredStar /></FormLabel>
          <Text color="gray.500" fontSize="xs" ml={-1}>(select one)</Text>
        </Flex>
        <Select
          placeholder="Select one"
          value={collabPreferenceOptions.find(option => option.value === collabPreference)}
          onChange={(option) => setCollabPreference(option.value)}
          options={collabPreferenceOptions}
        />
      </FormControl>
    </>
  );
};

export default CollaborationPreferences; 