import React, { useContext } from 'react';
import { Box, Text, Image, Flex, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CompletedCollabModal from './CompletedCollabModal';
import CanceledCollabModal from './CanceledCollabModal';
import ExpiredCollabModal from './ExpiredCollabModal';
import { Collab, Campaign } from '../../../types';
import CampaignEndedModal from './CampaignEndedModal';
import { useRandomImage } from '../../../utils/imageUtils';
import { ClientDataContext } from 'contexts/ClientDataContext';
import ClientRejectedModal from './ClientRejectedModal';

interface PastCollabComponentProps {
  collab: Collab;
  campaign: Campaign;
  influencerName: string;
}

const PastCollabComponent: React.FC<PastCollabComponentProps> = ({ collab, campaign, influencerName }) => {
  const navigate = useNavigate();
  const completedModalDisclosure = useDisclosure();
  const canceledModalDisclosure = useDisclosure();
  const expiredModalDisclosure = useDisclosure();
  const campaignEndedModalDisclosure = useDisclosure();
  const clientRejectedModalDisclosure = useDisclosure();
  const clients = useContext(ClientDataContext);
  const client = clients[campaign?.clientID];
  const { getRandomImage, randomImage } = useRandomImage();

  const handleCollabClick = () => {
    switch (collab.status) {
      case 'collabCompleted':
        completedModalDisclosure.onOpen();
        break;
      case 'collabRejected':
        canceledModalDisclosure.onOpen();
        break;
      case 'collabCanceled':
        canceledModalDisclosure.onOpen();
        break;
      case 'collabExpired':
        expiredModalDisclosure.onOpen();
        break;
      case 'clientRejected':
        clientRejectedModalDisclosure.onOpen();
        break;
      default:
        campaignEndedModalDisclosure.onOpen();
    }
  }
  
  const actionNames = {
    collabCompleted: "Completed",
    collabRejected: "Rejected",
    collabCanceled: "Canceled",
    collabExpired: "Expired",
    clientRejected: "Rejected",
  }

  return (
    <>
      <Box 
        borderWidth={1} 
        borderRadius="lg" 
        overflow="hidden" 
        boxShadow="md"  
        bg="white" 
        width="150px"
        height="200px" 
        display="flex"
        flexDirection="column"
        m={2} 
        cursor="pointer" 
        _hover={{ boxShadow: "lg" }}
        position="relative"
        onClick={handleCollabClick}
      >
        <Image 
          src={campaign?.collabImage ?? client?.bannerPicture ?? getRandomImage()} 
          height="50%" 
          width="100%" 
          objectFit="cover"
          onError={(e) => { 
            if (!randomImage) {
              e.currentTarget.src = getRandomImage();
            }
          }}
        />
        <Box p={2} flex={1} display="flex" flexDirection="column">
          <Flex direction="row" justifyContent="space-between">

          <Text color="black" mb={3} whiteSpace="normal" width="100%" noOfLines={2} wordBreak="break-word" fontSize="sm" fontWeight="bold">{client?.accountName}</Text>
          </Flex>
          <Text color={collab.status === 'collabCompleted' ? "green.500" : "gray.500"}>
            {collab.status in actionNames ? actionNames[collab.status] : "Campaign Ended"}
          </Text>
        </Box>
      </Box>
      
      <CompletedCollabModal
        isOpen={completedModalDisclosure.isOpen}
        onClose={completedModalDisclosure.onClose}
        collabId={collab.id}
        userConfirmRedemption={collab.userConfirmRedemption}
      />
      <CanceledCollabModal
        isOpen={canceledModalDisclosure.isOpen}
        onClose={canceledModalDisclosure.onClose}
        clientName={client?.accountName ?? ""}
      />
      <ExpiredCollabModal
        isOpen={expiredModalDisclosure.isOpen}
        onClose={expiredModalDisclosure.onClose}
        clientName={client?.accountName ?? ""}
      />
      <CampaignEndedModal
        isOpen={campaignEndedModalDisclosure.isOpen}
        onClose={campaignEndedModalDisclosure.onClose}
        clientName={client?.accountName ?? ""}
      />
      <ClientRejectedModal
        isOpen={clientRejectedModalDisclosure.isOpen}
        onClose={clientRejectedModalDisclosure.onClose}
        clientName={client?.accountName ?? ""}
      />
    </>
  );
}

export default PastCollabComponent;