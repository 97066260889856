import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Box, Button, Image, VStack } from '@chakra-ui/react';

const NewLoginPage: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmitNewUserInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    navigate('/new-signup');
  };

  const handleSubmitNewUserNoInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    navigate('/signup-application');
  };

  const handleSubmitReturningUser = async (e: React.FormEvent) => {
    e.preventDefault();
    navigate('/returning-user');
  };

  return (
    <Flex
      alignItems="center"
      direction="column"
      justifyContent="space-between"
      bgGradient="linear(to-t, #939393, white 50%, white)"
      p={{ base: "2", md: "4", lg: "8" }}
      height="100svh"
      width="100vw"
    >
      <Box />
      <VStack spacing={8} width="100%" maxW="400px">
        <Image 
          src="https://firebasestorage.googleapis.com/v0/b/storyitapp.appspot.com/o/touchpoints%2FFoodFluence.io%20Logo%20(461%20x%20196%20px)%20(6).png?alt=media&token=54ec9ac5-6bd9-40e1-98c4-12597c3953a1" 
          alt="FoodFluencer Logo" 
          objectFit="contain"
        />
        <Flex 
          direction="row" 
          wrap="wrap" 
          justify="center" 
          gap={4} 
          width="100%"
          mb={16}
        >
          <Button 
            bg="#2F2F2F" 
            color="white" 
            width="180px"
            height="48px"
            _hover={{ bg: "gray.700" }}
            onClick={handleSubmitReturningUser}
          >
            Returning User
          </Button>
          <Button 
            bg="#2F2F2F" 
            color="white" 
            width="180px"
            height="48px"
            _hover={{ bg: "gray.700" }}
            onClick={handleSubmitNewUserInvite}
          >
            Sign in with Invite
          </Button>
          <Button 
            bg="#2F2F2F" 
            color="white" 
            width="180px"
            height="48px"
            _hover={{ bg: "gray.700" }}
            onClick={handleSubmitNewUserNoInvite}
          >
            Apply to Join
          </Button>
        </Flex>
      </VStack>
      <Box />
    </Flex>
  );
};

export default NewLoginPage;