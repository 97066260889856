import { Timestamp } from 'firebase/firestore';

export const formatFirebaseDate = (date: Timestamp | { seconds: number; nanoseconds: number } | { _seconds: number; _nanoseconds: number } | null | undefined) => {
    if (!date) return 'Invalid Date';

    try {
        if (date instanceof Timestamp) {
            return date.toDate().toLocaleDateString();
        }

        const seconds = '_seconds' in date ? date._seconds : date.seconds;
        const nanoseconds = '_nanoseconds' in date ? date._nanoseconds : date.nanoseconds;

        if (typeof seconds !== 'number' || typeof nanoseconds !== 'number') {
            return 'Invalid Date';
        }

        const timestamp = new Timestamp(seconds, nanoseconds);
        return timestamp.toDate().toLocaleDateString();
    } catch (error) {
        console.error('Error formatting Firebase date:', error);
        return 'Invalid Date';
    }
};