import React, { useState, useContext, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Select,
  FormControl,
  Flex,
  Text,
  useToast,
  Link,
  Box
} from '@chakra-ui/react';
import { InfluencerDataContext } from '../../../contexts/InfluencerDataContext';
import { parsePhoneNumber } from 'libphonenumber-js';
import { countryOptions } from '../../../types';

interface ConfirmPhoneModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (countryCode: string, phoneNumber: string) => Promise<void>;
  isProcessing: boolean;
  onTermsClick: () => void;
}

const ConfirmPhoneModal: React.FC<ConfirmPhoneModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isProcessing,
  onTermsClick
}) => {
  const { influencerData } = useContext(InfluencerDataContext);
  const toast = useToast();

  // Parse phone number using libphonenumber-js
  const parsePhone = (fullNumber: string) => {
    try {
      const phoneNumber = parsePhoneNumber(fullNumber);

      if (!phoneNumber) {
        return {
          countryCode: '1',
          nationalNumber: fullNumber.replace(/[^\d]/g, ''),
          country: 'US'
        };
      }
      return {
        countryCode: `${phoneNumber.countryCallingCode}`,
        nationalNumber: phoneNumber.nationalNumber,
        country: phoneNumber.country
      };
    } catch (error) {
      return {
        countryCode: '1',
        nationalNumber: fullNumber.replace(/[^\d]/g, ''),
        country: 'US'
      };
    }
  };

  const initialPhone = parsePhone(influencerData?.phoneNumber || '');
  const [phoneNumber, setPhoneNumber] = useState(initialPhone.nationalNumber);
  const [countryCode, setCountryCode] = useState(initialPhone.countryCode);

  useEffect(() => {
    if (isOpen && influencerData?.phoneNumber) {
      const parsed = parsePhone(influencerData.phoneNumber);
      setCountryCode(parsed.countryCode);
      setPhoneNumber(parsed.nationalNumber);
    }
  }, [isOpen, influencerData]);

  const validatePhoneNumber = (phone: string, code: string): boolean => {
    try {
      const fullNumber = `+${code}${phone}`;
      const parsedNumber = parsePhoneNumber(fullNumber);
      return parsedNumber?.isValid() || false;
    } catch {
      return false;
    }
  };

  const handleConfirm = async () => {
    if (!validatePhoneNumber(phoneNumber, countryCode)) {
      toast({
        title: "Invalid Phone Number",
        description: "Please enter a valid phone number for the selected country code",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    await onConfirm(countryCode, phoneNumber);
  };


  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Phone Number</ModalHeader>
          <ModalBody>
            <Text mb={4}>Please confirm your phone number to receive payment details:</Text>
            <FormControl>
              <Flex>
                <Select
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  width="40%"
                  bg="#F7F8F9"
                  mr={2}
                >
                  {countryOptions.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.code}
                    </option>
                  ))}
                </Select>
                <Input
                  type="tel"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))}
                  bg="#F7F8F9"
                />
              </Flex>
              <Text fontSize="sm" color="gray.500" mt={2}>
                Note: Foodfluence takes a 5% service fee from cash payments as mentioned in our{' '}
                <Link 
                  color="blue.500" 
                  href="https://www.foodfluence.io/tos" 
                  isExternal
                  cursor="pointer"
                >
                  Terms of Service
                </Link>
                .
              </Text>
              <Box 
                mt={4} 
                p={3} 
                bg="blue.50" 
                borderRadius="sm" 
                borderLeft="4px" 
                borderColor="blue.500"
              >
                <Text fontSize="sm" color="blue.700" fontWeight="medium">
                  ⚠️ Important: If you don't receive your payment information within 5 minutes, please click the help button below and reach out to our support team.
                </Text>
              </Box>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button 
              bg="gray.200" 
              mr={3} 
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              bg="black"
              color="white"
              onClick={handleConfirm}
              isLoading={isProcessing}
            >
              Confirm Withdrawal
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmPhoneModal;