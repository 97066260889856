import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import TabBar from './TabBar';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const showTabBar = ['/collabs', '/wallet', '/explore', '/inbox/current', '/inbox/previous', '/inbox/unread'].includes(location.pathname);

  // Determine if the help button should be shown
  const showHelpButton = ['/collabs', '/wallet', '/explore', '/inbox/current', '/inbox/previous', '/inbox/unread'].includes(location.pathname);

  return (
    <Box minH="100vh" bg="white" position="relative">
      <Box pb={showTabBar ? "60px" : "0"}>
        {children}
      </Box>
      {showTabBar && <TabBar />}
      {showHelpButton && (
        <Button
          size="sm"
          position="fixed"
          bottom="70px"
          right="10px"
          bg="black"
          color="white"
          onClick={() => window.Intercom('show')}
          _hover={{ bg: "gray.800" }}
        >
          Need Help?
        </Button>
      )}
    </Box>
  );
};

export default MainLayout;
