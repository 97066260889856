import React from 'react';
import { Flex, Text, IconButton, Button, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody } from '@chakra-ui/react';
import { DeleteIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { getFormattedBundleDescription } from '../../utils';

const BundlePricing = ({ bundlePricing, handleDeleteBundle, setIsAddBundleModalOpen }) => {
  return (
    <>
      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="xl" mr={2}>Bundle Pricing</Text>
        <Popover placement="right">
          <PopoverTrigger>
            <IconButton
              aria-label="Info"
              icon={<InfoOutlineIcon />}
              variant="ghost"
              size="xs"
            />
          </PopoverTrigger>
          <PopoverContent width="200px" fontSize="sm">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody fontSize="sm">Offer brands a discount when they book multiple content types together.</PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      {bundlePricing.length > 0 ? (
        bundlePricing.map((bundle, index) => (
          <Flex key={index} alignItems="center" mb={2}>
            <IconButton
              icon={<DeleteIcon />}
              size="sm"
              aria-label="Delete Bundle"
              variant="ghost"
              color="red"
              onClick={() => handleDeleteBundle(index)}
            />
            <Text ml={2}>{getFormattedBundleDescription(bundle)}</Text>
          </Flex>
        ))
      ) : (
        <Text>No bundles added</Text>
      )}
      <Button
        bg="white"
        color="black"
        border="1px solid black"
        _hover={{ bg: "gray.100" }}
        onClick={() => setIsAddBundleModalOpen(true)}
        mb={4}
      >
        Add a Bundle
      </Button>
    </>
  );
};

export default BundlePricing; 