import React, { useContext } from 'react';
import { VStack, Heading, Text, Box, Flex, Button, Icon } from '@chakra-ui/react';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import { CopyIcon } from '@chakra-ui/icons';
import { Collab, Campaign } from '@foodfluence/shared';
import { formatFirebaseDate } from '../../../utils/dateUtils';
import { ClientDataContext } from 'contexts/ClientDataContext';
import LocationList from 'components/LocationList';

interface CreativeBriefProps {
  collab: Collab;
  campaign: Campaign;
  isRequest?: boolean;
}

const CreativeBrief: React.FC<CreativeBriefProps> = ({ collab, campaign, isRequest }) => {
  const clients = useContext(ClientDataContext);
  const client = clients[campaign.clientID];
  const deliverableTypeToTask: { [key: string]: string } = {
    instagramReels: "Post/Tag on Instagram Reels",
    instagramStories: "Post/Tag on Instagram Story",
    tiktoks: "Post/Tag on TikTok",
    instagramPosts: "Post/Tag on Instagram Feed"
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <VStack align="stretch" spacing={5}>
      {!isRequest && <Heading fontSize="2xl" textAlign="center">Collaboration Details</Heading>}
      <Box>
        <Text fontWeight="bold" fontSize={"20px"}>Brief Description</Text>
        <Box borderWidth={1}
            borderColor="gray.300"
            borderRadius="md"
            px={3}
            py={2}
            mt={2}
            width="100%">
        <Text fontSize={"16px"}>{campaign.description}</Text>
        </Box>
      </Box>
      {((collab.credit > 0 || collab.cash > 0) && !isRequest) && (
        <Flex direction="column" align="flex-start">
          <Text fontSize="20px" fontWeight="bold">Compensation</Text>
          <Flex align="center" mt={2} width="100%">
          <Flex
            direction="column"
            borderWidth={1}
            borderColor="gray.300"
            borderRadius="md"
            px={3}
            py={2}
            width="100%"
          >
            {collab.cash > 0 && (
                <Flex direction="column">
                  <Flex direction="row" align="center" gap={4}>
                    <Text fontSize="20px" fontWeight={"bold"}>💵</Text>
                    <Text fontSize="20px" fontWeight={"bold"}>${collab.cash} Cash</Text>
                  </Flex>
                </Flex>
            )}
            {collab.credit > 0 && (
              <Flex direction="row" align="center" gap={4}>
                <Text fontSize="20px" fontWeight={"bold"}>🎁</Text>
                <Text fontSize="20px" fontWeight={"bold"}>${collab.credit} Credit</Text>
              </Flex>
            )}
            {collab.cash > 0 && (
                <Flex direction="column">
                  <Text fontSize="14px" color="gray" ml={0}>(Cash paid after content is verified)</Text>
                </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      )}
      {!isRequest && (
      <Box>
            <Text fontWeight="bold" fontSize={"20px"}>Deliverables</Text>
            <Box mt={2} borderWidth={1} borderColor="gray.300" borderRadius="md" p={3}>
              <VStack align="stretch" spacing={3}>
                {client?.instagramHandle && (
                  <Flex align="center">
                    <Icon as={FaInstagram} mr={2} />
                    <Text fontSize={"16px"}>Tag @{client.instagramHandle}</Text>
                    <Button size="sm" ml={2} onClick={() => handleCopy(`@${client.instagramHandle}`)} variant="ghost">
                      <CopyIcon />
                    </Button>
                  </Flex>
                )}
                {client?.tiktokHandle && (
                  <Flex align="center">
                    <Icon as={FaTiktok} mr={2} />
                    <Text fontSize={"16px"}>Tag @{client.tiktokHandle}</Text>
                    <Button size="sm" ml={2} onClick={() => handleCopy(`@${client.tiktokHandle}`)} variant="ghost">
                      <CopyIcon />
                    </Button>
                  </Flex>
                )}
                {Object.entries(collab?.deliverableCounts ?? {})
                  .filter(([key, value]) => value > 0)
                  .map(([key, value]) => (
                    <Flex key={key} align="center">
                      <Text as="span" fontWeight="bold" fontSize="16px">{value}</Text>
                      <Text ml={4} fontSize={"16px"}>{deliverableTypeToTask[key] ?? ""}</Text>
                    </Flex>
                ))}
              </VStack>
            </Box>
      </Box>
      )}
      {campaign?.locations && campaign.locations.length > 0 && (
       <LocationList locations={campaign.locations} title="Locations" />
      )}
      <Box>
        {campaign?.campaignType === 'shipToHome' ? (
          <>
            <Text fontWeight="bold" fontSize={"20px"}>Instructions</Text>
            <Flex justify="space-between" >
              <Text fontSize={"18px"}>We will ship the product to you so you can make your content at home!</Text>
            </Flex>
          </>
        ) : (
          campaign?.endDate && (
            <Box mt={2}>
              <Text fontWeight="bold" fontSize={"20px"}>Timeline</Text>
              <Flex justify="space-between" mb={3}>
                <Text fontSize={"18px"}>Come into {client?.accountName} before <Text as="span" color="red.500">{formatFirebaseDate(campaign.endDate)}</Text></Text>
              </Flex>
              <Text fontWeight="bold" fontSize={"20px"}> Other Details</Text>
                  <Text fontSize={"18px"}>
                    Max Party Size: {campaign?.maxPartySize ?? 3}
                  </Text>
            </Box>
          )
        )}
                <Box mt={2}>
          {(campaign?.additionalInfoFile || campaign?.additionalInfoLink) && (
            <Text fontWeight="bold" fontSize={"20px"}>Additional Guidelines</Text>
          )}
          {campaign?.additionalInfoFile && (
            <Box>
              <a href={campaign.additionalInfoFile} target="_blank" rel="noopener noreferrer">
                <Text fontSize={"18px"} color="gray.500" textDecoration="underline">View File</Text>
              </a>
            </Box>
            )}
          {campaign?.additionalInfoLink && (
            <Box>
              <a href={campaign.additionalInfoLink} target="_blank" rel="noopener noreferrer">
                <Text fontSize={"18px"} color="gray.500" textDecoration="underline">Visit Link</Text>
              </a>
            </Box>
          )}
          </Box>
        </Box>      
    </VStack>
  );
};

export default CreativeBrief;