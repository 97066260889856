import React, { useState } from 'react';
import { FormControl, FormLabel, Input, Flex, Text, Checkbox, Icon, Select as ChakraSelect, Box, FormErrorMessage } from '@chakra-ui/react';
import Select from 'react-select';
import { countryOptions } from '../../../../types';
import { validateEmail } from '../../utils';
import { genderOptions, pronounOptions, ethnicityOptions } from '../../../../types';

const PersonalInformation = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email_address,
  setEmail_address,
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  gender,
  setGender,
  pronouns,
  setPronouns,
  birthday,
  setBirthday,
  ethnicity,
  setEthnicity,
  RequiredStar,
}) => {

  return (
    <>
      <Text fontWeight="bold" fontSize="xl">Personal Information</Text>
      <FormControl>
        <FormLabel>First Name<RequiredStar /></FormLabel>
        <Input placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      </FormControl>
      <FormControl>
        <FormLabel>Last Name<RequiredStar /></FormLabel>
        <Input placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </FormControl>
      <FormControl isInvalid={email_address && !validateEmail(email_address)}>
        <FormLabel>Email<RequiredStar /></FormLabel>
        <Input 
          type="email"
          placeholder="Email" 
          value={email_address} 
          onChange={(e) => setEmail_address(e.target.value)}
          autoFocus={false}
          tabIndex={-1}
        />
        {email_address && !validateEmail(email_address) && (
          <FormErrorMessage>Please enter a valid email address</FormErrorMessage>
        )}
      </FormControl>
      <FormControl>
        <FormLabel>Phone Number<RequiredStar /></FormLabel>
        <Flex>
          <ChakraSelect
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            width="40%"
          >
            {countryOptions.map(option => (
              <option key={option.code} value={option.code}>
                {option.code}
              </option>
            ))}
          </ChakraSelect>
          <Input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))}
            autoFocus={false}
            tabIndex={-1}
          />
        </Flex>
      </FormControl>
      <FormControl>
        <FormLabel>Birthday<RequiredStar /></FormLabel>
        <Input
          type="date"
          placeholder="Enter birthday"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
          pattern="\d{2}/\d{2}/\d{4}"
          sx={{
            '&::-webkit-datetime-edit': {
              display: 'block'
            },
            '&::-webkit-date-and-time-value': {
              minHeight: '1.5em',
              textContent: birthday ? new Date(birthday).toLocaleDateString('en-US') : ''
            }
          }}
        />
      </FormControl>
      <FormControl>
        <Flex alignItems="center">
          <FormLabel mb="0">Ethnicity<RequiredStar /></FormLabel>
          <Text color="gray.500" fontSize="xs" ml={-1}>(select one)</Text>
        </Flex>
        <Select
          placeholder="Select one"
          value={ethnicityOptions.find(option => option.value === ethnicity)}
          onChange={(option) => setEthnicity(option.value)}
          options={ethnicityOptions}
        />
      </FormControl>
      <FormControl>
        <Flex alignItems="center">
          <FormLabel mb="0">Gender<RequiredStar /></FormLabel>
          <Text color="gray.500" fontSize="xs" ml={-1}>(select one)</Text>
        </Flex>
        <Select
          placeholder="Select one"
          value={genderOptions.find(option => option.value === gender)}
          onChange={(option) => setGender(option.value)}
          options={genderOptions}
        />
      </FormControl>
      <FormControl mb={2}>
        <Flex alignItems="center">
          <FormLabel mb="0">Pronouns<RequiredStar /></FormLabel>
          <Text color="gray.500" fontSize="xs" ml={-1}>(select one or multiple)</Text>
        </Flex>
        <Select
          isMulti
          options={pronounOptions}
          value={pronouns}
          onChange={(newValue) => setPronouns(newValue)}
          placeholder="Select one or multiple"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999
            })
          }}
          menuPortalTarget={document.body}
        />
      </FormControl>

    </>
  );
};

export default PersonalInformation; 