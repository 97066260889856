import { useState, useEffect } from 'react';
import {
  Box, Button, Input, VStack, Text, FormControl,
  FormLabel, Flex, useToast, IconButton
} from '@chakra-ui/react';
import {
  MapContainer, TileLayer, Marker, useMapEvents, Circle
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { DeleteIcon } from '@chakra-ui/icons';
import { InfluencerLocation } from 'types';

const Locations = ({ locations, setLocations, RequiredStar }) => {
  const toast = useToast();
  const [map, setMap] = useState<L.Map | null>(null);
  const [activeLocationIndex, setActiveLocationIndex] = useState(0);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 30.2672, lng: -97.7431 }); // Austin
  const [isMapReady, setIsMapReady] = useState(false);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setDefaultCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => console.error('Error getting current position:', error)
      );
    }
  }, []);

  useEffect(() => {
    if (!locations[activeLocationIndex]) return;
    if (map && isMapReady) {
      const { lat, lng } = locations[activeLocationIndex];
      map.setView(
        [lat || defaultCenter.lat, lng || defaultCenter.lng],
        getZoomLevel()
      );
    }
  }, [locations, map, activeLocationIndex, defaultCenter, isMapReady]);

  const getZoomLevel = () => {
    const loc = locations[activeLocationIndex];
    if (!loc || (!loc.lat && !loc.lng)) return 9;
    const d = loc.maxCollabDistance || 0;
    if (d <= 10) return 10;
    if (d <= 25) return 9;
    if (d <= 50) return 8;
    if (d <= 100) return 7;
    return 6;
  };

  const handleAddLocation = () => {
    setLocations([...locations, {
      lat: 0, lng: 0, city: '', state: '', country: 'United States', maxCollabDistance: 25
    }]);
    setActiveLocationIndex(locations.length);
  };

  const handleDeleteLocation = (index: number) => {
    if (locations.length <= 1) {
      toast({
        title: 'Cannot delete',
        description: 'You must have at least one location',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const newLocations = locations.filter((_, i) => i !== index);
    setLocations(newLocations);
    setActiveLocationIndex(Math.min(activeLocationIndex, newLocations.length - 1));
  };

  const handleLocationUpdate = (updatedLocation: InfluencerLocation) => {
    const newLocations = [...locations];
    newLocations[activeLocationIndex] = updatedLocation;
    setLocations(newLocations);
  };

  const getCurrentPosition = () => {
    if (!navigator.geolocation) {
      toast({
        title: 'Error',
        description: 'Geolocation is not supported by your browser',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async ({ coords }) => {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?lat=${coords.latitude}&lon=${coords.longitude}&format=json`
          );
          const data = await response.json();

          if (!data.address?.country) {
            toast({
              title: 'Invalid Location',
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top'
            });
            return;
          }

          let locationUpdate = {
            ...locations[activeLocationIndex],
            lat: coords.latitude,
            lng: coords.longitude,
            country: data.address.country,
            city: data.address.city || data.address.county || data.address.town || data.address.village || '',
            state: data.address.country === 'United States' ? data.address.state : ''
          };

          handleLocationUpdate(locationUpdate);
          if (map) {
            map.setView([coords.latitude, coords.longitude], getZoomLevel());
          }

          toast({
            title: 'Location Updated',
            description: 'Your current location has been set',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error getting location data:', error);
        }
      },
      (error) => {
        console.error('Error getting current position:', error);
        toast({
          title: 'Error',
          description: 'Failed to get your current location',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    );
  };

  const LocationMarker = () => {
    useMapEvents({
      async click(e) {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?lat=${e.latlng.lat}&lon=${e.latlng.lng}&format=json`
          );
          const data = await response.json();

          if (!data.address?.country) {
            toast({
              title: 'Invalid Location',
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top'
            });
            return;
          }

          const locationUpdate = {
            ...locations[activeLocationIndex],
            lat: e.latlng.lat,
            lng: e.latlng.lng,
            country: data.address.country,
            city: data.address.city || data.address.county || data.address.town || data.address.village || '',
            state: data.address.country === 'United States' ? data.address.state : ''
          };

          handleLocationUpdate(locationUpdate);
        } catch (error) {
          console.error('Error getting location data:', error);
        }
      }
    });

    const pinIcon = L.icon({
      iconUrl: 'data:image/svg+xml;base64,' + btoa(`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="24px" height="24px">
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" stroke="black" stroke-width="1"/>
        </svg>
      `),
      iconSize: [24, 24],
      iconAnchor: [12, 24],
      popupAnchor: [0, -24],
    });

    return (
      <Marker
        position={[locations[activeLocationIndex].lat, locations[activeLocationIndex].lng]}
        icon={pinIcon}
      />
    );
  };

  const currentLocation = locations[activeLocationIndex];

  return (
    <VStack spacing={4} align="stretch">
      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="xl">Locations<RequiredStar /></Text>
        <Button
          ml="auto"
          bg="white"
          color="black"
          border="1px solid black"
          _hover={{ bg: "gray.100" }}
          onClick={handleAddLocation}
          fontWeight="normal"
          size="sm"
        >
          Add Location
        </Button>
      </Flex>

      {locations.map((location, index) => (
        <Flex key={index} alignItems="center" mb={2}>
          <IconButton
            icon={<DeleteIcon />}
            size="sm"
            aria-label="Delete Location"
            variant="ghost"
            color="red"
            onClick={() => handleDeleteLocation(index)}
            isDisabled={locations.length <= 1}
          />
          <Text ml={2} cursor="pointer" onClick={() => setActiveLocationIndex(index)}>
            {(location.city || location.country)
              ? location.country === 'United States'
                ? `${location.city || ''}${location.state ? `, ${location.state}` : ''}`
                : `${location.city || ''}${location.city ? ', ' : ''}${location.country || ''}`
              : 'Unset location'}
            {index === activeLocationIndex && " (Selected)"}
          </Text>
        </Flex>
      ))}

      <Box height="400px" p={2} position="relative">
        {!currentLocation.lat && (
          <Text
            position="absolute"
            top="40%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex={1000}
            bg="white"
            p={2}
            borderRadius="md"
            boxShadow="md"
          >
            Click on the map to add your location pin
          </Text>
        )}
        <MapContainer
          center={
            currentLocation.lat ?
              [currentLocation.lat, currentLocation.lng] :
              [defaultCenter.lat, defaultCenter.lng]
          }
          zoom={getZoomLevel()}
          style={{ height: '100%', width: '100%' }}
          ref={(mapRef) => {
            if (mapRef) {
              setMap(mapRef);
              setIsMapReady(true);
            }
          }}
          whenReady={() => setIsMapReady(true)}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {isMapReady && <LocationMarker />}
          {isMapReady && currentLocation.lat && (
            <Circle
              center={[currentLocation.lat, currentLocation.lng]}
              radius={currentLocation.maxCollabDistance * 1609.34}
            />
          )}
        </MapContainer>
      </Box>

      <Box>
        <FormControl mt={2} mb={2}>
          <Flex alignItems="center">
            <FormLabel mb="0">Max Travel Distance For Collaborations (miles)</FormLabel>
          </Flex>
          <Input
            value={currentLocation.maxCollabDistance}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (!isNaN(value)) {
                handleLocationUpdate({
                  ...currentLocation,
                  maxCollabDistance: value
                });
              }
            }}
            placeholder="Radius in miles"
          />
        </FormControl>
      </Box>
    </VStack>
  );
};

export default Locations;
