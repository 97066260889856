import React, { useState } from 'react';
import {
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  List,
  ListItem,
  Text,
  Icon,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { FaMapPin } from 'react-icons/fa'; 
import { FaStore } from 'react-icons/fa';
import { SearchResult } from '../utils';

interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  onSelectResult?: (result: SearchResult) => void;
  suggestions?: SearchResult[];
  debounceTime?: number;
  onSearch: (query: string) => Promise<void>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  value,
  onChange,
  onSelectResult,
  suggestions = [],
  debounceTime = 300,
  onSearch
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(newValue);
    
    if (newValue.length >= 2) {
      await onSearch(newValue);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSelectSuggestion = (suggestion: SearchResult) => {
    onChange(suggestion.text);
    setShowSuggestions(false);
    if (onSelectResult) {
      onSelectResult(suggestion);
    }
  };

  return (
    <Box width="100%" position="relative">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          placeholder="Search by brand name"
          value={value}
          onChange={handleChange}
          onFocus={() => setShowSuggestions(true)}
          bg="white"
          borderRadius="full"
          _hover={{ borderColor: "gray.300" }}
          _focus={{ 
            borderColor: "gray.400",
            boxShadow: "none"
          }}
        />
      </InputGroup>

      {showSuggestions && suggestions.length > 0 && (
        <List
          position="absolute"
          top="100%"
          left={0}
          right={0}
          bg="white"
          boxShadow="md"
          borderRadius="md"
          mt={2}
          maxH="300px"
          overflowY="auto"
          zIndex={1000}
        >
          {suggestions.map((suggestion, index) => (
            <ListItem
              key={index}
              p={3}
              cursor="pointer"
              _hover={{ bg: "gray.50" }}
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              <Box display="flex" alignItems="center">
                <Icon
                  as={suggestion.type === 'location' ? FaMapPin : FaStore}
                  mr={2}
                  color="gray.500"
                />
                <Text>{suggestion.text}</Text>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default SearchBar; 