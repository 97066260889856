import React, { createContext, useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Flex, Spinner } from '@chakra-ui/react';

interface WhiteLabelData {
  clientId: string;
  isWhiteLabeled: boolean;
  isLoading: boolean;
}

export const WhiteLabelContext = createContext<WhiteLabelData>({
  clientId: '',
  isWhiteLabeled: false,
  isLoading: true
});

export const WhiteLabelProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [whiteLabelData, setWhiteLabelData] = useState<WhiteLabelData>({
    clientId: '',
    isWhiteLabeled: false,
    isLoading: true
  });

  useEffect(() => {
    const checkWhiteLabel = async () => {
      const hostname = window.location.hostname;
      
      try {
        const whitelabelDoc = await getDoc(doc(firestore, 'clientExplorePages', hostname));
        
        if (whitelabelDoc.exists()) {
          setWhiteLabelData({
            clientId: whitelabelDoc.data().clientID,
            isWhiteLabeled: true,
            isLoading: false
          });
        } else {
          setWhiteLabelData({
            clientId: '',
            isWhiteLabeled: false,
            isLoading: false
          });
        }
      } catch (error) {
        console.error('Error checking white label:', error);
        setWhiteLabelData({
          clientId: '',
          isWhiteLabeled: false,
          isLoading: false
        });
      }
    };

    checkWhiteLabel();
  }, []);

  if (whiteLabelData.isLoading) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <WhiteLabelContext.Provider value={whiteLabelData}>
      {children}
    </WhiteLabelContext.Provider>
  );
};