import React, { createContext, useState, useEffect, ReactNode } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { useAuthState } from 'react-firebase-hooks/auth';

import { Influencer } from "../types";
import { getInfluencerByEmail, getInfluencerByPhoneNumber } from "services/firebaseService";

interface InfluencerDataContextType {
    influencerData: Influencer | null;
    loading: boolean;
}

export const InfluencerDataContext = createContext<InfluencerDataContextType>({
    influencerData: null,
    loading: true
});

interface InfluencerDataProviderProps {
    children: ReactNode;
}

export const InfluencerDataProvider: React.FC<InfluencerDataProviderProps> = ({ children }) => {
    const [user] = useAuthState(auth);
    const [influencerData, setInfluencerData] = useState<Influencer | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!user) {
            setLoading(false);
            setInfluencerData(null);
            return;
        }

        const fetchInfluencerData = async () => {
            let influencerDoc;
            if (user.phoneNumber) {
                influencerDoc = await getInfluencerByPhoneNumber(user.phoneNumber);
            } else if (user.email) {
                influencerDoc = await getInfluencerByEmail(user.email);
            }

            if (influencerDoc) {
                const unsubscribe = onSnapshot(doc(firestore, "influencers", influencerDoc.id), (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        const influencerData = docSnapshot.data() as Influencer;
                        influencerData.id = influencerDoc.id;
                        setInfluencerData(influencerData);
                    } else {
                        setInfluencerData(null);
                    }
                    setLoading(false);
                }, (error) => {
                    console.error("Error fetching influencer data:", error);
                    setLoading(false);
                });

                return () => unsubscribe();
            } else {
                setInfluencerData(null);
                setLoading(false);
            }
        };

        fetchInfluencerData();
    }, [user]);

    return (
        <InfluencerDataContext.Provider value={{ influencerData, loading }}>
            {children}
        </InfluencerDataContext.Provider>
    );
};
