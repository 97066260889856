import React, { useState } from 'react';
import { FormControl, FormLabel, Flex, Text, Input, Box, Divider, Tooltip, Icon, Link, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, IconButton } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const cashCap = 10000;

const RatesPricing = ({
  rateRanges,
  handleRateChange,
  collabPreference,
  RequiredStar,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleValueChange = (category: string, field: string, value: number) => {
    if (!isNaN(value)) {
      handleRateChange(category, field, value);
      if (value > cashCap) {
        setErrorMessage(`Rate cannot exceed ${cashCap.toLocaleString()}.`);
      } else {
        setErrorMessage(null);
      }
    }
  };

  const instagramDeliverables = [
    {
      id: 'instagramReels',
      label: 'Instagram Reel',
      description: '(per video rate)'
    },
    {
      id: 'instagramPosts',
      label: 'Instagram Post',
      description: '(per post rate)'
    },
    {
      id: 'instagramStories',
      label: 'Instagram Story',
      description: '(per story rate)'
    }
  ];

  return (
    <>
      {collabPreference != 'cashOnly' && ( 
        <>
          <Flex alignItems="center">
            <Text fontWeight="bold" fontSize="xl" mr={2}>
              Rates & Pricing
          </Text>
          <Popover placement="right">
            <PopoverTrigger>
              <IconButton
                aria-label="Info"
                icon={<InfoOutlineIcon />}
                variant="ghost"
                size="xs"
              />
            </PopoverTrigger>
            <PopoverContent width="200px" fontSize="sm">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody fontSize="sm">The minimum and maximum value of gifted products & hosted experience you'd accept for a collaboration.</PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
        <FormControl mb={2}>
          <Flex alignItems="center" mb={2}>
            <FormLabel mb="0" fontWeight="bold">Minimum Gifting Value Range<RequiredStar /></FormLabel>
          </Flex>
          <Flex alignItems="center">
            <Flex alignItems="center" mr={2}>
              <Text mr={1}>$</Text>
              <Input
                value={rateRanges.gifting.minRate ?? ''}
                onChange={(e) => handleValueChange('gifting', 'minRate', Number(e.target.value))}
                width="80px"
              />
            </Flex>
            <Box width="15px" height="1px" backgroundColor="gray.500" />
            <Flex alignItems="center" ml={2}>
              <Text mr={1}>$</Text>
              <Input
                value={rateRanges.gifting.maxRate ?? ''}
                onChange={(e) => handleValueChange('gifting', 'maxRate', Number(e.target.value))}
                width="80px"
              />
            </Flex>
          </Flex>
        </FormControl>
      </>
      )}

      <Divider />

      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="xl" mr={2}>
          Rates (USD)
        </Text>
        <Popover placement="right">
          <PopoverTrigger>
            <IconButton
              aria-label="Info"
              icon={<InfoOutlineIcon />}
              variant="ghost"
              size="xs"
            />
          </PopoverTrigger>
          <PopoverContent width="200px" fontSize="sm" >
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody fontSize="sm">Set your pricing for different types of sponsored content. Brands will use this to determine if your rates align with their budget.</PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      {(rateRanges.instagramPosts.offered && 
        rateRanges.instagramReels.offered && 
        rateRanges.instagramStories.offered) && (
        <>
          {instagramDeliverables.map((deliverable) => (
            <FormControl key={deliverable.id} mb={2}>
              <Flex alignItems="center" mb={2}>
                <FormLabel mb="0" fontWeight="bold">{deliverable.label}</FormLabel>
                <Text color="gray.500" fontSize="xs" ml={-1}>{deliverable.description}</Text>
                <RequiredStar />
              </Flex>
              <Flex alignItems="center">
                <Flex alignItems="center" mr={2}>
                  <Text mr={1}>$</Text>
                  <Input
                    value={rateRanges[deliverable.id].minRate ?? ''}
                    onChange={(e) => handleValueChange(deliverable.id, 'minRate', Number(e.target.value))}
                    width="80px"
                  />
                </Flex>
                <Box width="15px" height="1px" backgroundColor="gray.500" />
                <Flex alignItems="center" ml={2}>
                  <Text mr={1}>$</Text>
                  <Input
                    value={rateRanges[deliverable.id].maxRate ?? ''}
                    onChange={(e) => handleValueChange(deliverable.id, 'maxRate', Number(e.target.value))}
                    width="80px"
                  />
                </Flex>
              </Flex>
            </FormControl>
          ))}
        </>
      )}

      {rateRanges.tiktoks.offered && (
        <>
          {/* TikTok */}
          <FormControl mb={2}>
            <Flex alignItems="center" mb={2}>
              <FormLabel mb="0" fontWeight="bold">TikTok</FormLabel>
              <Text color="gray.500" fontSize="xs" ml={-1}>(per video rate)</Text>
              <RequiredStar />
            </Flex>
            <Flex alignItems="center">
              <Flex alignItems="center" mr={2}>
                <Text mr={1}>$</Text>
                <Input
                  value={rateRanges.tiktoks.minRate ?? ''}
                  onChange={(e) => handleValueChange('tiktoks', 'minRate', Number(e.target.value))}
                  width="80px"
                />
              </Flex>
              <Box width="15px" height="1px" backgroundColor="gray.500" />
              <Flex alignItems="center" ml={2}>
                <Text mr={1}>$</Text>
                <Input
                  value={rateRanges.tiktoks.maxRate ?? ''}
                  onChange={(e) => handleValueChange('tiktoks', 'maxRate', Number(e.target.value))}
                  width="80px"
                />
              </Flex>
            </Flex>
          </FormControl>
        </>
      )}

      {/* {rateRanges.ugc.offered && (
        <>
          {/* UGC 
          <FormControl mb={2}>
            <Flex alignItems="center" mb={2}>
              <FormLabel mb="0" fontWeight="bold">UGC</FormLabel>
              <Text color="gray.500" fontSize="xs" ml={-1}>(per video rate)</Text>
            </Flex>
            <Flex alignItems="center">
              <Flex alignItems="center" mr={2}>
                <Text mr={1}>$</Text>
                <Input
                  value={rateRanges.ugc.minRate}
                  onChange={(e) => handleRateChange('ugc', 'minRate', Number(e.target.value))}
                  width="80px"
                />
              </Flex>
              <Box width="15px" height="1px" backgroundColor="gray.500" />
              <Flex alignItems="center" ml={2}>
                <Text mr={1}>$</Text>
                <Input
                  value={rateRanges.ugc.maxRate}
                  onChange={(e) => handleRateChange('ugc', 'maxRate', Number(e.target.value))}
                  width="80px"
                />
              </Flex>
            </Flex>
          </FormControl>
        </>
      )} */}

      {errorMessage && (
        <Text color="red.500" fontSize="sm" textAlign="center">
          {errorMessage}
        </Text>
      )}
    </>
  );
};

export default RatesPricing; 