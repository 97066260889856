import { PlaceDetails } from "../types";
import Environment from "../environment";

const API_KEY = Environment.FLASK_API_KEY;
const HOST_URL = Environment.FLASK_HOST_URL;

//methods


//you have 24 hours to accept your collab

//your collab has expired

//your collab

//your content was approved/rejected

//post receivign reward reminder to post with delivera

//please remember to post your content and get verified

export const acceptCollabReminder = async (collabId: string): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/accept-collab/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        collab_id: collabId
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error accepting collab:', error.message);
    } else {
      console.error('Error accepting collab:', error);
    }
    return false;
  }
};

export const scheduleCollabReminder = async (
  collabId: string,
  scheduledTime: string,
  isDateAlreadyScheduled: boolean,
  clientName: string,
  influencerInstagramHandle: string,
  influencerPhoneNumber: string,
  clientEmail: string,
  timezone: string,
  location: string,
  locationEmails: string[],
  clientID: string,
  partySize: number
): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/schedule-collab/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        collab_id: collabId,
        scheduled_time: scheduledTime,
        is_date_already_scheduled: isDateAlreadyScheduled,
        client_name: clientName,
        influencer_phone: influencerPhoneNumber,
        influencer_handle: influencerInstagramHandle,
        client_email: clientEmail,
        timezone: timezone,
        location: location,
        location_emails: locationEmails,
        client_id: clientID, 
        party_size: partySize,
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error scheduling collab reminder:', error.message);
    } else {
      console.error('Error scheduling collab reminder:', error);
    }
    return false;
  }
};

export const processCollabPayment = async (
  collabIds: string,
  amount: number,
  influencerPhoneNumber: string,
  influencerCountryCode: string,
  influencerHandle: string
): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/send-payment-link/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        phone_number: influencerPhoneNumber,
        country_code: influencerCountryCode,
        payment_amount: amount,
        collab_ids: collabIds,
        influencer_handle: influencerHandle
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error processing payment:', error.message);
    } else {
      console.error('Error processing payment:', error);
    }
    return false;
  }
};

export const sendNotificationToVerifyContent = async (
  influencerHandle: string,
  clientName: string
): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/influencer-submitted-link/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        client_name: clientName,
        influencer_handle: influencerHandle
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error sending notification to verify content:', error.message);
    } else {
      console.error('Error sending notification to verify content:', error);
    }
    return false;
  }
};

export const getPlaceSuggestions = async (inputText: string): Promise<any[]> => {
  try {
    const response = await fetch(`${HOST_URL}/places/autocomplete?input=${encodeURIComponent(inputText)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error getting place suggestions:', error.message);
    } else {
      console.error('Error getting place suggestions:', error);
    }
    return [];
  }
};

export const getPlaceDetails = async (placeId: string): Promise<PlaceDetails> => {
  try {
    const response = await fetch(`${HOST_URL}/places/details?place_id=${encodeURIComponent(placeId)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error getting place details:', error.message);
    } else {
      console.error('Error getting place details:', error);
    }
    return null;
  }
};

export const sendAddressSubmissionEmail = async (collabId: string, clientEmail: string, influencerHandle: string, clientID: string): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/send-influencer-submitted-address-email/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({ 
        collab_id: collabId,
        client_email: clientEmail,
        influencer_handle: influencerHandle,
        client_id: clientID
      })
    }); 

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error sending address submission email:', error.message);
    } else {
      console.error('Error sending address submission email:', error);
    }
    return false;
  }
};

export const sendCounterEmail = async (clientEmail: string, influencerHandle: string, clientID: string): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/influencer-countered/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        client_email: clientEmail,
        influencer_handle: influencerHandle,
        client_id: clientID
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error sending counter email:', error.message);
    } else {
      console.error('Error sending counter email:', error);
    }
    return false;
  }
};  

export const sendCollabSubmitContentReminder = async (collabId: string): Promise<boolean> => {
  try {
    const now = new Date();
    const formattedDate = now.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });

    const response = await fetch(`${HOST_URL}/send-collab-submit-content-reminder/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        collab_id: collabId,
        scheduled_time: formattedDate
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error sending collab submit content reminder:', error.message);
    } else {
      console.error('Error sending collab submit content reminder:', error);
    }
    return false;
  }
};

export const cancelCollabAndNotifyClient = async (
  collabId: string,
  clientID: string,
  clientEmail: string,
  clientName: string, 
  influencerHandle: string,
  influencerPhone: string
): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/cancel-collab/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        client_id: clientID,
        collab_id: collabId,
        client_email: clientEmail,
        client_name: clientName,
        influencer_handle: influencerHandle,
        influencer_phone: influencerPhone
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error canceling collab:', error.message);
    } else {
      console.error('Error canceling collab:', error);
    }
    return false;
  }
}


export const registerNewUser = async (userData: {
  email: string;
  firstName: string;
  lastName: string;
  instagramHandle: string;
  tiktokHandle?: string;
  phoneNumber: string;
}): Promise<{success: boolean; message: string;}> => {
  try {
    const response = await fetch(`${Environment.FLASK_HOST_URL}/influencers/register-new-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': Environment.FLASK_API_KEY
      },
      body: JSON.stringify({
        email: userData.email,
        first_name: userData.firstName,
        last_name: userData.lastName,
        instagram_handle: userData.instagramHandle,
        tiktok_handle: userData.tiktokHandle || '',
        phone_number: userData.phoneNumber
      })
    });

    const result = await response.json();
    
    if (!response.ok) {
      throw new Error(result.message || 'Registration failed');
    }

    return {
      success: result.success,
      message: result.message,
    };
  } catch (error) {
    console.error('Error registering new user:', error);
    return {
      success: false,
      message: error instanceof Error ? error.message : 'Registration failed'
    };
  }
};

export const sendInfluencerApplicationSubmittedNotifications = async (influencerEmail: string, influencerHandle: string): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/influencers/send-influencer-application-submitted-notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        influencer_email: influencerEmail,
        influencer_handle: influencerHandle
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    console.error('Error sending influencer application submitted notifications:', error);
    return false;
  }
};


export const billForAdditionalCollab = async (adminId: string, collabId: string): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/bill-for-additional-collab`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        adminId: adminId,
        collabId: collabId
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    console.error('Error sending collab request notification:', error);
    return false;
  }
};

export const messageSentToClientEmail = async (clientID: string, clientEmail: string, client_name: string, influencerHandle: string, message: string): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/message-sent-to-client-email/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        client_id: clientID,
        client_email: clientEmail,
        client_name: client_name,
        influencer_handle: influencerHandle,
        message: message
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error sending message sent to client email:', error.message);
    } else {
      console.error('Error sending message sent to client email:', error);
    }
    return false;
  }
};  