import { Flex, Checkbox, FormControl, FormLabel, Input, Text } from '@chakra-ui/react';

const SocialProfiles = ({ 
  instagramHandle, 
  tiktokHandle, 
  rateRanges, 
  setRateRanges,
  RequiredStar,
}) => {

  const handleInstagramChange = (checked: boolean) => {
    setRateRanges({
      ...rateRanges,
      instagramPosts: { ...rateRanges.instagramPosts, offered: checked },
      instagramReels: { ...rateRanges.instagramReels, offered: checked },
      instagramStories: { ...rateRanges.instagramStories, offered: checked }
    });
  };

  const handleTiktokChange = (checked: boolean) => {
    setRateRanges({
      ...rateRanges,
      tiktoks: { ...rateRanges.tiktoks, offered: checked }
    });
  };

  return (
    <>
      <Text fontWeight="bold" fontSize="xl">Social Profiles</Text>
      <FormControl>
        <Flex alignItems="center" mb={2}>
          <FormLabel mb="0" fontWeight="bold">Instagram<RequiredStar /></FormLabel>
        </Flex>
        <Checkbox
          isChecked={rateRanges.instagramPosts.offered && 
                    rateRanges.instagramReels.offered && 
                    rateRanges.instagramStories.offered}
          onChange={(e) => handleInstagramChange(e.target.checked)}
        >
          Do you post content on Instagram?
        </Checkbox>
      </FormControl>

      {rateRanges.instagramPosts.offered && (
        <FormControl>
          <FormLabel>Instagram Handle<RequiredStar /></FormLabel>
          <Input 
            placeholder="@username" 
            value={instagramHandle ? `@${instagramHandle}` : ''} 
            isDisabled={true}
            bg="gray.50"
            _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
          />
        </FormControl>
      )}

      <FormControl>
        <Flex alignItems="center" mb={2}>
          <FormLabel mb="0" fontWeight="bold">TikTok<RequiredStar /></FormLabel>
        </Flex>
        <Checkbox
          isChecked={rateRanges.tiktoks.offered}
          onChange={(e) => handleTiktokChange(e.target.checked)}
        >
          Do you post content on TikTok?
        </Checkbox>
      </FormControl>

      {rateRanges.tiktoks.offered && (
        <FormControl>
          <FormLabel>TikTok Handle<RequiredStar /></FormLabel>
          <Input 
            placeholder="@username" 
            value={tiktokHandle ? `@${tiktokHandle}` : ''} 
            isDisabled={true}
            bg="gray.50"
            _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
          />
        </FormControl>
      )}
    </>
  );
};

export default SocialProfiles; 