import React, { useContext } from 'react';
import {
  Box, Button, Text, VStack, Flex, Avatar, IconButton, Divider, Link
} from '@chakra-ui/react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { InfluencerDataContext } from '../../contexts/InfluencerDataContext';
import { MdEdit, MdLiveHelp } from 'react-icons/md';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import { getFormattedBundleDescription, getNonHiddenTags } from './utils';
import RatesForProfilePage from './components/RatesForProfilePage';
import { collabPreferenceOptions } from '../../types';

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { influencerData } = useContext(InfluencerDataContext);

  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleEdit = () => {
    navigate('/profile/edit');
  };

  return (
    <Box w="100%" maxW="100%" mx="auto" p={6}>
      {/* Header */}
      <Flex justifyContent="space-between" alignItems="center" mb="12px" position="relative" top="-10px">
        <IconButton
          icon={<ArrowBackIcon />}
          size="lg"
          aria-label="Go Back"
          variant="ghost"
          borderRadius="full"
          onClick={() => navigate(-1)}
        />
        <Text fontWeight="bold" fontSize="2xl" position="absolute" left="50%" transform="translateX(-50%)">
          Profile
        </Text>
        <Flex>
          <IconButton
            icon={<MdEdit />}
            size="xxl"
            aria-label="Edit"
            variant="ghost"
            borderRadius="full"
            onClick={handleEdit}
          />
        </Flex>
      </Flex>

      {/* Profile Section */}
      <VStack spacing={4} align="start" w="100%">
        <Flex alignItems="center" w="100%">
          <Avatar src={influencerData?.profile_pic_url} name={influencerData?.firstName} size="xl" />
          <Box ml={4}>
            <Text fontSize="xl" fontWeight="bold">
              {(influencerData?.firstName && 
                influencerData?.lastName && 
                influencerData?.firstName?.toLowerCase() !== 'unknown' && 
                influencerData?.lastName?.toLowerCase() !== 'unknown')
                ? `${influencerData.firstName} ${influencerData.lastName}`
                : influencerData?.instagramHandle}
            </Text>
            <Text color="gray.500">
              {influencerData?.location?.length > 0 ? (
                influencerData.location.map((loc, index) => (
                  <React.Fragment key={index}>
                    📍 {loc.country === 'United States' 
                      ? `${loc.city}${loc.state ? `, ${loc.state}` : ''}`
                      : `${loc.city}${loc.country ? `, ${loc.country}` : ''}`}
                    {index < influencerData.location.length - 1 && <br />}
                  </React.Fragment>
                ))
              ) : (
                influencerData?.city && <React.Fragment>
                  📍 {`${influencerData.city}${influencerData.state ? `, ${influencerData.state}` : ''}`}
                </React.Fragment>
              )}
            </Text>
            <Flex mt={2} gap={2}>
              {influencerData?.instagramHandle && influencerData?.instagramHandle !== '' && influencerData?.rateRanges?.instagramPosts?.offered && influencerData?.rateRanges?.instagramReels?.offered && influencerData?.rateRanges?.instagramStories?.offered && (
                <Link href={`https://www.instagram.com/${influencerData.instagramHandle}`} isExternal>
                  <FaInstagram size={24} />
                </Link>
              )}
              {influencerData?.tiktokHandle && influencerData?.tiktokHandle !== '' && influencerData?.rateRanges?.tiktoks?.offered && (
                <Link href={`https://www.tiktok.com/@${influencerData.tiktokHandle}`} isExternal>
                  <FaTiktok size={22} />
                </Link>
              )}
            </Flex>
          </Box>
        </Flex>

        <Divider my={0.5} />

        {/* Niches */}
        <Box>
          <Text fontWeight="bold">Niches</Text>
          {influencerData?.tags && influencerData.tags.length > 0 ? (
            <Text mt={1}>{getNonHiddenTags(influencerData.tags).join(', ')}</Text>
          ) : (
            <Text color="gray.500" mt={1}>Update your profile to add them.</Text>
          )}
        </Box>

        <Divider my={0.5} />

        {/* Collaboration Preferences */}
        <Box>
          <Text fontWeight="bold">Collaboration Preferences</Text>
          {influencerData?.collabPreference && influencerData.collabPreference.length > 0 ? (
            <Text mt={1}>{collabPreferenceOptions.find(option => option.value === influencerData.collabPreference)?.label}</Text>
          ) : (
            <Text color="gray.500" mt={1}>Update your profile to set them.</Text>
          )}
          {/* {influencerData?.rateRanges?.ugc?.offered === true && (
            <Text mt={1}>
              Produces UGC content
            </Text>
          )} */}
        </Box>

        <Divider my={0.5} />

        {/* Minimum Gifting Value */}
        {influencerData?.collabPreference != 'cashOnly' && (
          <Box>
            <Text fontWeight="bold">Minimum Gifting Value</Text>
            {influencerData?.rateRanges?.gifting?.minRate !== undefined && influencerData?.rateRanges?.gifting?.maxRate !== undefined ? (
              <Text>${influencerData?.rateRanges?.gifting?.minRate} - ${influencerData?.rateRanges?.gifting?.maxRate} USD</Text>
            ) : (
                <Text color="gray.500" mt={1}>Update your profile to set them.</Text>
              )}
          </Box>
        )}

        {/* Rates Section */}
        <RatesForProfilePage
          rateRanges={influencerData?.rateRanges}
        />

        <Divider my={0.5} />

        {/* Bundle Pricing Section */}
        {influencerData?.bundlePricing && influencerData?.bundlePricing.length > 0 && (
          <Box>
            <Text fontWeight="bold">Bundle Pricing</Text>
            {influencerData?.bundlePricing.map((bundle, index) => (
              <Text key={index}>{getFormattedBundleDescription(bundle)}</Text>
            ))}
          </Box>
        )}

        {influencerData?.bundlePricing && influencerData?.bundlePricing.length > 0 && (
          <Divider my={0.5} />
        )}
       

        {/* Media Kit Section */}
        {influencerData?.mediaKit && (
        <Box>
          <Text fontWeight="bold">Media Kit</Text>
          {influencerData?.mediaKit && (
            <Link href={influencerData?.mediaKit} isExternal>
              <Text color="gray.500" textDecoration="underline">
                View Media Kit
              </Text>
            </Link>
            )}
          </Box>
        )}

        {influencerData?.mediaKit && (
          <Divider my={0.5} />
        )}

        {/* Sign Out Button Moved to Bottom Right */}
        <Flex justifyContent="flex-end" w="100%">
          <Button 
            bg="black" 
            color="white" 
            _hover={{ bg: "gray.700" }} 
            onClick={handleSignOut}
          >
            Sign Out
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default ProfilePage;
