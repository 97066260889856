import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Flex,
  useToast,
  Link,
  IconButton,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { createOrRetrieveInstagramVerificationCode, checkInstagramVerification } from 'services/firebaseService';
import { useInstagramVerification } from 'contexts/InstagramVerificationContext';

interface InstagramVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  instagram: string;
  onVerificationSuccess: () => void;
}

const InstagramVerificationModal: React.FC<InstagramVerificationModalProps> = ({
  isOpen,
  onClose,
  email,
  instagram,
  onVerificationSuccess,
}) => {
  const [instagramVerificationCode, setInstagramVerificationCode] = useState('');
  const toast = useToast();
  const { isVerified, watchInstagramHandle } = useInstagramVerification();

  useEffect(() => {
    if (isOpen && instagram) {
      watchInstagramHandle(instagram);
    }
  }, [isOpen, instagram]);

  useEffect(() => {
    if (isVerified) {
      onVerificationSuccess();
      onClose();
    }
  }, [isVerified, onVerificationSuccess, onClose]);

  useEffect(() => {
    const verifyExisting = async () => {
      if (email && instagram) {
        try {
          const isVerified = await checkInstagramVerification(instagram);
          if (isVerified) {
            onVerificationSuccess();
            onClose();
          } else {
            // Generate code when modal opens and not verified
            try {
              const code = await createOrRetrieveInstagramVerificationCode(email, instagram);
              setInstagramVerificationCode(code);
            } catch (error) {
              console.error('Error storing verification code:', error);
              toast({
                title: "Error",
                description: "Failed to generate verification code. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          }
        } catch (error) {
          console.error('Error checking verification:', error);
        }
      }
    };

    if (isOpen) {
      verifyExisting();
    }
  }, [isOpen, email, instagram, onVerificationSuccess, onClose, toast]);
 
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Instagram Verification</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
            <Box p={4} bg="gray.100" borderRadius="md" mt={4}>
              <Text fontWeight="bold">Instagram Verification Instructions:</Text>
              <Flex alignItems="center">
                <Text>1. Copy your verification code: </Text>
                <Text as="span" fontWeight="bold" mx={2}>{instagramVerificationCode}</Text>
                <IconButton
                  aria-label="Copy code"
                  icon={<CopyIcon />}
                  size="sm"
                  onClick={() => {
                    navigator.clipboard.writeText(instagramVerificationCode);
                    toast({
                      title: "Copied!",
                      status: "success",
                      duration: 2000,
                      isClosable: true,
                    });
                  }}
                />
              </Flex>
              <Text>2. <Link href="https://www.instagram.com/direct/t/17844327537249335" isExternal color="blue.500">DM your code</Link> to <Link href="https://www.instagram.com/foodfluence.io" isExternal color="blue.500">@foodfluence.io</Link> on Instagram</Text>
              <Text>3. Once sent, come back to this page and your application will be submitted</Text>
              <Text color="gray.600" fontSize="sm" mt={2}>
                Note: Your application will not be submitted until your Instagram is verified
              </Text>
            </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InstagramVerificationModal;
