import Environment from '../../environment';
import { auth } from '../../firebase';

const API_URL = Environment.API_URL;
const FLASK_API_KEY = Environment.FLASK_API_KEY;
const FLASK_HOST_URL = Environment.FLASK_HOST_URL;

type ApiConfig = {
  baseUrl?: string;
  headers?: Record<string, string>;
};

type RequestOptions = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  endpoint: string;
  data?: any;
  config?: ApiConfig;
};

const defaultConfig: ApiConfig = {
  baseUrl: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
};

const flaskConfig: ApiConfig = {
  baseUrl: FLASK_HOST_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `${FLASK_API_KEY}`
  }
};

export const apiRequest = async <T>({
  method,
  endpoint,
  data,
  config = defaultConfig
}: RequestOptions): Promise<T> => {
  const user = auth.currentUser;
  const token = user ? await user.getIdToken() : null;
  const headers = {
    ...config.headers,
    ...(token && { 'Authorization': `Bearer ${token}` })
  };

  const url = `${config.baseUrl}${endpoint}`;
  
  const requestOptions: RequestInit = {
    method,
    headers,
    ...(data && { body: JSON.stringify(data) })
  };

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw new Error(`API request failed: ${response.statusText}`);
  }

  // Return null for 204 No Content responses
  if (response.status === 204) {
    return null as T;
  }

  return response.json();
};

export const makeFlaskRequest = async <T>(options: Omit<RequestOptions, 'config'>): Promise<T> => {
  return apiRequest<T>({ ...options, config: flaskConfig });
}; 