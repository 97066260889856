import React, { useEffect, useState, useContext } from 'react';
import { 
  Box, 
  Text, 
  Image, 
  Heading, 
  Flex,
  Link,
  Icon,
  Spinner,
  Button,
  HStack,
  VStack,
  useDisclosure,
  IconButton
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import { Client, Campaign } from '@foodfluence/shared';
import CampaignComponent from '../components/CampaignComponent';
import { exploreApi } from '../../../services/api/explore';
import SignInSignUpModal from '../components/SignInSignUpModal';
import { useAuth } from '../../../contexts/AuthContext';
import { CollabDataContext } from '../../../contexts/collabDataContext';
import { getClientIdFromCollabRequest } from 'utils/collabUtils';
import { InfluencerCampaignDataContext } from 'contexts/InfluencerCampaignDataContext';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useRandomImage } from '../../../utils/imageUtils';

import { WhiteLabelContext } from '../../../contexts/WhiteLabelContext';
import { SINGLE_CLIENT_COLLAB_REQUEST_LIMIT } from '../utils';

export interface ClientWithCampaigns extends Client {
  campaigns: Campaign[];
}

const ClientPage: React.FC = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { isWhiteLabeled, clientId: whiteLabelClientId } = useContext(WhiteLabelContext);
  const [client, setClient] = useState<ClientWithCampaigns | null>(null);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser } = useAuth();
  const collabData = useContext(CollabDataContext);
  const campaigns = useContext(InfluencerCampaignDataContext);
  const { getRandomImage, randomImage } = useRandomImage();
  // If white-labeled, override the clientId from params
  const effectiveClientId = isWhiteLabeled ? whiteLabelClientId : clientId;

  const pendingRequests = collabData.filter(collab => 
    collab.status === 'pendingClientApproval' &&  

    getClientIdFromCollabRequest(collab, campaigns?.[collab.id]) === effectiveClientId
  );

  // Hide back button and prevent navigation if white-labeled
  const showBackButton = !isWhiteLabeled && currentUser;
  const handleRequestCollab = () => {
    
    if (!currentUser) {
      onOpen();
      return;
    }

    const navigationPath = isWhiteLabeled ? "/request-collab" : `/explore/client/${effectiveClientId}/request-collab`;
  
    navigate(navigationPath, { 
      state: { 
        client: client,
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!effectiveClientId) return;
        
        const data = await exploreApi.getClientAndCampaigns(effectiveClientId);
        if (data) {
          setClient({ ...data.client, campaigns: data.campaigns });
        }
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [effectiveClientId]);

  if (loading) {
    return (
      <Flex height="100vh" align="center" justify="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!client) {
    return (
      <Box p={6}>
        <Text>Client not found</Text>
      </Box>
    );
  }

  return (
    <Box minH="100vh" bg="gray.50">
      {showBackButton && (
        <IconButton
          icon={<ArrowBackIcon />}
          aria-label="Back to Explore"
          onClick={() => navigate('/explore')}
          position="absolute"
          top={4}
          left={4}
          zIndex={2}
          bg="white"
          _hover={{ bg: "gray.100" }}
        />
      )}
      <Box position="relative" height="250px">
        <Image
          src={client.bannerPicture || getRandomImage()}
          alt={client.accountName}
          objectFit="cover"
          width="100%"
          height="100%"
          onError={(e) => { 
            if (!randomImage) {
              e.currentTarget.src = getRandomImage();
            }
          }}
        />
        <HStack 
          position="absolute" 
          top={4} 
          right={4} 
          spacing={1}
        >
          {client.instagramHandle && (
            <Link href={`https://instagram.com/${client.instagramHandle}`} isExternal>
              <Box
                bg="white"
                p={2}
                borderRadius="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaInstagram} boxSize={5} color="black" />
              </Box>
            </Link>
          )}
          {client.tiktokHandle && (
            <Link href={`https://tiktok.com/@${client.tiktokHandle}`} isExternal>
              <Box
                bg="white"
                p={2}
                borderRadius="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={FaTiktok} boxSize={5} color="black" />
              </Box>
            </Link>
          )}
        </HStack>
      </Box>

      <Box 
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="30px 30px 0 0"
        borderBottom="none"
        mt="-20px"
        bg="gray.50"
        position="relative"
        pt={6}
      >
        <Heading pl={6} fontWeight="black" size="lg" color="gray.700">
          Become a brand partner
        </Heading>
      </Box>
      {client.campaigns.length > 0 ? (
      <Box p={6} >
        <Text fontSize="lg" fontWeight="semibold" mb={3}>
          Available Campaigns
        </Text>
        <Box overflowX="auto" whiteSpace="nowrap">
          {client.campaigns.map((campaign) => (
            <Box as="span" display="inline-block" key={campaign.id}>
              <CampaignComponent campaign={campaign} client={client} />
            </Box>
          ))}
          </Box>
        </Box>
      ) : (
        <Box p={6}>
          <Text fontSize="lg" fontWeight="semibold" mb={3}>
            No pre-existing campaigns available
          </Text>
        </Box>
      )}

      {pendingRequests.length > 0 &&
      <Box p={6}>
        <Text fontSize="lg" fontWeight="semibold" mb={3}>
          Pending Requests
        </Text>
        <Box overflowX="auto" whiteSpace="nowrap">
          {pendingRequests.map((collab) => (
            <Box as="span" display="inline-block" key={collab.id}>
              <CampaignComponent campaign={campaigns?.[collab.id]} client={client} requestedCollab={collab} />
            </Box>
          ))}
        </Box>
      </Box>
      }
      {pendingRequests.length < SINGLE_CLIENT_COLLAB_REQUEST_LIMIT &&
        <Box p={6} textAlign="center" mt="auto" >
          <Text fontSize="md" color="black" mb={3} fontWeight="bold">
            Don't like the available offers? Create your own!
          </Text>
          <Button
            bg="black"
            color="white"
            size="md"
            onClick={handleRequestCollab}
            width="80%"
            _hover={{ bg: "blackAlpha.800" }}
          >
            Request Collab
          </Button>
        </Box>
      }
      <SignInSignUpModal
        isOpen={isOpen}
        onClose={onClose}
        returnTo={`/client/${effectiveClientId}/request-collab`}
        returnState={{
          client: client
        }}
      />
    </Box>
  );
};

export default ClientPage;
