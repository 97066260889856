import { Campaign } from '../../../../types';

export const getTimezone = (locationId: string | undefined, campaign: Campaign): string => {
  if (locationId && campaign?.locations) {
    const location = campaign.locations.find(loc => loc.docId === locationId);
    if (location?.timezone) {
      return location.timezone;
    }
  }
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertToLocalTime = (selectedDate: Date, selectedTime: string, timezone: string): TimeConversionResult => {
  const [time, modifier] = selectedTime.split(' ');
  let [hours, minutes] = time.split(':').map(Number);
  
  if (modifier === 'PM' && hours < 12) hours += 12;
  else if (modifier === 'AM' && hours === 12) hours = 0;
  
  const dateTime = new Date(selectedDate);
  dateTime.setHours(hours, minutes);
  
  const options = { timeZone: timezone };
  const tzOffset = new Date(dateTime.toLocaleString('en-US', options)).getTime() - dateTime.getTime();
  const localTime = new Date(dateTime.getTime() - tzOffset);
  
  return { localTime, dateTime };
};

interface TimeConversionResult {
  localTime: Date;
  dateTime: Date;
} 