import React from 'react';
import { Flex, Box, Text, Icon } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdHome, MdAccountBalanceWallet, MdExplore } from 'react-icons/md';
import { IoMdMail } from 'react-icons/io';

const TabBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { icon: MdHome, label: 'Home', path: '/collabs' },
    { icon: MdExplore, label: 'Explore', path: '/explore' },
    { icon: IoMdMail, label: 'Inbox', path: '/inbox' },
    { icon: MdAccountBalanceWallet, label: 'Wallet', path: '/wallet' },
  ];

  const isActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  return (
    <Flex
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bg="white"
      borderTop="1px solid"
      borderColor="gray.200"
      height="60px"
      justify="space-around"
      align="center"
      zIndex={1000}
    >
      {tabs.map((tab) => (
        <Box
          key={tab.path}
          flex={1}
          textAlign="center"
          cursor="pointer"
          onClick={() => navigate(tab.path)}
          py={2}
          color={isActive(tab.path) ? 'black' : 'gray.500'}
        >
          <Icon
            as={tab.icon}
            w={6}
            h={6}
            mb={1}
          />
          <Text
            fontSize="xs"
          >
            {tab.label}
          </Text>
        </Box>
      ))}
    </Flex>
  );
};

export default TabBar;
