import React, { useState, useEffect, useContext } from 'react';
import { Box, VStack, Heading, Text, Button, useToast, IconButton, Flex } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { updateCollabDate, cancelCollab } from '../../../services/firebaseService';
import { cancelCollabAndNotifyClient, scheduleCollabReminder } from 'services/flaskService';
import DateTimeSelector from './components/DateTimeSelector';
import LocationSearchBox from './components/LocationSearchBox';
import PartySizeSelector from './components/PartySizeSelector';
import CancelOptionsModal from './components/CancelOptionsModal';
import { getEndDate, getStartDate } from './utils/dateUtils';
import { initializeSchedulingData, isSubmitDisabled } from './utils/validateScheduling';
import { getLocationInfo } from './utils/locationUtils';
import { convertToLocalTime, getTimezone } from './utils/timezoneUtils';
import { generateTimeOptionsForDateRange } from './utils/timeOptionsUtils';
import { formatDateForReminder } from './utils/formatUtils';
import { ClientDataContext } from 'contexts/ClientDataContext';

interface SelectedLocation {
  docId: string;
  fullAddress: string;
}

const ScheduleCollabPage: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<SelectedLocation | null>(null);
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [initialTime, setInitialTime] = useState<string>('');
  const [initialPartySize, setInitialPartySize] = useState<number | null>(null);
  const [initialLocation, setInitialLocation] = useState<SelectedLocation | null>(null);
  const [timeOptions, setTimeOptions] = useState<Map<string, { time: string; timezone: string; }[]>>(new Map());
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [locationSearchQuery, setLocationSearchQuery] = useState('');
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);
  const [partySize, setPartySize] = useState<number | null>(null);
  const navigate = useNavigate(); 
  const toast = useToast();
  const location = useLocation();
  const { collab, campaign, influencer } = location.state || {};
  const clients = useContext(ClientDataContext);
  const client = clients[campaign.clientID];

  const isDateAlreadyScheduled = collab?.scheduledCollabDate !== undefined;
  useEffect(() => {
    const initialData = initializeSchedulingData(collab, campaign);
    setSelectedDate(initialData.selectedDate);
    setSelectedTime(initialData.selectedTime);
    setSelectedLocation(initialData.selectedLocation);
    setPartySize(initialData.initialPartySize);
    setInitialDate(initialData.initialDate);
    setInitialTime(initialData.initialTime);
    setInitialPartySize(initialData.initialPartySize);
    setInitialLocation(initialData.initialLocation);
    setLocationSearchQuery(initialData.locationSearchQuery);
  }, [collab, campaign]);

  useEffect(() => {
    const startDate = getStartDate(campaign);
    const endDate = getEndDate(campaign);
    const newTimeOptions = generateTimeOptionsForDateRange(
      startDate,
      endDate,
      campaign,
      selectedLocation?.docId
    );
    setTimeOptions(newTimeOptions);
  }, [campaign?.openHours, selectedLocation?.docId]);

  const handleSubmit = async () => {
    if (!collab.id || !selectedDate || !selectedTime) return;
    if (campaign?.locations?.length > 0 && !selectedLocation) return;

    const timezone = getTimezone(selectedLocation?.docId, campaign);
    const { localTime, dateTime } = convertToLocalTime(selectedDate, selectedTime, timezone);

    try {
      await updateCollabDate(collab.id, localTime, selectedLocation?.docId || null, partySize);
      
      toast({
        title: 'Date and time selected',
        description: 'Your collab date and time have been set successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate('/collabs');

      const formattedDate = formatDateForReminder(dateTime);
      const { address, locationEmails } = getLocationInfo(selectedLocation, campaign);

      scheduleCollabReminder(
        collab.id,
        formattedDate,
        isDateAlreadyScheduled,
        client.accountName,
        influencer.instagramHandle,
        influencer.phoneNumber,
        client.email,
        timezone,
        address,
        locationEmails,
        campaign.clientID,
        partySize
      ).catch(error => console.error('Error scheduling collab reminder:', error));

    } catch (error) {
      console.error('Error setting collab date and time:', error);
      toast({
        title: 'Error',
        description: 'Failed to set collab date and time. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleLocationSelect = (location: SelectedLocation) => {
    setSelectedLocation(location);
    setLocationSearchQuery(location.fullAddress);
  };

  const handleCancel = () => {
    navigate('/collabs');
  };

  const handleCollabCancel = async (influencerCancelReason?: string) => {
    try {
      await cancelCollab(collab.id, influencerCancelReason);
      cancelCollabAndNotifyClient(collab.id, campaign.clientID, client.email, client.accountName, influencer.instagramHandle, influencer.phoneNumber);
      toast({
        title: "Collab canceled",
        description: "The collab has been successfully canceled.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/collabs');
    } catch (error) {
      console.error('Error canceling collab:', error);
      toast({
        title: "Error",
        description: "Failed to cancel the collab. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleOpenCancelOptionsModal = () => {
    setIsCancelModalOpen(true);
  };

  const handleCloseCancelOptionsModal = () => {
    setIsCancelModalOpen(false);
  };

  const handleConfirmCancel = async ( influencerCancelReason?: string) => {
    setIsCancelModalOpen(false);
    await handleCollabCancel(influencerCancelReason);
  };

  const isDisabled = isSubmitDisabled({
    selectedDate,
    selectedTime,
    selectedLocation,
    partySize,
    initialDate,
    initialTime,
    initialLocation,
    initialPartySize,
    campaign
  });

  return (
    <Flex direction="column" height="100svh" bg="white" p={4} position="relative">
      <IconButton
        icon={<ArrowBackIcon />}
        aria-label="Go back"
        position="absolute"
        top={4}
        left={4}
        onClick={() => navigate('/collabs')}
        variant="ghost"
      />
      <VStack spacing={6} align="stretch" flex={1}>
        <Box mt={12}>
          <Heading textAlign="center">Schedule Your Collab</Heading>
          {(campaign?.locations && campaign.locations.length > 0) && (
            <Text textAlign="center">Times are based on the timezone of the location you selected:</Text>
          )}
        </Box>

        <DateTimeSelector
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          timeOptions={timeOptions}
          onDateChange={(date: Date) => {
            setSelectedDate(date);
            setSelectedTime('');
          }}
          onTimeChange={(e) => setSelectedTime(e.target.value)}
          campaign={campaign}
        />

        <LocationSearchBox
          campaign={campaign}
          locationSearchQuery={locationSearchQuery}
          isLocationDropdownOpen={isLocationDropdownOpen}
          onLocationSearch={setLocationSearchQuery}
          onLocationSelect={handleLocationSelect}
          onDropdownToggle={setIsLocationDropdownOpen}
        />

        <PartySizeSelector
          campaign={campaign}
          selectedSize={partySize}
          onSizeSelect={setPartySize}
        />

        <Flex direction="column" gap={2}>
          <Button
            bg="black"
            color="white"
            onClick={handleSubmit}
            isDisabled={isDisabled}
          >
            Confirm Date and Time
          </Button>
          {isDateAlreadyScheduled && (
            <Button
              bg="gray.200"
              color="black"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          )}
          <Text
            color="black"
            textDecoration="underline" 
            cursor="pointer"
            onClick={handleOpenCancelOptionsModal}
            textAlign="center"
          >
            No longer interested? Cancel Collab
          </Text>
        </Flex>
      </VStack>
      
      <CancelOptionsModal
        isOpen={isCancelModalOpen}
        onClose={handleCloseCancelOptionsModal}
        onConfirm={handleConfirmCancel}
        onBack={() => setIsCancelModalOpen(true)}
        clientName={client?.accountName || ""}
      />
    </Flex>
  );
};

export default ScheduleCollabPage;
