import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Heading, Text, VStack, Spinner, Flex, useToast, IconButton, Icon } from '@chakra-ui/react';
import { Campaign, Client } from '@foodfluence/shared';
import CampaignComponent from './components/CampaignComponent';
import { exploreApi } from '../../services/api/explore';
import { useNavigate } from 'react-router-dom';
import ClientComponent from './components/ClientComponent';
import SearchBar from './components/SearchBar';
import { SearchResult } from './utils';
import { FaMapPin } from 'react-icons/fa';
import LocationSearchModal from './components/LocationSearchModal';
import useDebounce from '../../hooks/useDebounce';
import { PlaceDetails } from '../../types';

// Key for storing filters in localStorage
const STORAGE_KEY = 'explore_filters';

const ExplorePage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  
  // Load initial state from localStorage
  const loadSavedFilters = () => {
    try {
      const savedFilters = localStorage.getItem(STORAGE_KEY);
      if (savedFilters) {
        return JSON.parse(savedFilters);
      }
    } catch (e) {
      console.error('Error parsing saved filters', e);
    }
    return {
      searchQuery: '',
      selectedLocation: null,
      searchRadius: 25,
      filters: {
        searchQuery: '',
        location: null
      }
    };
  };
  
  const savedState = loadSavedFilters();
  
  const [activeCampaigns, setActiveCampaigns] = useState<Array<{ campaign: Campaign; client: Client }>>([]);
  const [featuredBrands, setFeaturedBrands] = useState<Array<{ client: Client; campaigns: Campaign[] }>>([]);
  const [allBrands, setAllBrands] = useState<Array<{ client: Client; campaigns: Campaign[] }>>([]);
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [searchQuery, setSearchQuery] = useState(savedState.searchQuery || '');
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<PlaceDetails | null>(savedState.selectedLocation || null);
  const [searchRadius, setSearchRadius] = useState(savedState.searchRadius || 25);
  const [paginationState, setPaginationState] = useState({
    featuredBrands: { cursor: null as string | null, hasMore: true, loading: false },
    activeCampaigns: { cursor: null as string | null, hasMore: true, loading: false },
    allBrands: { cursor: null as string | null, hasMore: true, loading: false }
  });
  
  const featuredBrandsRef = useRef<HTMLDivElement>(null);
  const activeCampaignsRef = useRef<HTMLDivElement>(null);
  const allBrandsRef = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useState<{
    searchQuery: string;
    location: { lat: number; lng: number; radius: number } | null;
  }>(savedState.filters || {
    searchQuery: '',
    location: null
  });

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({
      searchQuery,
      selectedLocation,
      searchRadius,
      filters
    }));
  }, [searchQuery, selectedLocation, searchRadius, filters]);

  const fetchSectionData = async (
    section: 'featuredBrands' | 'activeCampaigns' | 'allBrands',
    isInitial: boolean = false,
    filtersToUse: typeof filters
  ) => {
    try {
      if (isInitial) {
        setLoading(true);
      }
      setPaginationState(prev => ({
        ...prev,
        [section]: { ...prev[section], loading: true }
      }));
      const cursor = isInitial ? null : paginationState[section].cursor;
      const data = await exploreApi.getExploreData(
        section, 
        cursor,
        filtersToUse.searchQuery || filtersToUse.location ? {
          searchQuery: filtersToUse.searchQuery,
          location: filtersToUse.location
        } : undefined
      );

      switch (section) {
        case 'featuredBrands':
          setFeaturedBrands(prev => 
            isInitial ? data.featuredBrands?.data || [] : [...prev, ...(data.featuredBrands?.data || [])]
          );
          break;
        case 'activeCampaigns':
          setActiveCampaigns(prev => 
            isInitial ? data.activeCampaigns?.data || [] : [...prev, ...(data.activeCampaigns?.data || [])]
          );
          break;
        case 'allBrands':
          setAllBrands(prev => 
            isInitial ? data.allBrands?.data || [] : [...prev, ...(data.allBrands?.data || [])]
          );
          break;
      }

      setPaginationState(prev => ({
        ...prev,
        [section]: {
          cursor: data[section]?.cursor || null,
          hasMore: data[section]?.hasMore || false,
          loading: false
        }
      }));
    } catch (err) {
      console.error(`Error fetching ${section}:`, err);
      toast({
        title: 'Error',
        description: `Failed to load ${section}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setPaginationState(prev => ({
        ...prev,
        [section]: { ...prev[section], loading: false }
      }));
    } finally {
      if (isInitial) {
        setLoading(false);
      }
    }
  };

  const fetchAllSections = async (isInitial: boolean = false, filterOverride?: typeof filters) => {
    const filtersToUse = filterOverride || filters;
    
    await Promise.all([
      fetchSectionData('featuredBrands', isInitial, filtersToUse),
      fetchSectionData('activeCampaigns', isInitial, filtersToUse),
      fetchSectionData('allBrands', isInitial, filtersToUse)
    ]);
  };

  useEffect(() => {
    // Initial data fetch using saved filters
    fetchAllSections(true, filters);
  }, []);

  // Create intersection observer for each section
  useEffect(() => {
    const createObserver = (
      ref: React.RefObject<HTMLDivElement>,
      section: 'featuredBrands' | 'activeCampaigns' | 'allBrands'
    ) => {
      const observer = new IntersectionObserver(
        entries => {
          if (
            entries[0].isIntersecting && 
            paginationState[section].hasMore && 
            !paginationState[section].loading
          ) {
            fetchSectionData(section, false, filters);
          }
        },
        { threshold: 0.1 }
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return observer;
    };

    const observers = [
      createObserver(featuredBrandsRef, 'featuredBrands'),
      createObserver(activeCampaignsRef, 'activeCampaigns'),
      createObserver(allBrandsRef, 'allBrands')
    ];

    return () => observers.forEach(observer => observer.disconnect());
  }, [paginationState]);

  useEffect(() => {
    const fetchData = async () => {
      const newFilters = {
        ...filters,
        searchQuery: debouncedSearchQuery
      };
      setFilters(newFilters);

      setPaginationState({
        featuredBrands: { cursor: null, hasMore: true, loading: false },
        activeCampaigns: { cursor: null, hasMore: true, loading: false },
        allBrands: { cursor: null, hasMore: true, loading: false }
      });
      await fetchAllSections(true, newFilters);
    };

    if (debouncedSearchQuery.length >= 2 || debouncedSearchQuery.length === 0) {
      fetchData();
    }
  }, [debouncedSearchQuery]);

  const handleSearch = useCallback(async (query: string) => {
    setSearchQuery(query);
  }, []);

  const handleLocationSelect = async (placeDetails: PlaceDetails, newRadius: number) => {
    let newFilters;
    if (placeDetails) {
      newFilters = {
        ...filters,
        location: {
          lat: placeDetails.lat,
          lng: placeDetails.lng,
          radius: newRadius
        }
      };
    } else {
      newFilters = {
        ...filters,
        location: null
      };
    }

    setSelectedLocation(placeDetails);
    setSearchRadius(newRadius);
    setFilters(newFilters);
    await fetchAllSections(true, newFilters);
  };

  const hasNoResults = featuredBrands.length === 0 && 
    activeCampaigns.length === 0 && 
    allBrands.length === 0;

  return (
    <Box p={4} minHeight="100vh">
      <Flex width="100%" justify="space-between"  mb={4}>
        <Heading size="lg" color="gray.800">Explore</Heading>
        <Flex align="center">
          <IconButton
            aria-label="Search by location"
            icon={<FaMapPin />}
            onClick={() => setIsLocationModalOpen(true)}
            colorScheme={selectedLocation ? "blackAlpha" : "gray"}
            borderRadius="full"
            boxShadow="md"
            _hover={{ bg: "teal.500", color: "white" }}
          />
        </Flex>
      </Flex>

      <VStack spacing={6} align="stretch">

      <SearchBar
          value={searchQuery}
          onChange={setSearchQuery}
          onSearch={handleSearch}
          suggestions={searchResults}
        />  

        {selectedLocation && (
          <Box 
            p={3} 
            borderWidth="1px" 
            borderRadius="md" 
            boxShadow="sm" 
            bg="gray.50"
          >
            <Flex direction="column" align="flex-start">
              <Flex align="center" mb={1}>
                <Icon as={FaMapPin} mr={2} color="teal.500" />
                <Text fontWeight="medium" color="gray.700">{selectedLocation.fullAddress}</Text>
              </Flex>
              <Text fontSize="sm" color="gray.600">{searchRadius} mile radius</Text>
            </Flex>
          </Box>
        )}

        {!loading && hasNoResults ? (
          <Text color="gray.500" textAlign="center">
            {selectedLocation 
              ? `No matches found within ${searchRadius} miles of your selected location`
              : "No matches found for your search"}
          </Text>
        ) : (
          <>
            {true && (
              <>
                {featuredBrands.length > 0 && (
                  <Box width="100%">
                    <Heading size="md" mb={3}>Featured Brands</Heading>
                    <Box overflowX="auto" whiteSpace="nowrap">
                      {featuredBrands.map(({ client }) => (
                        <ClientComponent 
                          key={client.id}
                          client={client}
                          clientId={client.id}
                          locationFilter={filters.location}
                        />
                      ))}
                      {paginationState.featuredBrands.loading && (
                        <Spinner size="sm" ml={4} />
                      )}
                      <Box ref={featuredBrandsRef} display="inline-block" width="20px" />
                    </Box>
                  </Box>
                )}

                {activeCampaigns.length > 0 && (
                  <Box width="100%">
                    <Heading size="md" mb={3}>Available Campaigns</Heading>
                    <Box overflowX="auto" whiteSpace="nowrap">
                      {activeCampaigns.map(({ campaign, client }) => (
                        <CampaignComponent
                          key={campaign.id}
                          campaign={campaign}
                          client={client}
                          isExplorePage={true}
                          locationFilter={filters.location}
                        />
                      ))}
                      {paginationState.activeCampaigns.loading && (
                        <Spinner size="sm" ml={4} />
                      )}
                      <Box ref={activeCampaignsRef} display="inline-block" width="20px" />
                    </Box>
                  </Box>
                )}

                {allBrands.length > 0 && (
                  <Box width="100%">
                    <Heading size="md" mb={3}>All Brands</Heading>
                    <Box overflowX="auto" whiteSpace="nowrap">
                      {allBrands.map(({ client }) => (
                        <ClientComponent 
                          key={client.id}
                          client={client}
                          clientId={client.id}
                          locationFilter={filters.location}
                        />
                      ))}
                      {paginationState.allBrands.loading && (
                        <Spinner size="sm" ml={4} />
                      )}
                      <Box ref={allBrandsRef} display="inline-block" width="20px" />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </VStack>
      
      <LocationSearchModal 
        isOpen={isLocationModalOpen}
        onClose={() => setIsLocationModalOpen(false)}
        onLocationSelect={handleLocationSelect}
        currentLocation={selectedLocation}
        radius={searchRadius}
        onClearLocation={() => {
          handleLocationSelect(null, 25);
        }}
      />
    </Box>
  );
};

export default ExplorePage;
