import React, { useState, useEffect, useContext } from 'react';
import { Box, VStack, Heading, Flex, Button, Text, Avatar, useDisclosure, Spinner, Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Badge } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CollabComponent from './components/CollabComponent';
import PastCollabComponent from './components/pastCollabComponent';
import { InfluencerDataContext } from '../../contexts/InfluencerDataContext';
import { InfluencerCampaignDataContext } from '../../contexts/InfluencerCampaignDataContext';
import { CollabDataContext } from '../../contexts/collabDataContext';
import { LuAlarmClock } from "react-icons/lu";
import { Collab } from '../../types';
import { useSwipeable } from 'react-swipeable'; // Import useSwipeable hook
import { getAuth, signOut } from 'firebase/auth';
import HelpModal from '../../components/HelpModal';
import { getCurrentCollabs, getPastCollabs } from './utils/collabUtils';
import { areProfileFieldsFilled, InfluencerDataToInfluencerProfileData } from '../profile/utils';

const CollabsPage: React.FC<{ isFirstTime: boolean }> = ({ isFirstTime }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { influencerData, loading } = useContext(InfluencerDataContext);
  const collabData = useContext(CollabDataContext)
  const influencerCampaignData = useContext(InfluencerCampaignDataContext);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(isFirstTime);
  const [isIntercomOpen, setIsIntercomOpen] = useState(false);

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!loading && !influencerData) {
        setShowSignOutModal(true);
      } else {
        setShowSignOutModal(false);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [loading, influencerData]);
  
  const currentCollabs = getCurrentCollabs(collabData, influencerCampaignData);

  const pastCollabs = getPastCollabs(collabData, influencerCampaignData);

  // Use the useSwipeable hook to handle swipe gestures
  const swipeHandlers = useSwipeable({
    onSwipedRight: onClose, // Close the drawer when swiped right
  });

  useEffect(() => {
    if (influencerData) {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "tkgj02b2",
        email: influencerData?.email_address,
        hide_default_launcher: true
      });
    }
    if (isFirstTime) {
      setIsHelpModalOpen(true);
    }
  }, [influencerData, isFirstTime]);

  const toggleIntercom = () => {
    if (isIntercomOpen) {
      window.Intercom('hide');
    } else {
      window.Intercom('show');
    }
    setIsIntercomOpen(!isIntercomOpen);
  };

  const isProfileComplete = areProfileFieldsFilled(InfluencerDataToInfluencerProfileData(influencerData));

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" bg="white" height="100svh" overflow="auto">
      <Box width="100%" bg="white" display="flex" flexDirection="column" alignItems="center" overflowY="auto" flex="1">
        <Box width="100%" bg="white" color="black" p={4} position="relative">
          <Flex align="center" justify="space-between" width="100%">
            <VStack align="start" spacing={2}>
              <Heading fontSize="2xl" fontWeight="bold">
                Welcome Back{influencerData?.instagramHandle != null? ", " + influencerData?.instagramHandle : ""}!
              </Heading>
              <Text fontSize="md" color="gray.500">Check out what's new below</Text>
            </VStack>

            <Box position="relative">
              <Avatar
                src={influencerData?.profile_pic_url}
                name={influencerData?.firstName || ''}
                size="md"
                cursor="pointer"
                onClick={handleProfileClick}
                alignSelf="flex-start"
              />
              {!isProfileComplete && (
                <Badge
                  position="absolute"
                  bottom="-2px"
                  right="-2px"
                  bg="red.500"
                  color="white"
                  borderRadius="full"
                  boxSize="18px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="xs"
                  fontWeight="bold"
                >
                  !
                </Badge>
              )}
            </Box>
          </Flex>

          <VStack spacing={4} width="100%" mt={10}>
            <Box width="100%">
              <Flex ml={2} direction="row" alignItems="center" justifyContent="space-between">
                <Flex alignItems="center" gap="5px">
                  <Heading size="md">Current Collabs</Heading>
                  <LuAlarmClock size="20px" />
                </Flex>
              </Flex>
              {loading ? (
                <Spinner size="xl" color="teal.500" />
              ) : currentCollabs.length > 0 ? (
                <Box overflowX="auto" whiteSpace="nowrap" mt={2}>
                  {currentCollabs.map((collab: Collab, index: number) => (
                    <Box as="span" display="inline-block" key={index} mr={2}>
                      <CollabComponent
                        collab={collab}
                        campaign={influencerCampaignData[collab.id]}
                        influencer={influencerData}
                      />
                    </Box>
                  ))}
                </Box>
              ) : (
                <Text mt={4} ml={2} textAlign="left">You have no current collabs</Text>
              )}
            </Box>

            <Box width="100%" mt={6}>
              <Heading size="md" ml={2}>Previous Collabs</Heading>
              {pastCollabs.length > 0 ? (
                <Box overflowX="auto" whiteSpace="nowrap" mt={2}>
                  {pastCollabs.map((collab: Collab, index: number) => (
                    <Box as="span" display="inline-block" key={index} mr={2}>
                      <PastCollabComponent
                        collab={collab}
                        campaign={influencerCampaignData[collab.id]}
                        influencerName={influencerData?.firstName || ''}
                      />
                    </Box>
                  ))}
                </Box>
              ) : (
                <Text mt={4}  ml={2} textAlign="left">You have no past collabs</Text>
              )}
            </Box>
          </VStack>
        </Box>
      </Box>

      {!loading && showSignOutModal && (
        <Modal isOpen={showSignOutModal} onClose={() => {}} isCentered closeOnOverlayClick={false} closeOnEsc={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody textAlign="center" py={8}>
              <Text fontSize="xl" fontWeight="bold" mb={4}>
                No influencer data found
              </Text>
              <Text mb={6}>
                We couldn't find your influencer data. Please sign out and try logging in again.
              </Text>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button
                onClick={handleSignOut}
                colorScheme="red"
                size="lg"
                width="full"
              >
                Sign Out
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <HelpModal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} />
    </Box>
  );
};

export default CollabsPage;
