import { FoodfluenceLocation } from '@foodfluence/shared';

export const SINGLE_CLIENT_COLLAB_REQUEST_LIMIT = 2;

export interface SearchResult {
  type: 'location' | 'client';
  text: string;
  id: string;
  clientId: string;
  location?: FoodfluenceLocation;
  placeId?: string;
}
