import React, { useContext } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { InfluencerDataProvider } from './contexts/InfluencerDataContext';
import { CollabDataProvider } from './contexts/collabDataContext';
import { InfluencerCampaignDataProvider } from './contexts/InfluencerCampaignDataContext';
import { ClientDataProvider } from 'contexts/ClientDataContext';
import NewLoginPage from 'features/authentication/NewLogin';
import ReturningUser from 'features/authentication/ReturningUser';
import CollabsPage from './features/collabs/index';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import { isSignInWithEmailLink } from 'firebase/auth';
import Verification from 'features/authentication/Verification';
import { VerificationProvider } from 'contexts/PhoneVerificationContext';
import RecaptchaInitializer from 'features/collabs/components/RecaptchaInitializer';
import RedeemRewardPage from './features/collabs/pages/RedeemRewardPage';
import ViewRewardPage from './features/collabs/pages/ViewRewardPage';
import SignUpPage from 'features/authentication/NewSignup';
import EmailSentPage from 'features/authentication/EmailSentPage';
import CompleteCollabPage from 'features/collabs/pages/CompleteCollabPage';
import WalletPage from 'features/wallet';
import MainLayout from './components/MainLayout';
import ReviewCollabPage from './features/collabs/pages/ReviewCollabPage';
import ScheduleCollabPage from './features/collabs/scheduling';
import CounterCollabPage from './features/collabs/pages/CounterCollabPage';
import ClientPage from './features/explore/pages/ClientPage';
import SignUpApplicationPage from 'features/authentication/SignUpApplicationPage';
import RequestCollabPage from 'features/explore/pages/RequestCollabPage';
import ExplorePage from './features/explore';
import { WhiteLabelContext } from './contexts/WhiteLabelContext';
import AuthRouteGuard from './components/AuthRouteGuard';
import Inbox from 'features/inbox';
import MessagesPage from 'features/inbox/components/messagesPage';
import { ConversationsProvider } from './contexts/ConversationsDataContext';
import { InstagramVerificationProvider } from 'contexts/InstagramVerificationContext';
import ProfileEditPage from 'features/profile/components/ProfileEditPage';
import ProfilePage from 'features/profile';

const App: React.FC = () => {
  const [user] = useAuthState(auth);
  const isEmailSignInLink = isSignInWithEmailLink(auth, window.location.href);
  const { isWhiteLabeled } = useContext(WhiteLabelContext);

  return (
    <>
      <RecaptchaInitializer />
        <InfluencerDataProvider>
          <CollabDataProvider>
            <InfluencerCampaignDataProvider> 
              <ClientDataProvider>
                <InstagramVerificationProvider>
                <VerificationProvider>
                  <ConversationsProvider>
                  <Routes>                    
                    {/* Auth routes - accessible regardless of white label */}
                    <Route path="login" element={!user ? <NewLoginPage /> : <Navigate to={isWhiteLabeled ? "/" : "/collabs"} replace />} />
                    <Route path="new-signup" element={!user ? <SignUpPage /> : <Navigate to={isWhiteLabeled ? "/" : "/collabs"} replace />} />
                    <Route path="signup-application" element={!user ? <SignUpApplicationPage /> : <Navigate to={isWhiteLabeled ? "/" : "/collabs"} replace />} />
                    <Route path="returning-user" element={!user ? <ReturningUser /> : <Navigate to={isWhiteLabeled ? "/" : "/collabs"} replace />} />
                    <Route path="verification" element={!user ? <Verification /> : <Navigate to={isWhiteLabeled ? "/" : "/collabs"} replace />} />
                    <Route path="email-sent" element={!user ? <EmailSentPage /> : <Navigate to={isWhiteLabeled ? "/" : "/collabs"} replace />} />

                    {/* White labeled client routes */}
                    {isWhiteLabeled ? (
                      <>
                        <Route path="/" element={<MainLayout><ClientPage /></MainLayout>} />
                        <Route path="request-collab" element={<RequestCollabPage />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                      </>
                    ) : (
                      <>
                        {/* Main app routes - protected by auth guard */}
                        <Route element={<AuthRouteGuard><MainLayout><Outlet /></MainLayout></AuthRouteGuard>}>
                          <Route path="/" element={user ? <Navigate to="/collabs" replace /> : <Navigate to="/login" replace />} />
                          <Route path="profile" element={<MainLayout><Outlet /></MainLayout>}>
                            <Route index element={<ProfilePage />} />
                            <Route path="edit" element={<ProfileEditPage />} />
                          </Route>
                          <Route path="collabs" element={<MainLayout><Outlet /></MainLayout>}>
                            <Route index element={<CollabsPage isFirstTime={!user && isEmailSignInLink} />} />
                            <Route path="review-collab" element={<ReviewCollabPage />} />
                            <Route path="redeem-reward" element={<RedeemRewardPage />} />
                            <Route path="view-reward" element={<ViewRewardPage />} />
                            <Route path="schedule-collab" element={<ScheduleCollabPage />} />
                            <Route path="complete-collab" element={<CompleteCollabPage />} />
                            <Route path="counter-collab" element={<CounterCollabPage />} />
                            <Route path="request-collab" element={<RequestCollabPage />} />
                          </Route>
                          <Route path="wallet" element={<WalletPage />} />
                          <Route path="inbox" element={<MainLayout><Outlet /></MainLayout>}>
                            <Route index element={<Navigate to="current" replace />} />
                            <Route path="current" element={<Inbox tab="current" />} />
                            <Route path="previous" element={<Inbox tab="previous" />} />
                            <Route path="unread" element={<Inbox tab="unread" />} />
                          </Route>
                          <Route path="inbox/:tab/messages" element={<MessagesPage />} />
                          <Route path="explore/*" element={
                            <Routes>
                              <Route path="/" element={<ExplorePage />} />
                              <Route path="client/:clientId">
                                <Route index element={<ClientPage />} />
                                <Route path="request-collab" element={<RequestCollabPage />} />
                              </Route>
                            </Routes>
                          } />
                          <Route path="*" element={<Navigate to="/login" replace />} />
                        </Route>
                      </>
                    )}
                  </Routes>
                  </ConversationsProvider>
                  </VerificationProvider>
                </InstagramVerificationProvider>
              </ClientDataProvider>
            </InfluencerCampaignDataProvider>
          </CollabDataProvider>
        </InfluencerDataProvider>
    </>
  );
};

export default App;
