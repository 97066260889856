import { Campaign } from '../../../../types';

export const DEFAULT_MAX_PARTY_SIZE = 3;

export const getMaxPartySize = (campaign?: Campaign): number => {
  return campaign?.maxPartySize || DEFAULT_MAX_PARTY_SIZE;
};

export const generatePartySizeOptions = (maxSize: number): number[] => {
  return Array.from({ length: maxSize }, (_, i) => i + 1);
};

export const isValidPartySize = (size: number | null, maxSize: number): boolean => {
  return size !== null && size > 0 && size <= maxSize;
}; 