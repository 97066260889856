import { Campaign } from '../../../../types';
import { convertFirebaseTimestampToDate } from './formatUtils';
export const getStartDate = (campaign?: Campaign): Date => {
  return campaign?.startDate ? convertFirebaseTimestampToDate(campaign.startDate) : new Date();
};

export const getEndDate = (campaign?: Campaign): Date | null => {
  return campaign?.endDate ? convertFirebaseTimestampToDate(campaign.endDate) : null;
};

export const isValidDateRange = (date: Date | null, campaign?: Campaign): boolean => {
  if (!date) return false;
  
  const startDate = getStartDate(campaign);
  const endDate = getEndDate(campaign);
  
  return date >= startDate && (!endDate || date <= endDate);
};

export const hasAvailableTimeSlots = (date: Date, timeOptions: Map<string, { time: string; timezone: string; }[]>): boolean => {
  return (timeOptions.get(date.toISOString())?.length ?? 0) > 0;
}; 