import React, { useContext, useEffect, useState } from 'react';
import { 
  Box, 
  Text, 
  VStack, 
  HStack, 
  Button, 
  IconButton, 
  Input,
  Textarea,
  useToast,
  Select,
  Heading,
} from '@chakra-ui/react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { Campaign, Client, Collab } from '@foodfluence/shared';
import { campaignTypeDisplayNames } from '../../../types';
import { InfluencerDataContext } from 'contexts/InfluencerDataContext';
import CreativeBrief from 'features/collabs/components/creativeBrief';
import { collabApi } from 'services/api/collab';
import LocationList from 'components/LocationList';
import { isInternalClient } from 'utils/clientUtils';
import { getClientIdFromCollabRequest } from 'utils/collabUtils';
import { CollabDataContext } from 'contexts/collabDataContext';
import { InfluencerCampaignDataContext } from 'contexts/InfluencerCampaignDataContext';
import { SINGLE_CLIENT_COLLAB_REQUEST_LIMIT } from '../utils';

const generateCollab = (campaign: Campaign): Collab => {
    return {
      id: campaign.id,
      credit: campaign.credit || 0,
      cash: campaign.cash || 0,
      deliverableCounts: campaign.deliverableCounts || {},
      status: 'pendingInfluencerApproval',
      influencerID: '',
      influencerCampaignID: campaign.id,
      logs: [],
    };
  };
  
const RequestCollabPage: React.FC = () => {
    
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const campaign: Campaign | null = location.state?.campaign;
  const client: Client | null = location.state?.client;
  const requestedCollab: Collab | null = location.state?.requestedCollab;

  const isCustomRequest = !campaign;

  const clientCampaignTypes = client?.campaignTypes || ['inStore'];

  const defaultCampaignType = campaign?.campaignType || clientCampaignTypes[0];

  const [tikTokCount, setTikTokCount] = useState(requestedCollab?.proposedInfluencerDeliverableCounts?.tiktoks || campaign?.deliverableCounts?.tiktoks || 0);
  const [igReelCount, setIgReelCount] = useState(requestedCollab?.proposedInfluencerDeliverableCounts?.instagramReels || campaign?.deliverableCounts?.instagramReels || 0);
  const [igPostCount, setIgPostCount] = useState(requestedCollab?.proposedInfluencerDeliverableCounts?.instagramPosts || campaign?.deliverableCounts?.instagramPosts || 0);
  const [igStoryCount, setIgStoryCount] = useState(requestedCollab?.proposedInfluencerDeliverableCounts?.instagramStories || campaign?.deliverableCounts?.instagramStories || 0);
  const [cashAmount, setCashAmount] = useState(requestedCollab?.proposedInfluencerCash || campaign?.cash || 0);
  const [creditAmount, setCreditAmount] = useState(requestedCollab?.proposedInfluencerCredit || campaign?.credit || 0);
  const [note, setNote] = useState(requestedCollab?.influencerNote || '');
  const [campaignType, setCampaignType] = useState<'shipToHome' | 'inStore'>(defaultCampaignType);;
  const {influencerData} = useContext(InfluencerDataContext);
  const collabData = useContext(CollabDataContext);
  const campaigns = useContext(InfluencerCampaignDataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const pendingRequests = collabData.filter(collab => 
    collab.status === 'pendingClientApproval' &&    
    getClientIdFromCollabRequest(collab, campaigns?.[collab.id]) === client?.id
  );

  const handleIncrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
    setter(value + 1);
  };

  const handleDecrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
    if (value > 0) {
      setter(value - 1);
    }
  };

  const handleCashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setCashAmount(value);
    }
  };

  const handleCreditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setCreditAmount(value);
    }
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const isValid = (): boolean => {
    return ((!requestedCollab && pendingRequests.length < SINGLE_CLIENT_COLLAB_REQUEST_LIMIT) || requestedCollab && 
      (tikTokCount > 0 ||
      igReelCount > 0 ||
      igPostCount > 0 ||
      igStoryCount > 0) &&
      (cashAmount > 0 || creditAmount > 0 || campaignType === 'shipToHome')
    );
  };

  const handleSendRequest = async () => {
    try {
      setIsSubmitting(true);
      const deliverableCounts = {
        tiktoks: tikTokCount,
        instagramReels: igReelCount,
        instagramPosts: igPostCount,
        instagramStories: igStoryCount
      };
  
      if (requestedCollab) {
        await collabApi.updateCollabRequest({
          collabId: requestedCollab.id,
          deliverableCounts,
          cash: cashAmount,
          credit: creditAmount,
          note: isCustomRequest ? note : null,
          customCollabType: isCustomRequest ? campaignType : null,
        });
      } else {
        await collabApi.createCollabRequest({
          influencerID: influencerData.id,
          deliverableCounts,
          cash: cashAmount,
          credit: creditAmount,
          campaignId: campaign?.id || null,
          note: isCustomRequest ? note : null,
          customCollabType: isCustomRequest ? campaignType : null,
          clientId: client.id,
          isExternalClient: !isInternalClient(client),
          clientName: client.accountName,
          clientEmail: client.email,
          influencerHandle: influencerData.instagramHandle,
          prospectiveEmails: client.prospectiveEmails || []
      });
      }
  
      toast({
        title: requestedCollab ? "Request updated successfully" : "Request sent successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
  
      handleBack();
    } catch (error) {
      toast({
        title: "Error sending request",
        description: "Please try again later",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };
  
  return (
    <Box minH="100vh" bg="white" p={4}>
        <IconButton
          icon={<ArrowBackIcon/>}
          onClick={() => handleBack()}
          position="absolute"
          top={4}
          left={4}
          aria-label="Go back"
          bg="white"
          color="black"
          _hover={{ bg: "gray.100" }}
        />
        <Heading 
          fontSize="2xl" 
          textAlign="center" 
          mt={1}
          mb={isCustomRequest ? 2 : 4}
        >
          {requestedCollab 
            ? "Update Collab" 
            : isCustomRequest 
              ? "Custom Collab Request"
              : "Review Campaign"}
        </Heading>

      <VStack spacing={4} align="stretch">
        {!isCustomRequest && (
            <CreativeBrief 
              collab={generateCollab(campaign)} 
              campaign={campaign}
              isRequest={true}
          />
        )}

        {isCustomRequest && clientCampaignTypes.length > 1 && (
          <Box>
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Collab Type
            </Text>
            <Select
              value={campaignType}
              onChange={(e) => setCampaignType(e.target.value as 'shipToHome' | 'inStore')}
            >
              {clientCampaignTypes.map((type) => (
                <option key={type} value={type}>{campaignTypeDisplayNames[type]}</option>
              ))}
            </Select>
          </Box>
        )}

        <Box mt={3}>
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            {isCustomRequest ? "Desired Deliverables" : "Customize Deliverables"}
          </Text>
          <Box borderWidth="1px" borderRadius="md" p={4}>
            <VStack spacing={3} align="stretch">
              {[
                { label: 'TikTok', count: tikTokCount, setCount: setTikTokCount },
                { label: 'IG Reel', count: igReelCount, setCount: setIgReelCount },
                { label: 'IG Post', count: igPostCount, setCount: setIgPostCount },
                { label: 'IG Story', count: igStoryCount, setCount: setIgStoryCount },
              ].map(({ label, count, setCount }) => (
                <HStack key={label} justify="space-between">
                  <Text fontSize="md" fontWeight="medium">{label}</Text>
                  <HStack>
                    <IconButton
                      icon={<FaMinus style={{ color: 'red' }} />}
                      aria-label={`Decrease ${label}`}
                      onClick={() => handleDecrement(setCount, count)}
                      size="sm"
                      variant="ghost"
                      isDisabled={count === 0}
                    />
                    <Text>{count}</Text>
                    <IconButton
                      icon={<FaPlus style={{ color: 'green' }} />}
                      aria-label={`Increase ${label}`}
                      onClick={() => handleIncrement(setCount, count)}
                      variant="ghost"
                      size="sm"
                    />
                  </HStack>
                </HStack>
              ))}
            </VStack>
          </Box>
        </Box>

        <Box mt={3}>
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            {isCustomRequest ? "Desired Compensation" : "Customize Compensation"}
          </Text>
          <VStack spacing={4}>
            {campaignType === 'inStore' && (
              <Box width="100%">
                <Text mb={2}>Credit</Text>
                <Input
                  value={creditAmount}
                  onChange={handleCreditChange}
                />
              </Box>
            )}

            <Box width="100%">
              <Text mb={2}>Cash (Paid after content is verified)</Text>
              <Input
                value={cashAmount}
                onChange={handleCashChange}
              />
            </Box>
          </VStack>
        </Box>
        {isCustomRequest && (
          <Box mt={3}>
            {campaignType === 'inStore' && (
              <LocationList locations={client?.foodfluenceLocations || []} title="Possible Locations" />
            )}
            <Text fontSize="xl" fontWeight="bold" mb={4} mt={3}>Additional Notes</Text>
            <Textarea
              value={note}
              onChange={handleNoteChange}
              placeholder="Describe what you're looking for in this collaboration..."
              minH="150px"
            />
          </Box>
        )}

        <Text fontSize="md" color="black" mb={3}>
          {(!requestedCollab && pendingRequests.length >= SINGLE_CLIENT_COLLAB_REQUEST_LIMIT) ? 'You have reached the maximum number of collab requests for this brand. Please wait for your previous request to be approved or denied before requesting a new collab.' : ''}
        </Text>

        <Button
          bg="black"
          color="white"
          size="lg"
          onClick={handleSendRequest}
          isDisabled={!isValid() || isSubmitting}
          isLoading={isSubmitting}
          _hover={{ bg: "blackAlpha.800" }}
        >
          {requestedCollab ? "Update Request" : "Send Request"}
        </Button>
      </VStack>
    </Box>
  );
};

export default RequestCollabPage; 