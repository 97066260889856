import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

interface AuthRouteGuardProps {
  children: React.ReactNode;
}

const AuthRouteGuard: React.FC<AuthRouteGuardProps> = ({ children }) => {
  const [user] = useAuthState(auth);
  const location = useLocation();
  
  // Allow access to auth routes without authentication
  const authPaths = ['/login', '/new-signup', '/signup-application', '/returning-user', '/verification', '/email-sent'];
  if (authPaths.includes(location.pathname)) {
    return <>{children}</>;
  }

  // For explore routes, allow access without authentication
  const clientPathRegex = /^\/explore\/client\/[^/]+\/?$/;
  if (clientPathRegex.test(location.pathname)) {
    return <>{children}</>;
  }

  // For all other routes, require authentication
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default AuthRouteGuard; 