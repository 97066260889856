import { FormControl, FormLabel, Flex, Text } from '@chakra-ui/react';
import Select from 'react-select';
import { tagOptions } from '../../../../types';

const ContentInterests = ({ tags, setTags, RequiredStar }) => {

  return (
    <>
      <Text fontWeight="bold" fontSize="xl">Niches</Text>
      <FormControl mb={2}>
        <Flex alignItems="center" mb={2}>
          <FormLabel mb="0">Categories<RequiredStar /></FormLabel>
          <Text color="gray.500" fontSize="xs" ml={-1}>(select one or multiple)</Text>
        </Flex>
        <Select
          isMulti
          options={tagOptions}
          value={tags}
          onChange={(newValue) => setTags(newValue)}
          placeholder="Select one or multiple"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
        />
      </FormControl>
    </>
  );
};

export default ContentInterests; 