import React, { useState, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Box, Button, FormControl, VStack, Text, useToast } from '@chakra-ui/react';
import { verifyPhoneCode } from '../../services/firebaseService';
import ReactCodeInput from 'react-code-input';
import { VerificationContext } from '../../contexts/PhoneVerificationContext';

const EnterVerificationCode: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { confirmationResult } = useContext(VerificationContext);

  const postSignInPageInfo = useLocation().state;

  const handleVerificationCodeSubmit = async () => {
    setIsLoading(true);
    try {
      const isValid = await verifyPhoneCode(confirmationResult, verificationCode);
      if (isValid) {
        navigate(postSignInPageInfo?.returnTo || '/collabs',
          postSignInPageInfo ? {
            state: postSignInPageInfo.state
          } : undefined
        );
      } else {
        toast({
          title: "Error",
          description: "Invalid verification code. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error verifying phone code:', error);
      toast({
        title: "Error",
        description: "An error occurred during phone code verification. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      height="100svh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="white"
      p={{ base: "2", md: "4", lg: "8" }}
    >
      <Button 
        onClick={() => navigate(-1)} 
        position="absolute"
        top="16px"
        left="16px"
        variant="ghost"
      >
        &larr;
      </Button>
      <VStack spacing={6} align="center" width="100%" maxWidth="400px">
        <Text fontSize="32px" fontWeight="bold" textAlign="center" mt={30}>
          Please Enter the 6 Digit Code
        </Text>
        <FormControl id="verificationCode">
          <Box ml="61">
            <ReactCodeInput
                          type="text"
                          fields={6}
                          value={verificationCode}
                          onChange={(value: string) => setVerificationCode(value)}
                          inputMode="tel" name={''}            />
          </Box>
          <Button mt="380px" bg="black" color="white" width="full" onClick={handleVerificationCodeSubmit} isLoading={isLoading}>
            Continue
          </Button>
        </FormControl>
        <Text>
          Don't have an account? <Link to="/register" style={{ color: 'green' }}>Register Now</Link>
        </Text>
      </VStack>
    </Box>
  );
};

export default EnterVerificationCode;