import React, { createContext, useState, useEffect, useContext, ReactNode } from "react";
import { doc, getDoc, collection } from "firebase/firestore";
import { firestore } from "../firebase";
import { CollabDataContext } from "./collabDataContext";
import { Campaign, FoodfluenceLocation } from "../types";

interface CampaignDataContextType {
    [collabId: string]: Campaign
}

export const InfluencerCampaignDataContext = createContext<CampaignDataContextType>({});

interface InfluencerCampaignDataProviderProps {
    children: ReactNode;
}

export const InfluencerCampaignDataProvider: React.FC<InfluencerCampaignDataProviderProps> = ({ children }) => {
    const collabData = useContext(CollabDataContext);
    const [campaignData, setCampaignData] = useState<CampaignDataContextType>({});

    useEffect(() => {
        const fetchCampaignData = async () => {
            const campaignPromises = collabData.map(async (collab) => {
                if (collab.influencerCampaignID) {
                    const campaignDocRef = doc(firestore, "influencerCampaigns", collab.influencerCampaignID);
                    const campaignDocSnap = await getDoc(campaignDocRef);
                    if (campaignDocSnap.exists()) {
                        const campaignData = campaignDocSnap.data() as Campaign;

                        let locations: FoodfluenceLocation[] = [];
                        if (campaignData.foodfluenceLocations && campaignData.foodfluenceLocations.length > 0) {
                            const locationsRef = collection(firestore, "foodfluenceLocations");
                            const locationPromises = campaignData.foodfluenceLocations.map(locationId => 
                                getDoc(doc(locationsRef, locationId))
                            );
                            const locationDocs = await Promise.all(locationPromises);
                            locations = locationDocs
                                .filter(doc => doc.exists())
                                .map(doc => ({
                                    docId: doc.id,
                                    ...doc.data()
                                } as FoodfluenceLocation));
                        }
                        return {
                            [collab.id]: {
                                ...campaignData,
                                locations
                            }
                        };
                    }
                }
                return null;
            });

            const campaignResults = await Promise.all(campaignPromises);
            const campaigns = campaignResults.reduce((acc, result) => {
                if (result) {
                    return { ...acc, ...result };
                }
                return acc;
            }, {});

            setCampaignData(campaigns);
        };

        fetchCampaignData();
    }, [collabData]);

    return (
        <InfluencerCampaignDataContext.Provider value={campaignData}>
            {children}
        </InfluencerCampaignDataContext.Provider>
    );
};