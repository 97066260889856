import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Text, Flex, Button, Menu, MenuButton, MenuList, MenuItem, IconButton, Tooltip } from "@chakra-ui/react";
import { ArrowBackIcon, AttachmentIcon, CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { sendMessage, sendFileMessage, updateMessageReadTime } from "services/firebaseService";
import { Message, Influencer } from "types";
import { useLocation, useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { ConversationsContext } from "contexts/ConversationsDataContext";
import { InfluencerDataContext } from "contexts/InfluencerDataContext";
import { FiMoreVertical } from "react-icons/fi";
import CreativeBriefModal from "features/collabs/components/CreativeBriefModal";
import { messageSentToClientEmail } from "services/flaskService";
import { formatMessageTimestamp, getLatestReadMessageId } from "features/inbox/utils";

const MessagesPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const conversationId = location.state.conversationId;
  const conversations = useContext(ConversationsContext);
  const campaign = location.state.campaign;
  const collab = location.state.collab;
  const client = location.state.client;
  const {influencerData} = useContext(InfluencerDataContext) as {influencerData: Influencer | null};
  const influencerHandle = influencerData?.instagramHandle;

  const [message, setMessage] = useState('');
  const [isCollabDetailsModalOpen, setIsCollabDetailsModalOpen] = useState(false);
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const [latestReadMessageId, setLatestReadMessageId] = useState<string | null>(null);

  let messages: Record<string, Message> = conversations[conversationId]?.messages_log || {};

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const latestReadMessageId = getLatestReadMessageId(conversations[conversationId]?.messages_log);
    setLatestReadMessageId(latestReadMessageId);
  }, [messages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (messages) {
      Object.entries(messages).forEach(([key, message]) => {
        if (message.sender !== "influencer" && !message.time_read) {
          updateMessageReadTime(conversationId, key);
        }
      });
    }
  }, [Object.keys(messages).length]);

  const handleViewCollab = () => {
    setIsCollabDetailsModalOpen(true);
  };

  const handleReportUser = () => {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      console.error("Intercom is not initialized");
    }
  };

  const resetMessageInput = () => {
    setAttachedFile(null);
    setMessage('');
  };

  const handleSendMessage = async () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage === '') {
      return; 
    }
    if (attachedFile) {
      try {
        await sendFileMessage(conversationId, attachedFile);
        messageSentToClientEmail(campaign?.clientID, client?.email, client?.accountName, influencerHandle, message);
        resetMessageInput();
      } catch (error) {
        console.error("Error sending file:", error);
      }
    } else {
      try {
        await sendMessage(conversationId, message);
        messageSentToClientEmail(campaign?.clientID, client?.email, client?.accountName, influencerHandle, message);
        resetMessageInput();
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <>
      <style>
        {`
          .message-container {
            overflow-y: auto;
            flex: 1;
            padding: 10px;
            -webkit-overflow-scrolling: touch;
          }
          .header, .input-area {
            flex-shrink: 0;
          }
          .header {
            border-bottom: 2px solid #e2e8f0;
            padding: 15px 10px;
          }
          .input-area {
            border-top: 1px solid #e2e8f0;
            padding: 10px;
            margin-bottom: 0;
            padding-bottom: calc(env(safe-area-inset-bottom) + 5px);
          }
        `}
      </style>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        height="100svh"
        display="flex"
        flexDirection="column"
      >
        <Flex className="header" justify="space-between" align="center" mb={2}>
          <Flex align="center">
            <Button onClick={() => navigate(-1)} variant="ghost" leftIcon={<ArrowBackIcon />} />
            <Text fontSize="2xl" fontWeight="bold" ml={2}>
              {client?.accountName}
            </Text>
          </Flex>
          
          <Menu placement="left">
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<FiMoreVertical />}
              variant="ghost"
            />
            <MenuList minWidth="50%">
              <MenuItem onClick={handleViewCollab}>
                View Collab Details
              </MenuItem>
              <MenuItem onClick={handleReportUser}>
                Report User
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <Box className="message-container">
          {messages && Object.keys(messages).length > 0 ? (
            Object.entries(messages)
              .sort(([, a], [, b]) => {
                const timestampA = new Timestamp(a?.time_sent?.seconds, a?.time_sent?.nanoseconds);
                const timestampB = new Timestamp(b?.time_sent?.seconds, b?.time_sent?.nanoseconds);
                return timestampA.toDate().getTime() - timestampB.toDate().getTime();
              })
              .map(([key, message]: [string, Message]) => {
                const isSender = message.sender === "influencer";
                const { formattedString } = formatMessageTimestamp(message, isSender, key === latestReadMessageId);

                return (
                  <Flex key={key} mb="10px" justify={isSender ? "flex-end" : "flex-start"} width="100%">
                    <Box
                      maxWidth="70%"
                      textAlign="left"
                      boxSizing="border-box"
                      display="flex"
                      flexDirection="column"
                      alignItems={isSender ? "flex-end" : "flex-start"}
                    >
                      {message.type === 'message' ? (
                        <Box
                          p="8px"
                          borderRadius="md"
                          bg={isSender ? "blue.100" : "gray.200"}
                          whiteSpace="normal"
                          width="fit-content"
                          justifySelf={isSender ? "flex-end" : "flex-start"}
                          wordBreak="break-word"
                          overflowWrap="break-word"
                          sx={{ hyphens: "auto" }}
                        >
                          <Text>{message.body}</Text>
                        </Box>
                      ) : message.type === 'file' ? (
                        <Box
                          p="8px"
                          borderRadius="md"
                          bg={isSender ? "blue.100" : "gray.200"}
                          display="flex"
                          alignItems="center"
                          maxWidth="100%"
                          whiteSpace="normal"
                          wordBreak="break-word"
                          justifySelf={isSender ? "flex-end" : "flex-start"}
                          sx={{ hyphens: "auto" }}
                        >
                          <AttachmentIcon boxSize={4} mr={3} />
                          <a href={message.file_url} target="_blank" rel="noopener noreferrer">
                            <Text as="span" color="blue.500" textDecoration="underline">
                              {message.body}
                            </Text>
                          </a>
                        </Box>
                      ) : null}
                      <Box mt="0.5" textAlign={isSender ? "right" : "left"}>
                        <Text fontSize="xs" color="gray.500">
                          {isSender && message?.time_read && key === latestReadMessageId && (
                            <>
                              <Tooltip label="Latest Read Message" aria-label="Latest Read Message Tooltip" bg="white" color="black">
                                <CheckIcon color="green.500" boxSize={3} mr={1} />
                              </Tooltip>
                              {"Read:"}
                            </>
                          )}
                          {` ${formattedString}`}
                        </Text>
                      </Box>
                    </Box>
                  </Flex>
                );
              })
          ) : null}
          <div ref={messagesEndRef} />
        </Box>
        <Box className="input-area">
          <Flex as="form" onSubmit={async (e) => {
            e.preventDefault();
            await handleSendMessage();
          }} align="center">
            <input
              type="file"
              accept=".pdf, .jpeg, .jpg, .png"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  setAttachedFile(file);
                  setMessage(file.name); 
                }
              }}
              style={{ display: 'none' }}
              id="pdf-upload"
            />
            <label htmlFor="pdf-upload" style={{ cursor: 'pointer', marginRight: '10px' }}>
              <Button as="span" variant="outline" colorScheme="gray" size="sm">
                <AttachmentIcon boxSize={6} mr={2} />
              </Button>
            </label>
            <Flex align="center" flex={1}>
              <input
                type="text"
                id="message-input"
                name="message"
                placeholder="Type message"
                value={message}
                onChange={(e) => {
                  if (!attachedFile) {
                    setMessage(e.target.value);
                  }
                }}
                style={{
                  flex: 1,
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #e2e8f0',
                  marginRight: '10px',
                  width: attachedFile ? 'calc(100% - 10px)' : '100%'
                }}
                onKeyDown={async (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    await handleSendMessage();
                  }
                }}
                disabled={!!attachedFile}
              />
              {attachedFile && (
                <Button
                  variant="ghost"
                  colorScheme="red"
                  onClick={resetMessageInput}
                  m={1}
                >
                  <CloseIcon boxSize={3} />
                </Button>
              )}
            </Flex>
            <Button type="submit" colorScheme="gray" disabled={!attachedFile && !message} minWidth="80px">Send</Button>
          </Flex>
        </Box>
        <CreativeBriefModal
          isOpen={isCollabDetailsModalOpen}
          onClose={() => setIsCollabDetailsModalOpen(false)}
          campaign={campaign}
          collab={collab}
        />
      </Box>
    </>
  );
};

export default MessagesPage;
