import { Campaign, Collab } from '../../../types';

/**
 * Checks if a campaign has ended based on its end date and collab status
 * @param campaign The campaign to check
 * @param collab The collab to check
 * @returns True if the campaign has ended, false otherwise
 */
export const isCampaignEnded = (campaign: Campaign, collab: Collab): boolean => {
  if (!campaign?.endDate || campaign.endDate.toDate() >= new Date()) {
      return false;
  }

  if (collab.status === "pendingInfluencerApproval") {
      return true;
  }

  if (collab.status === "pendingRedemption") {
      if (campaign.campaignType === "shipToHome") {
          return false;
      }
      return !collab.scheduledCollabDate;
  }

  return false;
};

export const getCurrentCollabs = (collabData: Collab[], campaignData: Record<string, Campaign>): Collab[] => {
    return collabData
      .filter((collab: Collab) => {
        const campaign = campaignData[collab.id];
        return ['pendingClientApproval','pendingInfluencerApproval', 'influencerCountered', 'pendingSubmission', 'pendingResubmission', 'pendingVerification',
          'pendingRedemption', 'collabRedeemed'].includes(collab.status) && !isCampaignEnded(campaign, collab);
      })
      .sort((a: Collab, b: Collab) => {
        const dateA = a.collabReceivedTime?.toDate() || a.collabRequestedTime?.toDate();
        const dateB = b.collabReceivedTime?.toDate() || b.collabRequestedTime?.toDate();
        return dateA && dateB ? dateB.getTime() - dateA.getTime() : 0;
      });
  };
  
  export const getPastCollabs = (collabData: Collab[], campaignData: Record<string, Campaign>): Collab[] => {
    return collabData
      .filter((collab: Collab) => {
        const campaign = campaignData[collab.id];
        return ['collabRejected', 'collabCompleted', 'collabExpired', 'collabCanceled', 'clientRejected'].includes(collab.status) || isCampaignEnded(campaign, collab);
      })
      .sort((a: Collab, b: Collab) => {
        const dateA = a.collabReceivedTime?.toDate() || a.collabRequestedTime?.toDate();
        const dateB = b.collabReceivedTime?.toDate() || b.collabRequestedTime?.toDate();
        return dateA && dateB ? dateB.getTime() - dateA.getTime() : 0;
      });
  };