import { Timestamp } from "firebase/firestore";
import { Collab, Campaign, Message } from "types";
import { getCurrentCollabs, getPastCollabs } from "../collabs/utils/collabUtils";

export const getLatestMessage = (messagesLog: Record<string, Message>): Message | null => {
    if (!messagesLog || Object.keys(messagesLog).length === 0) return null;
    const latestMessage = Object.values(messagesLog).sort((a, b) => 
      new Date(b?.time_sent?.toDate()).getTime() - new Date(a?.time_sent?.toDate()).getTime())[0];
    return latestMessage;
};

const getUnreadConversations = (conversations: Record<string, any>): string[] => {
  return Object.keys(conversations).filter(conversationId => {
    const conversation = conversations[conversationId];
    return getUnreadCount(conversation?.messages_log) > 0;
  });
};

export const searchForMessagesWithoutSearchTerm = (
    conversations: Record<string, any>,
    setting: string,
    collabData: Collab[],
    influencerCampaignData: Record<string, Campaign>
  ) => {
    const newFilteredConversations: { conversationId: string, message: Message }[] = [];
    Object.keys(conversations).forEach(conversationId => {
      const conversation = conversations[conversationId];
      const messagesLog = conversation?.messages_log;
      const latestMessage = getLatestMessage(messagesLog);
      if (setting === "current") {
        const currentCollabs = getCurrentCollabs(collabData, influencerCampaignData);
        const currentCollabIds = currentCollabs.map(collab => collab.id);
        if (currentCollabIds.includes(conversationId)) {
          newFilteredConversations.push({ conversationId, message: latestMessage });
        }
      } else if (setting === "previous") {
        const pastCollabs = getPastCollabs(collabData, influencerCampaignData);
        const pastCollabIds = pastCollabs.map(collab => collab.id);
        if (pastCollabIds.includes(conversationId)) {
          newFilteredConversations.push({ conversationId, message: latestMessage });
        }
      } else if (setting === "unread") {  
        if (getUnreadConversations(conversations).includes(conversationId)) {
          newFilteredConversations.push({ conversationId, message: latestMessage });
        }
      }
    });
    return newFilteredConversations.sort((a, b) => {
      const timeA = a.message?.time_sent?.seconds || conversations[a.conversationId]?.time_created?.seconds || 0;
      const timeB = b.message?.time_sent?.seconds || conversations[b.conversationId]?.time_created?.seconds || 0;
      return timeB - timeA; // Sort by most recent time
    });
  };

export const searchForMessagesWithSearchTerm = (
  conversations: Record<string, any>,
  searchTerm: string,
  setting: string,
  addedMessages: Set<string>,
  collabData: Collab[],
  influencerCampaignData: Record<string, Campaign>
) => {
  const newFilteredConversations: { conversationId: string, message: Message }[] = [];
  Object.keys(conversations).forEach(conversationId => {
    const conversation = conversations[conversationId];
    const messagesLog = conversation?.messages_log;
    Object.values(messagesLog || {}).forEach(message => {
      const messageIdentifier = `${conversationId}-${(message as Message)?.body}-${(message as Message)?.time_sent?.seconds}`;
      if ((message as Message)?.body?.toLowerCase().includes(searchTerm.toLowerCase())) {
        if (!addedMessages.has(messageIdentifier) && // Check if already added
            (setting === "current" && getCurrentCollabs(collabData, influencerCampaignData).map(collab => collab.id).includes(conversationId)) ||
            (setting === "previous" && getPastCollabs(collabData, influencerCampaignData).map(collab => collab.id).includes(conversationId)) ||
            (setting === "unread" && getUnreadConversations(conversations).includes(conversationId))
          ) {
          newFilteredConversations.push({ conversationId, message: message as Message });
          addedMessages.add(messageIdentifier); // Mark as added
        }
      }
    });
  });

  return newFilteredConversations;
};

export function formatConversationDate(message: Message | null, conversation: any): string {
  const dateToCheck = message?.time_sent?.toDate() || conversation?.time_created?.toDate();

  if (!dateToCheck) return "";

  const now = new Date();
  const isToday = dateToCheck.toDateString() === now.toDateString();
  const isYesterday = dateToCheck.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();
  const isThisWeek = dateToCheck > new Date(now.setDate(now.getDate() - 6));

  if (isToday) {
    return dateToCheck.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
  } else if (isYesterday) {
    return "Yesterday";
  } else if (isThisWeek) {
    return dateToCheck.toLocaleDateString(undefined, { weekday: 'long' });
  } else {
    return dateToCheck.toLocaleDateString();
  }
}

export const formatMessageTimestamp = (message: any, isSender: boolean, isLatestMessage: boolean): { formattedString: string } => {
  const timestamp = new Timestamp(
    ((isSender && isLatestMessage && message?.time_read?.seconds) || message?.time_sent?.seconds),
    ((isSender && isLatestMessage && message?.time_read?.nanoseconds) || message?.time_sent?.nanoseconds)
  );
  const dateToCheck = timestamp.toDate();

  const now = new Date();
  const isToday = dateToCheck.toDateString() === now.toDateString();
  const isYesterday = dateToCheck.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();
  const isThisWeek = dateToCheck > new Date(now.setDate(now.getDate() - 6));

  let dateString;
  if (isToday) {
    dateString = "Today";
  } else if (isYesterday) {
    dateString = "Yesterday";
  } else if (isThisWeek) {
    dateString = dateToCheck.toLocaleDateString(undefined, { weekday: 'long' });
  } else {
    dateString = dateToCheck.toLocaleDateString();
  }
  const timeString = dateToCheck.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
  const formattedString = `${dateString}, ${timeString}`;
  return { formattedString };
}; 

export const getUnreadCount = (messagesLog: Record<string, Message>): number => {
  return Object.values(messagesLog).filter(message => 
    !message.time_read && message?.sender !== 'influencer'
  ).length;
};

export const getLatestReadMessageId = (messagesLog: Record<string, Message>): string | null => {
  if (messagesLog == null) {
    return null;
  }

  const entries = Object.entries(messagesLog)
    .filter(([_, message]) => message?.time_read && message?.sender === 'influencer')
    .sort(([, a], [, b]) => b?.time_sent?.seconds - a?.time_sent?.seconds);

  if (entries.length === 0) return null;

  const [key, message] = entries[0];
  return key;
};