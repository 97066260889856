import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, Avatar, Text, Badge } from "@chakra-ui/react";
import { ConversationsContext } from "contexts/ConversationsDataContext";
import { CollabDataContext } from 'contexts/collabDataContext';
import { InfluencerCampaignDataContext } from 'contexts/InfluencerCampaignDataContext';
import { ClientDataContext } from 'contexts/ClientDataContext';
import { Message, Client } from "types";
import { searchForMessagesWithSearchTerm, searchForMessagesWithoutSearchTerm, formatConversationDate, getUnreadCount } from '../utils';

interface ConversationsListProps {
  onConversationClick: (conversationId: string, client: Client) =>  void;
  setting: string;
  searchTerm: string;
}

const ConversationsList: React.FC<ConversationsListProps> = ({ onConversationClick, setting, searchTerm }) => {
  const conversations = useContext(ConversationsContext);
  const collabsData = useContext(CollabDataContext)
  const clientData = useContext(ClientDataContext);
  const influencerCampaignData = useContext(InfluencerCampaignDataContext);
  const [filteredConversations, setFilteredConversations] = useState<{ conversationId: string, message: Message }[]>([]);

  useEffect(() => {
    let newFilteredConversations: { conversationId: string, message: Message }[] = [];
    const addedMessages = new Set<string>(); // Track added messages by unique identifier

    if (!searchTerm || /^\s*$/.test(searchTerm)) {
      newFilteredConversations = searchForMessagesWithoutSearchTerm(conversations, setting, collabsData, influencerCampaignData);
    } else {
      newFilteredConversations = searchForMessagesWithSearchTerm(conversations, searchTerm, setting, addedMessages, collabsData, influencerCampaignData);
    }
    setFilteredConversations(newFilteredConversations);
  }, [conversations, setting, searchTerm]);

  return (
    <>
      <Box mb="20px">
        {filteredConversations.length === 0 ? (
          <Text>No conversations</Text>
        ) : (
          filteredConversations.map(({ conversationId, message }) => {
            const collab = collabsData.find(collab => collab.id === conversationId);
            const campaign = influencerCampaignData[collab.id];
            const client = clientData[campaign?.clientID];
            const unreadCount = getUnreadCount(conversations[conversationId]?.messages_log);

            return (
              <Flex 
                key={`${conversationId}-${message?.time_sent?.seconds}`} 
                align="center" 
                mb="10px" 
                p="10px" 
                borderWidth="1px" 
                borderRadius="md" 
                width="100%"
                onClick={() => onConversationClick(conversationId, client)}
                cursor="pointer"
              >
                <Avatar src={client?.instagramProfilePicture} mr="10px" />
                <Box flex="1" overflow="hidden">
                  <Flex justify="space-between" align="center">
                    <Text fontWeight="bold">{client?.accountName}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {formatConversationDate(message, conversations[conversationId])}
                    </Text>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <Box 
                      color="gray.500" 
                      fontStyle={message === null ? "italic" : "normal"}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'block',
                      }}
                    >
                      {message?.body || "No messages yet"}
                    </Box>
                    {unreadCount > 0 && (
                      <Badge colorScheme="gray" borderRadius="full" px="2" ml="2">
                        {unreadCount}
                      </Badge>
                    )}
                  </Flex>
                </Box>
              </Flex>
            );
          })
        )}
      </Box>
    </>
  );
};

export default ConversationsList;