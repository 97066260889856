import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Flex, FormControl, Input, Link, Checkbox, Text, VStack, useToast, Select } from '@chakra-ui/react';
import { checkInfluencerEmail, sendEmailVerificationCode } from '../../services/firebaseService';
import { countryOptions } from 'types';
import { validateEmail, validatePhoneNumber } from 'services/verificationService';
import { formatPhoneNumber } from 'utils/authUtils';
const SignUpPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [countryCode, setCountryCode] = useState<string>('+1');
  const [isIntercomOpen, setIsIntercomOpen] = useState(false);
  const [confirmPhoneNumber, setConfirmPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  useEffect(() => {
    if (typeof window.Intercom !== 'undefined') {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "tkgj02b2",
        hide_default_launcher: true
      });
    }
  }, []);

  const handleSubmit = async () => {
    if (!agreeToTerms) {
      toast({
        title: "Error",
        description: "Please agree to the terms of service to continue.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!firstName || !lastName) {
      toast({
        title: "Error",
        description: "Please enter your first and last names.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!validateEmail(email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!await checkInfluencerEmail(email)) {
      toast({
        title: "Error",
        description: "Please sign in with the email where you received your invite.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const fullPhoneNumber = formatPhoneNumber(phoneNumber, countryCode);
    const fullConfirmPhoneNumber = formatPhoneNumber(confirmPhoneNumber, countryCode);

    if (fullPhoneNumber !== fullConfirmPhoneNumber) {
      setPhoneError("Phone numbers don't match");
      return;
    }

    if (!validatePhoneNumber(fullPhoneNumber)) {
      setPhoneError("Please enter a valid phone number");
      return;
    }

    setPhoneError('');

    try {
      const isSuccess = await sendEmailVerificationCode(email, fullPhoneNumber, firstName, lastName);
      if (isSuccess) {
        navigate('/email-sent');
      } else {
        toast({
          title: "Error",
          description: "Sign up failed. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Sign up error:', error);
      toast({
        title: "Error",
        description: "An error occurred during sign up. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const toggleIntercom = () => {
    if (isIntercomOpen) {
      window.Intercom('hide');
    } else {
      window.Intercom('show');
    }
    setIsIntercomOpen(!isIntercomOpen);
  };

  return (
    <Box height="100svh" display="flex" flexDirection="column" bg="white" p="4" position="relative">
      <Button 
        onClick={() => navigate('/login')} 
        position="absolute"
        top="16px"
        left="16px"
        variant="ghost"
      >
        &larr;
      </Button>
      
      <VStack 
        spacing="6" 
        as="form" 
        onSubmit={e => { e.preventDefault(); handleSubmit(); }} 
        width="100%" 
        maxW="400px"
        mx="auto"
        flex="1"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        py="8"
      >
        {/* Top section */}
        <VStack spacing="6" width="100%">
          <Text fontSize="2xl" fontWeight="bold">We're excited to have you!</Text>
          <FormControl isRequired>
            <Input id="first-name" type="text" bg="#F7F8F9" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First Name" />
          </FormControl>
          <FormControl isRequired>
            <Input id="last-name" type="text" bg="#F7F8F9" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last Name" />
          </FormControl>
          <FormControl isRequired>
            <Input id="email" type="email" bg="#F7F8F9" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
          </FormControl>
          <FormControl isRequired>
            <Flex>
              <Select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                width="31%"
                bg="#F7F8F9"
              >
                {countryOptions.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.code}
                  </option>
                ))}
              </Select>
              <Input
                type="tel"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                bg="#F7F8F9"
              />
            </Flex>
          </FormControl>
          <FormControl isRequired>
            <Input
              type="tel"
              placeholder="Confirm Phone Number"
              value={confirmPhoneNumber}
              onChange={(e) => setConfirmPhoneNumber(e.target.value)}
              bg="#F7F8F9"
            />
          </FormControl>
          {phoneError && <Text color="red.500">{phoneError}</Text>}
          <Flex alignItems="center">
            <Checkbox id="agree-terms" isChecked={agreeToTerms} onChange={e => setAgreeToTerms(e.target.checked)} />
            <Text ml="2">
              I agree to the <Link href="https://www.foodfluence.io/tos" target="_blank" rel="noopener noreferrer" textDecoration="underline">terms of service</Link>.
            </Text>
          </Flex>
          <Text>
            Having trouble signing up? <Link color="green" onClick={toggleIntercom}>Get help</Link>
          </Text>
        </VStack>

        {/* Bottom section */}
        <VStack spacing="4" width="100%">
          <Button type="submit" bg="black" color="white" width="full" py="6">Register</Button>
          <Text>
            Already have an account? <Link color="green" href="/returning-user">Login Now</Link>
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default SignUpPage;
