import { Client } from '@foodfluence/shared';

export const getInternalClients = (clientsData: Record<string, { client: Client }>) => {
  return Object.entries(clientsData).reduce((acc, [id, clientData]) => {
    // Internal clients are those with adminID or not externally added
    if (isInternalClient(clientData.client)) {
      acc.push(id);
    }
    return acc;
  }, [] as string[]);
}; 

export const isInternalClient = (client: Client) => {
  return client.adminID || !client.externallyAdded;
};