import React from 'react';
import { Box, Input } from '@chakra-ui/react';
import { Campaign } from '../../../../types';
import { getFilteredLocations, shouldShowLocationSearch } from '../utils/locationUtils';

interface LocationSearchBoxProps {
  campaign: Campaign;
  locationSearchQuery: string;
  isLocationDropdownOpen: boolean;
  onLocationSearch: (query: string) => void;
  onLocationSelect: (location: { docId: string; fullAddress: string }) => void;
  onDropdownToggle: (isOpen: boolean) => void;
}

const LocationSearchBox: React.FC<LocationSearchBoxProps> = ({
  campaign,
  locationSearchQuery,
  isLocationDropdownOpen,
  onLocationSearch,
  onLocationSelect,
  onDropdownToggle,
}) => {
  if (!shouldShowLocationSearch(campaign)) {
    return null;
  }

  const filteredLocations = getFilteredLocations(campaign.locations, locationSearchQuery);
  return (
    <Box position="relative">
      <Input
        placeholder="Search location"
        value={locationSearchQuery}
        onChange={(e) => {
          onLocationSearch(e.target.value);
          onDropdownToggle(true);
        }}
        onClick={() => onDropdownToggle(true)}
      />
      <Box
        position="absolute"
        top="100%"
        left={0}
        right={0}
        bg="white"
        boxShadow={isLocationDropdownOpen ? "md" : "none"}
        borderRadius="md"
        maxH="200px"
        overflowY="auto"
        zIndex={1}
        display={isLocationDropdownOpen ? "block" : "none"}
      >
        {filteredLocations.map((location) => (
          <Box
            key={location.docId}
            p={2}
            cursor="pointer"
            _hover={{ bg: "gray.100" }}
            onClick={() => {
              onLocationSelect({
                docId: location.docId,
                fullAddress: location.fullAddress
              });
              onDropdownToggle(false);
            }}
          >
            {location.fullAddress}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LocationSearchBox; 