import React, { useContext, useState } from 'react';
import {
  Box,
  Text,
  Input,
  VStack,
  HStack,
  Button,
  IconButton,
  Flex,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { InfluencerDataContext } from 'contexts/InfluencerDataContext';
import { Bundle } from 'types';
import { cashCap, handleIncrement, handleDecrement } from '../../utils';

const AddBundleModal: React.FC<{ isOpen: boolean, onClose: () => void, onBundleCreate: (bundle: Bundle) => void }> = ({ isOpen, onClose, onBundleCreate }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { influencerData } = useContext(InfluencerDataContext);
  const [tiktokCount, setTiktokCount] = useState(0);
  const [igReelCount, setIgReelCount] = useState(0);
  const [igPostCount, setIgPostCount] = useState(0);
  const [igStoryCount, setIgStoryCount] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async () => {
    try {
      const bundle: Bundle = {
        deliverableCounts: {
          tiktoks: tiktokCount, 
          instagramReels: igReelCount,
          instagramPosts: igPostCount,
          instagramStories: igStoryCount,
        },
        cash: Number(cashAmount)
      };
      onBundleCreate(bundle);
      toast({
        title: "Bundle added",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Error adding bundle:', error);
      toast({
        title: "Error adding bundle",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const isSubmitEnabled = (tiktokCount > 0 || igReelCount > 0 || igPostCount > 0 || igStoryCount > 0) && (cashAmount > 0) && cashAmount <= cashCap;

  const handleCashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      setCashAmount(value);
      if (value > cashCap) {
        setErrorMessage(`Cash amount cannot exceed ${cashCap.toLocaleString()}.`);
      } else {
        setErrorMessage(null);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <IconButton
              icon={<ArrowBackIcon />}
              size="lg"
              aria-label="Go Back"
              variant="ghost"
              borderRadius="full"
              onClick={onClose}
            />
            <Text fontWeight="bold" fontSize="2xl" position="absolute" left="50%" transform="translateX(-50%)">
              Add A Bundle
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {/* Deliverables Section */}
            <Text fontSize="xl" fontWeight="bold">Deliverables</Text>
            <Box borderWidth="1px" borderRadius="md" p={4}>
              <VStack spacing={3} align="stretch">
                {[
                  { label: 'TikTok', count: tiktokCount, setCount: setTiktokCount },
                  { label: 'IG Reel', count: igReelCount, setCount: setIgReelCount },
                  { label: 'IG Post', count: igPostCount, setCount: setIgPostCount },
                  { label: 'IG Story', count: igStoryCount, setCount: setIgStoryCount },
                ].map(({ label, count, setCount }) => (
                  <HStack key={label} justify="space-between">
                    <Text fontSize="md" fontWeight="medium">{label}</Text>
                    <HStack>
                      <IconButton
                        icon={<FaMinus style={{ color: 'red' }} />}
                        aria-label={`Decrease ${label}`}
                        onClick={() => handleDecrement(setCount, count)}
                        size="sm"
                        variant="ghost"
                        isDisabled={count === 0}
                      />
                      <Text>{count}</Text>
                      <IconButton
                        icon={<FaPlus style={{ color: 'green' }} />}
                        aria-label={`Increase ${label}`}
                        onClick={() => handleIncrement(setCount, count)}
                        variant="ghost"
                        size="sm"
                      />
                    </HStack>
                  </HStack>
                ))}
              </VStack>
            </Box>

            {/* Compensation Section */}
            <Text fontSize="xl" fontWeight="bold">Compensation</Text>

            <Text fontWeight="bold" fontSize="18px" display="inline">
              Cash 
            </Text>
            <Input
              value={cashAmount}
              onChange={handleCashChange}
            />

            {/* Error Message */}
            {errorMessage && (
              <Text color="red.500" fontSize="sm" textAlign="center">
                {errorMessage}
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={isSubmitEnabled ? 'blackAlpha' : 'gray'}
            onClick={handleSubmit}
            bg={isSubmitEnabled ? 'black' : 'gray.300'}
            color={isSubmitEnabled ? 'white' : 'gray.500'}
            _hover={{ bg: isSubmitEnabled ? 'blackAlpha.800' : 'gray.300' }}
            width="100%"
            fontWeight="bold"
            isDisabled={!isSubmitEnabled}
            alignSelf="flex-end"
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddBundleModal;
