import React from 'react';
import {
  Box,
  VStack,
  Text,
  Heading,
} from '@chakra-ui/react';
import { FoodfluenceLocation } from '../types';

interface LocationListProps {
  locations: FoodfluenceLocation[];
  title?: string;
  maxHeight?: string;
}

const LocationList: React.FC<LocationListProps> = ({
  locations,
  title = "Locations",
  maxHeight = "300px"
}) => {
  if (!locations || locations.length === 0) {
    return (
      <Box>
        <Text color="gray.500">No locations available</Text>
      </Box>
    );
  }

  const sortedLocations = [...locations].sort((a, b) => {
    // Handle null/undefined states
    const stateA = a.state || '';
    const stateB = b.state || '';
    const cityA = a.city || '';
    const cityB = b.city || '';

    const stateComparison = stateA.localeCompare(stateB);
    if (stateComparison === 0) {
      return cityA.localeCompare(cityB);
    }
    return stateComparison;
  });

  return (
    <Box>
      <Heading size="md" mb={3}>{title}</Heading>
      <VStack 
        align="stretch" 
        spacing={2}
        borderWidth={1}
        borderColor="gray.300"
        borderRadius="md"
        p={3}
        maxHeight={maxHeight}
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray.200',
            borderRadius: '24px',
          },
        }}
      >
        {sortedLocations.map((location) => (
          <Box 
            key={location.docId}
            p={2}
            _hover={{ bg: "gray.50" }}
            borderRadius="md"
          >
            <Text fontSize="md">
              {`${location.streetNumber} ${location.streetName}`}
            </Text>
            <Text fontSize="sm" color="gray.600">
              {`${location.city}, ${location.state} ${location.zip}`}
            </Text>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default LocationList;