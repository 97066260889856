import React from 'react';
import { Box, Text, Image, VStack, Flex, Icon } from '@chakra-ui/react';
import { campaignTypeDisplayNames } from '../../../types';
import { Client } from '@foodfluence/shared';
import { useRandomImage } from '../../../utils/imageUtils';
import { useNavigate } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { getLocationDisplay } from 'utils/locationUtils';

interface ClientComponentProps {
  client: Client;
  clientId: string;
  locationFilter?: { lat: number; lng: number };
}

const ClientComponent: React.FC<ClientComponentProps> = ({ client, clientId, locationFilter }) => {
  const navigate = useNavigate();
  const { getRandomImage, randomImage } = useRandomImage();

  return (
    <Box 
      as="span" 
      display="inline-block" 
      mr={3}
      onClick={() => navigate(`/explore/client/${clientId}`)}
      cursor="pointer"
      transition="all 0.2s"
      _hover={{ transform: 'translateY(-4px)' }}
    >
      <Box
        borderRadius="2xl"
        overflow="hidden"
        boxShadow="lg"
        width="220px"
        height="240px"
        position="relative"
        _hover={{ 
          boxShadow: "2xl",
        }}
      >
        {/* Full-size background image */}
        <Image
          src={client.bannerPicture}
          alt={client.accountName}
          height="100%"
          width="100%"
          objectFit="cover"
          fallbackSrc={getRandomImage()}
          onError={(e) => {
            if (!randomImage) {
              e.currentTarget.src = getRandomImage();
            }
          }}
        />

        {/* Gradient overlay */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.8))"
        />

        {/* Content overlay */}
        <VStack
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          p={4}
          align="stretch"
          spacing={2}
          color="white"
        >
          <Text 
            fontSize="lg" 
            fontWeight="bold" 
            noOfLines={2}
            textShadow="0px 2px 4px rgba(0,0,0,0.3)"
            whiteSpace="normal"
            wordBreak="break-word"
            width="100%"
          >
            {client.accountName}
          </Text>
          
          <Text 
            fontSize="sm"
            noOfLines={1}
            opacity={0.9}
          >
            {(client.campaignTypes || []).map((type) => campaignTypeDisplayNames[type]).join(', ')}
          </Text>
          
          <Flex align="center" gap={1}>
            {client.foodfluenceLocations && client.foodfluenceLocations.length > 0 ? (
              <>
                <Icon as={FaMapMarkerAlt} boxSize={3} />
                <Text fontSize="xs" noOfLines={1} opacity={0.8}>
                  {getLocationDisplay(client.foodfluenceLocations, locationFilter)}
                </Text>
              </>
            ) : (
              <Text fontSize="xs" noOfLines={1} opacity={0.8}>
                &nbsp;
              </Text>
            )}
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
};

export default ClientComponent; 