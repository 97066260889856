export const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 3959; // Earth's radius in miles
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
}; 

export const getLocationDisplay = (
  locations: any[] | undefined,
  referenceLocation?: { lat: number; lng: number }
): string => {
  if (!locations || locations.length === 0) return '';

  let sortedLocations = [...locations];
  
  if (referenceLocation) {
    sortedLocations.sort((a, b) => {
      const distanceA = calculateDistance(
        referenceLocation.lat,
        referenceLocation.lng,
        a.lat,
        a.lng
      );
      const distanceB = calculateDistance(
        referenceLocation.lat,
        referenceLocation.lng,
        b.lat,
        b.lng
      );
      return distanceA - distanceB;
    });
  }
  
  const firstLocation = sortedLocations[0];
  const remainingCount = sortedLocations.length - 1;
  
  const locationText = `${firstLocation.streetNumber} ${firstLocation.streetName}, ${firstLocation.city}`;
  
  if (remainingCount > 0) {
    return `${locationText} + ${remainingCount} other${remainingCount === 1 ? '' : 's'}`;
  }
  
  return locationText;
};