export const validateEmail = (email: string): boolean => {
    return email.includes('@');
  };

export const validatePhoneNumber = (phone: string): boolean => {
    const sanitizedPhone = phone.replace(/[^\d+]/g, '');
    return sanitizedPhone.length >= 10 && sanitizedPhone.length <= 14;
  };

export const generateInstagramCode = (): string => {
  return Math.random().toString(36).substring(2, 8).toUpperCase();
};

export const validateInstagram = (handle: string): boolean => {
  // Remove @ symbol if present
  const cleanHandle = handle.replace('@', '');
  // Basic validation - ensure handle exists and follows Instagram username rules
  return /^[a-zA-Z0-9._]{1,30}$/.test(cleanHandle);
};    