import React from 'react';
import { Box, Select } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/datepicker-custom.css';
import { Campaign } from '../../../../types';
import { getStartDate, getEndDate, hasAvailableTimeSlots } from '../utils/dateUtils';

interface DateTimeSelectorProps {
  selectedDate: Date | null;
  selectedTime: string;
  timeOptions: Map<string, { time: string; timezone: string; }[]>;
  onDateChange: (date: Date) => void;
  onTimeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  campaign?: Campaign;
}

const DateTimeSelector: React.FC<DateTimeSelectorProps> = ({
  selectedDate,
  selectedTime,
  timeOptions,
  onDateChange,
  onTimeChange,
  campaign,
}) => {
  const getNormalizedDateKey = (date: Date | null) => {
    if (!date) return '';
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized.toISOString();
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" px={4}>
        <DatePicker
          selected={selectedDate}
          onChange={(date: Date) => {
            onDateChange(date);
          }}
          minDate={getStartDate(campaign)}
          maxDate={getEndDate(campaign)}
          filterDate={date => hasAvailableTimeSlots(date, timeOptions)}
          inline
        />
      </Box>
      <Box>
        <Select
          placeholder="Select time"
          value={selectedTime}
          onChange={onTimeChange}
          isDisabled={!selectedDate}
        >
          {timeOptions.get(getNormalizedDateKey(selectedDate))?.map(({ time, timezone }) => (
            <option key={time} value={time}>
              {time} ({timezone})
            </option>
          ))}
        </Select>
      </Box>
    </>
  );
};

export default DateTimeSelector; 