import { useDropzone } from 'react-dropzone';
import { Box, Flex, Text, Link, IconButton, Button } from '@chakra-ui/react';
import { FaEye, FaRedo } from 'react-icons/fa';
import { uploadMediaKit } from '../../../../services/firebaseService';

const MediaKit = ({ mediaKit, setMediaKit }) => {

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const arrayBuffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const mediaKitURL = await uploadMediaKit(uint8Array, file.type, file.name);
    setMediaKit(mediaKitURL);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
  });

  return (
    <>
      <Text fontWeight="bold" fontSize="xl">Media Kit</Text>
      <Box {...getRootProps()} border="2px dashed" borderColor="gray.300" p={4} textAlign="center" cursor="pointer" mt={4}>
        <input {...getInputProps()} />
        <Text>
          {mediaKit 
            ? "PDF uploaded. Click here to select a different file." 
            : "Optional: Upload a media kit to help brands understand your brand and content."}
        </Text>
      </Box>
      {mediaKit && (
        <Flex mt={2}>
          <Link href={mediaKit} isExternal>
            <IconButton size="sm" icon={<FaEye size={20} />} aria-label="View File" color="blackAlpha" />
          </Link>
          <Button
            ml={2}
            bg="red.500"
            color="white"
            _hover={{ bg: "red.600" }}
            size="sm"
            onClick={() => {
              setMediaKit('');
            }}
            borderRadius="full"
          >
            <FaRedo size={10} />
          </Button>
        </Flex>
      )}
    </>
  );
};

export default MediaKit; 