import { Campaign, Client } from '@foodfluence/shared';
import { apiRequest } from './apiUtils';

interface ClientAndCampaigns {
  client: Client;
  campaigns: Campaign[];
}

interface ExploreData {
  featuredBrands?: {
    data: Array<{ client: Client; campaigns: Campaign[] }>;
    cursor: string | null;
    hasMore: boolean;
  };
  activeCampaigns?: {
    data: Array<{ campaign: Campaign; client: Client }>;
    cursor: string | null;
    hasMore: boolean;
  };
  allBrands?: {
    data: Array<{ client: Client; campaigns: Campaign[] }>;
    cursor: string | null;
    hasMore: boolean;
  };
}

export const exploreApi = {
  getClientAndCampaigns: async (clientId: string): Promise<ClientAndCampaigns> => {
    return apiRequest({
      method: 'GET',
      endpoint: `/explore/client/${clientId}/campaigns`
    });
  },

  getExploreData: async (
    section: 'featuredBrands' | 'activeCampaigns' | 'allBrands',
    cursor: string | null = null,
    filters?: {
      searchQuery?: string;
      location?: {
        lat: number;
        lng: number;
        radius: number;
      };
    },
    limit: number = 10
  ): Promise<ExploreData> => {
    const params = new URLSearchParams({
      limit: limit.toString()
    });
    
    if (cursor) params.append('cursor', cursor);
    if (filters?.searchQuery) params.append('search', filters.searchQuery);
    if (filters?.location) {
      params.append('lat', filters.location.lat.toString());
      params.append('lng', filters.location.lng.toString());
      params.append('radius', filters.location.radius.toString());
    }

    return apiRequest({
      method: 'GET',
      endpoint: `/explore/data/${section}?${params.toString()}`
    });
  }
}; 