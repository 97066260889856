import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, IconButton, Text, Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

interface CounterOrConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onCounter: () => void;
  onConfirmReject: () => void;
  maxCounter: number;
  numTimesCountered: number;
}

const CounterOrConfirmModal: React.FC<CounterOrConfirmModalProps> = ({ isOpen, onClose, onBack, onCounter, onConfirmReject, maxCounter, numTimesCountered }) => {
    const handleBackAndClose = () => {
      onBack();
      onClose();
    };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth="360px"          
        padding="4"              
        borderRadius="12px"       
        boxShadow="lg"            
        bg="white"                
      >
        <ModalHeader>
          <IconButton
            icon={<ArrowBackIcon />}
            aria-label="Back"
            onClick={handleBackAndClose} 
            variant="ghost"
            position="absolute"
            left={4}
            top={4}
          />
        </ModalHeader>
        <ModalBody>
        <Text fontSize="2xl" fontWeight="bold" textAlign="left" color="black" mt={2} mb={2}>
            Are you sure you want to decline?
          </Text>
          <Text fontSize="xl" fontWeight="normal" textAlign="left" color="gray.600" mb={4}>
            Love the brand but not the offer? Submit a counter (only {maxCounter} counter {maxCounter === 1 ? 'submission' : 'submissions'} per offer)
          </Text>
          <Text fontSize="md" fontWeight="normal" textAlign="left" color="red.600" mb={4}>
            Declining makes this offer inaccessible.
          </Text>
          {/* {numTimesCountered === maxCounter - 1 && (
            <Text fontSize="md" fontWeight="normal" textAlign="left" color="red.600" mb={4}>
              You will not be able to counter again.
            </Text>
          )} */}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onCounter}
            bg="black"
            color="white"
            _hover={{ bg: "blackAlpha.900" }}
            flex="1"
            mr="2"
          >
            Counter
          </Button>
          <Button
            variant="outline"
            onClick={onConfirmReject}
            borderColor="grey"
            color="black"
            _hover={{ bg: "gray.100" }}
            flex="1"
          >
            Reject
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CounterOrConfirmModal;