import { Campaign } from '../../../../types';
import { SchedulingData, SelectedLocation } from './types';
import { getTimezone } from './timezoneUtils';

export interface ValidationParams {
  selectedDate: Date | null;
  selectedTime: string;
  selectedLocation: SelectedLocation | null;
  partySize: number | null;
  initialDate: Date | null;
  initialTime: string;
  initialLocation: SelectedLocation | null;
  initialPartySize: number | null;
  campaign?: Campaign;
}


export const isLocationValid = (selectedLocation: SelectedLocation | null, campaign?: Campaign): boolean => {
  if (!campaign?.locations || campaign.locations.length === 0) return true;
  return !!selectedLocation?.docId;
};

export const isPartySizeValid = (partySize: number | null): boolean => {
  return partySize !== null;
};

export const isDateTimeValid = (selectedDate: Date | null, selectedTime: string): boolean => {
  return !!selectedDate && !!selectedTime && selectedDate > new Date();
};

export const hasLocationChanges = (
  selectedLocation: SelectedLocation | null,
  initialLocation: SelectedLocation | null,
  campaign?: Campaign
): boolean => {
  if (!campaign?.locations || campaign.locations.length === 0) return false;
  if (!initialLocation) return !!selectedLocation;
  return selectedLocation?.docId !== initialLocation?.docId;
};

export const hasDateTimeChanges = (
  selectedDate: Date | null,
  selectedTime: string,
  initialDate: Date | null,
  initialTime: string
): boolean => {
  if (!initialDate || !initialTime) return true;
  return selectedDate?.getTime() !== initialDate?.getTime() || selectedTime !== initialTime;
};

export const hasPartySizeChanges = (
  partySize: number | null,
  initialPartySize: number | null
): boolean => {
  if (!initialPartySize) return true;
  return partySize !== initialPartySize;
};

export const hasAnyChanges = (
  selectedDate: Date | null,
  selectedTime: string,
  selectedLocation: SelectedLocation | null,
  partySize: number | null,
  initialDate: Date | null,
  initialTime: string,
  initialLocation: SelectedLocation | null,
  initialPartySize: number | null,
  campaign?: Campaign
): boolean => {
  return hasLocationChanges(selectedLocation, initialLocation, campaign) || 
         hasDateTimeChanges(selectedDate, selectedTime, initialDate, initialTime) ||
         hasPartySizeChanges(partySize, initialPartySize);
};

export const isSubmitDisabled = ({
  selectedDate,
  selectedTime,
  selectedLocation,
  partySize,
  initialDate,
  initialTime,
  initialLocation,
  initialPartySize,
  campaign
}: ValidationParams): boolean => {
  // Check validity
  const isValid = isDateTimeValid(selectedDate, selectedTime) && 
                 isLocationValid(selectedLocation, campaign) && 
                 isPartySizeValid(partySize);

  // Check for changes
  const hasChanges = hasDateTimeChanges(selectedDate, selectedTime, initialDate, initialTime) ||
                    hasLocationChanges(selectedLocation, initialLocation, campaign) ||
                    hasPartySizeChanges(partySize, initialPartySize);

  return !isValid || !hasChanges;
};

export const initializeSchedulingData = (
  collab?: { 
    scheduledCollabDate?: { seconds: number; nanoseconds: number },
    selectedLocation?: string,
    partySize?: number 
  },
  campaign?: Campaign,
): SchedulingData => {
  let selectedDate = null;
  let selectedTime = '';
  let selectedLocation = null;

  if (collab?.scheduledCollabDate) {
    const date = new Date(collab.scheduledCollabDate.seconds * 1000);
    const timezone = getTimezone(collab?.selectedLocation, campaign);

    // Convert the date to the location's timezone
    const options = { timeZone: timezone };
    const locationTime = new Date(date.toLocaleString('en-US', options));
    
    // Format the time string
    const timeString = locationTime.toLocaleTimeString('en-US', { 
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true,
    });

    // Set the date accounting for timezone differences
    const tzOffset = locationTime.getTime() - date.getTime();
    const adjustedDate = new Date(date.getTime() + tzOffset);

    // Only set the date and time if they are in the future
    const now = new Date();
    if (adjustedDate > now) {
      selectedDate = adjustedDate;
      selectedTime = timeString;
    }
  }

  if (collab?.selectedLocation && campaign?.locations) {
    const location = campaign.locations.find(loc => loc.docId === collab.selectedLocation);
    if (location) {
      selectedLocation = {
        docId: location.docId,
        fullAddress: location.fullAddress
      };
    }
  }

  return {
    selectedDate,
    selectedTime,
    selectedLocation,
    initialDate: selectedDate,
    initialTime: selectedTime,
    initialPartySize: collab?.partySize || null,
    initialLocation: selectedLocation,
    locationSearchQuery: selectedLocation?.fullAddress || ''
  };
}; 