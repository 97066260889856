import { useContext, useState, useEffect, useRef } from "react";
import { Box, Flex, Text, Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { CollabDataContext } from "contexts/collabDataContext";
import { InfluencerCampaignDataContext } from "contexts/InfluencerCampaignDataContext";
import ConversationsList from "./components/conversationsList";
import { useNavigate, Outlet } from "react-router-dom";
import { Client } from "types";

export default function Inbox({ tab }: { tab: string }) {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(tab || "current");
  const [searchTerm, setSearchTerm] = useState("");
  const collabsData = useContext(CollabDataContext);
  const influencerCampaignData = useContext(InfluencerCampaignDataContext);
  const searchInputRef = useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    if (searchTerm && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchTerm]);

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName.toLowerCase());
    navigate(`/inbox/${buttonName.toLowerCase()}`);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const InboxMain = () => {
    return (
      <Box position="relative" fontSize="15px" lineHeight="24px" p="20px" maxWidth="100vw">
        <Flex justifyContent="space-between" alignItems="center" mb="24px">
          <Text fontWeight="bold" fontSize="25px">
            Inbox
          </Text>
        </Flex>

        <InputGroup mb="20px" maxWidth="50vw">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input 
            id="search-input"
            name="search"
            placeholder="Search" 
            value={searchTerm} 
            onChange={handleSearchChange} 
            ref={searchInputRef}
          />
        </InputGroup>

        <Flex mb="20px">
          {["Current", "Previous", "Unread"].map((buttonName) => (
            <Button
              key={buttonName}
              variant="outline"
              mr="10px"
              onClick={() => handleButtonClick(buttonName)}
              bg={activeButton === buttonName.toLowerCase() ? "gray.200" : "transparent"}
              _hover={{ bg: "gray.100" }}
              transition="background-color 0.1s ease-in-out"
              fontSize="15px"
            >
              {buttonName}
            </Button>
          ))}
        </Flex>
        <ConversationsList 
          onConversationClick={handleConversationClick} 
          setting={activeButton} 
          searchTerm={searchTerm} 
        />
        <Outlet />
      </Box>
    );
  };

  const handleConversationClick = (conversationId: string, client: Client) => {
    const collab = collabsData.find(collab => collab.id === conversationId);
    const campaign = influencerCampaignData[collab.id];
    const path = `/inbox/${activeButton.toLowerCase()}/messages`;
    navigate(path, { state: { conversationId, campaign, collab, client } });
  };

  return (
    <InboxMain />
  );
}