import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  VStack,
  Image,
  Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

interface SignInSignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  returnTo: string;
  returnState: any;
}

const SignInSignUpModal: React.FC<SignInSignUpModalProps> = ({ isOpen, onClose, returnTo, returnState }) => {
  const navigate = useNavigate();

  const handleNewUser = () => {
    navigate('/signup-application', { state: { returnTo: returnTo, state: returnState } });
    onClose();
  };

  const handleReturningUser = () => {
    navigate('/returning-user', { state: { returnTo: returnTo, state: returnState } });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent
        bg="white"
        borderRadius="xl"
        boxShadow="xl"
        p={6}
        mx={4}
      >
        <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold">
          Sign In to Continue
        </ModalHeader>
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <Image 
              src="https://firebasestorage.googleapis.com/v0/b/storyitapp.appspot.com/o/touchpoints%2FFoodFluence.io%20Logo%20(461%20x%20196%20px)%20(6).png?alt=media&token=54ec9ac5-6bd9-40e1-98c4-12597c3953a1" 
              alt="FoodFluencer Logo" 
              objectFit="contain"
              mx="auto"
              mb={4}
            />
            <Text textAlign="center" fontSize="lg" color="gray.600">
              Please sign in or create an account to continue with this collaboration
            </Text>
            <Flex justify="center" width="100%" gap={4}>
              <Button 
                bg="#2F2F2F" 
                color="white" 
                width="45%" 
                onClick={handleReturningUser}
                _hover={{ bg: "gray.700" }}
              >
                Sign In
              </Button>
              <Button 
                bg="#2F2F2F" 
                color="white" 
                width="45%" 
                onClick={handleNewUser}
                _hover={{ bg: "gray.700" }}
              >
                Sign Up
              </Button>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SignInSignUpModal; 