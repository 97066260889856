import React, { useContext, useState } from 'react';
import { Box, VStack, Flex, Button, IconButton, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CreativeBrief from '../components/creativeBrief';
import { acceptCollab, rejectCollab, checkAcceptedCollabsLimit } from '../../../services/firebaseService';
import { InfluencerCampaignDataContext } from '../../../contexts/InfluencerCampaignDataContext';
import DeclineReasonModal from '../components/counter-flow/DeclineReasonModal';
import { useLocation } from 'react-router-dom';
import { InfluencerDataContext } from 'contexts/InfluencerDataContext';
import { Influencer } from 'types';
import AddressCollectionModal from '../components/AddressCollectionModal';
import { acceptCollabReminder } from 'services/flaskService';
import { CollabDataContext } from 'contexts/collabDataContext';


const ReviewCollabPage: React.FC = () => {
  const navigate = useNavigate();
  
  const toast = useToast();

  const campaignData = useContext(InfluencerCampaignDataContext);
  
  const { influencerData } = useContext(InfluencerDataContext) as { influencerData: Influencer | null };

  const collabData = useContext(CollabDataContext);

  const location = useLocation();
  const collabId = location.state.collab.id;
  
  const collab = collabData.find(c => c.id === collabId);
  
  const campaign = collabId ? campaignData[collabId] : undefined;
  
  const [isDeclineReasonModalOpen, setIsDeclineReasonModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  const maxCounter = location.state.maxCounter;

  const handleAccept = async () => {
    if(collab.status != 'pendingInfluencerApproval') {
      toast({
        title: "Collab already accepted",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (isAccepting) return; // Prevent double clicks
    
    try {
      setIsAccepting(true); // Disable button
      
      if (!collab) {
        toast({
          title: "Error",
          description: "Required data is missing. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (campaign?.campaignType === 'shipToHome') {
        setIsAddressModalOpen(true);
      } else {
        await checkAcceptedCollabsLimit(collab, campaign);
        
        await acceptCollab(collab.id);
        acceptCollabReminder(collab.id);
        toast({
          title: "Collab accepted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate(`/collabs/schedule-collab`, {state: { collab, campaign, influencer: influencerData}});
      }
    } catch (error) {
      console.error('Error accepting collab:', error);
      toast({
        title: "Error accepting collab",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsAccepting(false); // Re-enable button
    }
  };

  const handleReject = () => {
    setIsDeclineReasonModalOpen(true); // Opens the modal immediately
  };

  const handleModalSubmit = async (reason: string) => {
    try {
      if (!collab.id) throw new Error("Collab ID is undefined");
      await rejectCollab(collab.id, reason);
      toast({
        title: "Collab rejected",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/collabs');
    } catch (error) {
      console.error('Error rejecting collab:', error);
      toast({
        title: "Error rejecting collab",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsDeclineReasonModalOpen(false);
    }
  };

  const openIntercom = () => {
    if (window.Intercom) {
      window.Intercom('show');
    } 
  }

  if (!collab || !campaign) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box height="100svh" bg="white" p={4} display="flex" flexDirection="column">
      <IconButton
        icon={<ArrowBackIcon/>}
        onClick={() => navigate('/collabs')}
        position="absolute"
        top={4}
        left={4}
        aria-label="Go back"
        bg="white"
        color="black"
        _hover={{ bg: "gray.100" }}
      />
      <VStack spacing={6} align="stretch" flex={1} mt={1}>
        <CreativeBrief collab={collab} campaign={campaign} />
        {(campaign?.allowCounters === false || collab.counters?.length === maxCounter) && (
          <Box color="red" fontWeight="bold" textAlign="center">
            {campaign?.allowCounters === false 
              ? "Counters are not allowed for this collab"
              : "You will not be able to counter"
            }
          </Box>
        )}
      </VStack>
      <Flex justifyContent="space-between" mt={6} mb={8} p={4}>
        <>
          <Button
            bg="black"
            color="white"
            _hover={{ bg: "gray.800" }}
            onClick={handleAccept}
            flex={1}
            mr={2}
            isLoading={isAccepting}
            loadingText="Accepting..."
            disabled={isAccepting}
          >
            Accept
          </Button>
          <Button 
            bg="white" 
            color="black" 
            border="1px solid" 
            borderColor="grey" 
            width="48%"
            size="md" 
            flex={1}
            ml={2}
            onClick={handleReject}
            disabled={isAccepting}
          >
            Reject
          </Button>
        </>
      </Flex>
      <AddressCollectionModal
        isOpen={isAddressModalOpen}
        onClose={() => setIsAddressModalOpen(false)}
        collab={collab}
        campaign={campaign}
      />
      <DeclineReasonModal
        isOpen={isDeclineReasonModalOpen}
        onClose={() => setIsDeclineReasonModalOpen(false)}
        onSubmit={handleModalSubmit} 
        onBack={() => setIsDeclineReasonModalOpen(true)}
        collab={collab}
        campaign={campaign}
        maxCounter={maxCounter}
      />
    </Box>
  );
};

export default ReviewCollabPage;
