import {
  collection,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  Timestamp,
  DocumentReference,
  FieldValue,
  query,
  where,
  getDocs,
  setDoc
} from "firebase/firestore";
import { firestore, auth } from "../firebase";
import { Collab, CollabCounter, DeliverableLink, Influencer, CollabLog, SubscriptionHistory } from "../types";
import { RecaptchaVerifier, signInWithPhoneNumber, ConfirmationResult, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink  } from "firebase/auth";
import { billForAdditionalCollab, sendCollabSubmitContentReminder } from './flaskService';
import { generateInstagramCode } from "./verificationService";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier | undefined;
    confirmationResult: ConfirmationResult | undefined;
  }
}

const updateCollabStatus = async (collabId: string, newStatus: string, additionalFields: Record<string, any> = {}): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    const now: Timestamp = Timestamp.now();
    
    const updateData: {
      status: string;
      logs: CollabLog[] | FieldValue;
    } = {
      status: newStatus,
      logs: arrayUnion({
        status: newStatus,
        timestamp: now
      }),
      ...additionalFields
    };

    await updateDoc(collabRef, updateData);
    console.log(`Collab ${newStatus} successfully`);
  } catch (error) {
    console.error(`Error updating collab status to ${newStatus}:`, error);
    throw error;
  }
};

export const expireCollab = async (collabId: string): Promise<void> => {
  try {
    await updateCollabStatus(collabId, "collabExpired");
    console.log('Collab expired successfully');
  } catch (error) {
    console.error('Error expiring collab:', error);
    throw error;
  }
};

export const rejectCollab = async (collabId: string, reason?: string): Promise<void> => {
  try {
    if (reason) {
      await updateCollabStatus(collabId, "collabRejected", {influencerRejectionReason: reason});
    } else {
      await updateCollabStatus(collabId, "collabRejected");
    }
    console.log('Collab rejected successfully');
  } catch (error) {
    console.error('Error rejecting collab:', error);
    throw error;
  }
};

export const cancelCollab = async (collabId: string, influencerCancelReason?: string): Promise<void> => {
  try {
    await updateCollabStatus(collabId, "collabCanceled", {collabCanceledTime: Timestamp.now(), influencerCancelReason: influencerCancelReason});
    console.log('Collab canceled successfully');
  } catch (error) {
    console.error('Error canceling collab:', error);
    throw error;
  }
};

export const acceptCollab = async (collabId: string): Promise<void> => {
  try {
    const now: Timestamp = Timestamp.now();
    const newStatus = "pendingRedemption";
    await updateCollabStatus(collabId, newStatus, { collabAcceptedTime: now });
    await createNewConversation(collabId);
    console.log(`Collab accepted successfully`);
  } catch (error) {
    console.error(`Error accepting collab:`, error);
    throw error;
  }
};

export const acceptAtHomeCollab = async (collabId: string, shippingAddress: string, deliverableCounts: Record<string, number>): Promise<void> => {
  try {
    const deliverableLinks: { [key: string]: DeliverableLink[] } = {};
    for (const [type, count] of Object.entries(deliverableCounts)) {
      deliverableLinks[type] = Array(count).fill({ userLink: '', verifiedUGCLink: '', status: 'pendingSubmission' });
    }
    
    const now: Timestamp = Timestamp.now();
    const newStatus = "pendingSubmission";

    await updateCollabStatus(collabId, newStatus, { collabAcceptedTime: now, collabRedeemedTime: now, shippingAddress, deliverableLinks });
    await createNewConversation(collabId);
  } catch (error) {
    console.error('Error accepting collab and sending address:', error);
    throw error;
  }
};

export const submitCollab = async (collabId: string, deliverableLinks: { [key: string]: DeliverableLink[] }): Promise<void> => {
  try {
    await updateCollabStatus(collabId, "pendingVerification", { deliverableLinks });
    console.log('Collab submitted successfully');
  } catch (error) {
    console.error('Error submitting collab:', error);
    throw error;
  }
};

export const submitLink = async (
  collabId: string,
  linkType: string,
  link: string,
  linkIndex: number,
  deliverableLinks: { [key: string]: DeliverableLink[] }
): Promise<void> => {
  try {
    // Check if the specific link exists
    if (!deliverableLinks[linkType] || !deliverableLinks[linkType][linkIndex]) {
      throw new Error("Link not found");
    }

    // Update the specific link
    deliverableLinks[linkType][linkIndex] = {
      ...deliverableLinks[linkType][linkIndex],
      userLink: link,
      status: "pendingVerification"
    };

    // Update the document in Firestore
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    await updateDoc(collabRef, { deliverableLinks });

    console.log(`Link ${linkType} at index ${linkIndex} submitted successfully`);
  } catch (error) {
    console.error(`Error submitting link ${linkType} at index ${linkIndex}:`, error);
    throw error;
  }
};

export const redeemCollab = async (collabId: string): Promise<void> => {
  try {
    const now: Timestamp = Timestamp.now();
    await updateCollabStatus(collabId, "collabRedeemed", { collabRedeemedTime: now });
    console.log('Collab redeemed successfully');
  } catch (error) {
    console.error('Error redeeming collab:', error);
    throw error;
  }
};

export const completeCollab = async (collabId: string, deliverableCounts: Record<string, number>, skipRedeem: boolean = false): Promise<void> => {
  try {
    const deliverableLinks: { [key: string]: DeliverableLink[] } = {};
    for (const [type, count] of Object.entries(deliverableCounts)) {
      deliverableLinks[type] = Array(count).fill({ userLink: '', verifiedUGCLink: '', status: 'pendingSubmission' });
    }
    const additionalFields: { deliverableLinks: { [key: string]: DeliverableLink[] }, collabRedeemedTime?: Timestamp } = { deliverableLinks };

    if (skipRedeem) {
      additionalFields.collabRedeemedTime = Timestamp.now();
    }

    await updateCollabStatus(collabId, "pendingSubmission", additionalFields);
    console.log('Collab completed successfully');

    // Call the new function to send the reminder
    sendCollabSubmitContentReminder(collabId);
  } catch (error) {
    console.error('Error completing collab:', error);
    throw error;
  }
};

export const counterCollab = async (collabId: string, deliverableCounts: Record<string, number>, cash: number, credit: number, reason: string, previousCounters: CollabCounter[]): Promise<void> => {
  try {
    const newCounters = [...previousCounters, {reason, proposedInfluencerDeliverableCounts: deliverableCounts, proposedInfluencerCash: cash, proposedInfluencerCredit: credit, timestamp: Timestamp.now() }];
    await updateCollabStatus(collabId, "influencerCountered", {
      proposedInfluencerDeliverableCounts: deliverableCounts,
      proposedInfluencerCash: cash,
      proposedInfluencerCredit: credit,
      counters: newCounters,
      collabCounteredTime: Timestamp.now()
    });

    console.log('Collab countered successfully');
  } catch (error) {
    console.error('Error countering collab:', error);
    throw error;
  }
};
export const updateCollabCounter = async (collabId: string, deliverableCounts: Record<string, number>, cash: number, credit: number, counters: CollabCounter[]): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);

    if (counters.length > 0) {
      const mostRecentCounterIndex: number = counters.reduce((latestIndex: number, currentCounter: CollabCounter, currentIndex: number) => 
        currentCounter.timestamp > counters[latestIndex].timestamp ? currentIndex : latestIndex
      , 0);
      counters[mostRecentCounterIndex].proposedInfluencerDeliverableCounts = deliverableCounts;
      counters[mostRecentCounterIndex].proposedInfluencerCash = cash;
      counters[mostRecentCounterIndex].proposedInfluencerCredit = credit;
      counters[mostRecentCounterIndex].timestamp = Timestamp.now();
    }

    await updateDoc(collabRef, {
      proposedInfluencerDeliverableCounts: deliverableCounts,
      proposedInfluencerCash: cash,
      proposedInfluencerCredit: credit,
      counters: counters
    });
    console.log('Collab counter updated successfully');
  } catch (error) {
    console.error('Error updating collab counter:', error);
    throw error;
  }
};

export const updateCollabDate = async (collabId: string, selectedDate: Date, selectedLocation?: string, partySize?: number): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    const updateData: { scheduledCollabDate: Timestamp, selectedLocation?: string, partySize?: number } = { scheduledCollabDate: Timestamp.fromDate(selectedDate) };
    
    if (selectedLocation) {
      updateData.selectedLocation = selectedLocation;
    }

    if (partySize) {
      updateData.partySize = partySize;
    }
    
    await updateDoc(collabRef, updateData);
    console.log("Collab date updated successfully");
  } catch (error) {
    console.error("Error updating collab date:", error);
    throw error;
  }
};

export const updateDeliverableLinks = async (collabId: string, deliverableLinks: { [key: string]: DeliverableLink[] }): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    await updateDoc(collabRef, { deliverableLinks });
    console.log("Deliverable links updated successfully");
  } catch (error) {
    console.error("Error updating deliverable links:", error);
    throw error;
  }
};

export const updateCollabUserRedemptionConfirmation = async (collabId: string, userConfirmation: boolean): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    await updateDoc(collabRef, {
      userConfirmRedemption: userConfirmation ? "yes" : "no"
    });
    console.log('User confirmation updated successfully');
  } catch (error) {
    console.error('Error updating user confirmation:', error);
    throw error;
  }
};

export const checkInfluencerEmail = async (email: string): Promise<boolean> => {
  try {
    const influencersRef = collection(firestore, "influencers");
    const q = query(influencersRef, where("email_address", "==", email.toLowerCase()));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  } catch (error) {
    console.error("Error checking influencer email:", error);
    throw error;
  }
};

export const checkInfluencerPhone = async (phoneNumber: string): Promise<boolean> => {
  try {
    const influencersRef = collection(firestore, "influencers");
    const q = query(influencersRef, where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  } catch (error) {
    console.error("Error checking influencer phone:", error);
    throw error;
  }
};

export const sendEmailVerificationCode = async (email: string, phoneNumber: string, firstName: string, lastName: string): Promise<boolean> => {
  try {
    const actionCodeSettings = {
      url: `${window.location.origin}/collabs?email=${encodeURIComponent(email)}&phoneNumber=${encodeURIComponent(phoneNumber)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}`,
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    
    return true;
  } catch (error) {
    console.error("Error sending email verification code:", error);
    return false;
  }
};

export const sendPhoneVerificationCode = async (phoneNumber: string): Promise<any> => {
  try {
    if (!window.recaptchaVerifier) {
      throw new Error("reCAPTCHA not initialized");
    }
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
    return confirmationResult;
  } catch (error) {
    console.error("Error sending phone verification code:", error);
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = undefined;
    }
    throw error;
  }
};

export const verifyEmailCode = async (): Promise<boolean> => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const phoneNumber = urlParams.get("phoneNumber");
    const firstName = urlParams.get("firstName");
    const lastName = urlParams.get("lastName");

    if (isSignInWithEmailLink(auth, window.location.href)) {
      await signInWithEmailLink(auth, email, window.location.href);

      if (phoneNumber) {
        const user = auth.currentUser;
        if (user) {
          const influencer = await getInfluencerByEmail(email);
          if (influencer) {
            await updateInfluencerContactInformation(influencer.id, firstName, lastName, phoneNumber);
            console.log('Contact information added to the database successfully');
          } else {
            console.error('No influencer found for the given email.');
          }
        }
      }
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error verifying email code:", error);
    return false;
  }
};

export const verifyPhoneCode = async (confirmationResult: ConfirmationResult, code: string): Promise<boolean> => {
  try {
    if (!confirmationResult) {
      throw new Error("Confirmation result not found");
    }
   
    const credential = await confirmationResult.confirm(code);
    return !!credential.user;
  } catch (error) {
    console.error("Error verifying phone code:", error);
    return false;
  }
};

export const getInfluencerByPhoneNumber = async (phoneNumber: string): Promise<Influencer | null> => {
  try {
    const influencersRef = collection(firestore, "influencers");
    const q = query(influencersRef, where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const influencerDoc = querySnapshot.docs[0];
      return { id: influencerDoc.id, ...influencerDoc.data() } as Influencer;
    }
    return null;
  } catch (error) {
    console.error("Error fetching influencer by phone number:", error);
    throw error;
  }
};

export const getInfluencerByEmail = async (email: string): Promise<Influencer | null> => {
  try {
    const influencersRef = collection(firestore, "influencers");
    const q = query(influencersRef, where("email_address", "==", email.toLowerCase()));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const influencerDoc = querySnapshot.docs[0];
      return { id: influencerDoc.id, ...influencerDoc.data() } as Influencer;
    }
    return null;
  } catch (error) {
    console.error("Error fetching influencer by email:", error);
    throw error;
  }
};

export const updatePhoneNumber = async (influencerID: string, newPhoneNumber: string): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerID);
    await updateDoc(influencerRef, {
      phoneNumber: newPhoneNumber
    });
    console.log('Phone number updated successfully');
  } catch (error) {
    console.error('Error updating phone number:', error);
    throw error;
  }
};

export const updateFirstName = async (influencerID: string, newFirstName: string): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerID);
    await updateDoc(influencerRef, {
      firstName: newFirstName
    });
    console.log('First name updated successfully');
  } catch (error) {
    console.error('Error updating first name:', error);
    throw error;
  }
};

export const updateLastName = async (influencerID: string, newLastName: string): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerID);
    await updateDoc(influencerRef, {
      lastName: newLastName
    });
    console.log('Last name updated successfully');
  } catch (error) {
    console.error('Error updating last name:', error);
    throw error;
  }
};

export const updateEmailAddress = async (influencerID: string, newEmailAddress: string): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerID);
    await updateDoc(influencerRef, {
      email_address: newEmailAddress.toLowerCase()
    });
    console.log('Email address updated successfully');
  } catch (error) {
    console.error('Error updating email address:', error);
    throw error;
  }
};

export const claimCollabPayment = async (collabId: string): Promise<void> => {
  try {
    const collabRef = doc(firestore, "collabs", collabId);
    await updateDoc(collabRef, {
      paymentClaimed: true,
    });
    console.log('Payment claimed successfully');
  } catch (error) {
    console.error('Error claiming payment:', error);
    throw error;
  }
};

export const createOrRetrieveInstagramVerificationCode = async (email: string, instagramHandle: string): Promise<string> => {
  try {
    const influencersRef = collection(firestore, "instagramVerifications");
    
    const existingCode = await doesRecentInstagramVerificationCodeExist(instagramHandle);

    if (!existingCode) {
      const code = generateInstagramCode();
      await setDoc(doc(influencersRef, instagramHandle), {
        email: email.toLowerCase(),
        instagramHandle: instagramHandle,
        verificationCode: code,
        createdAt: Timestamp.now(),
        verified: false
      });
      return code;
    }
    return existingCode;
  } catch (error) {
    console.error("Error storing Instagram verification code:", error);
    throw error;
  }
};

export const getCollabsTakenForCampaign = async (campaignId: string): Promise<number | undefined> => {
  try {
    // Get all collabs with the relevant statuses
    const collabsRef = collection(firestore, "collabs");
    const q = query(
      collabsRef, 
      where("influencerCampaignID", "==", campaignId),
      where("status", "in", [
        "pendingRedemption",
        "collabRedeemed",
        "pendingSubmission",
        "collabCompleted"
      ])
    );

    const querySnapshot = await getDocs(q);
    const takenSpots = querySnapshot.size;

    return takenSpots;
  } catch (error) {
    console.error('Error getting taken collabs:', error);
    throw error;
  }
};

export const doesRecentInstagramVerificationCodeExist = async (instagramHandle: string): Promise<string> => {
  try {
    const influencersRef = doc(firestore, "instagramVerifications", instagramHandle);
    const docSnapshot = await getDoc(influencersRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      const createdAt = data.createdAt.toDate();
      const code = data.verificationCode;
      const isRecent = createdAt > new Date(Date.now() - 10 * 60 * 1000); // 10 minutes ago
      if (isRecent) {
        return code;
      }
    }
    return null;
  } catch (error) {
    console.error("Error retrieving Instagram verification code:", error);
    throw error;
  }
};

export const createNewConversation = async (collabId: string): Promise<void> => {
  try {
    const conversationRef = doc(collection(firestore, "conversations"), collabId);
    const now: Timestamp = Timestamp.now();
    await setDoc(conversationRef, {
      time_created: now,
      messages_log: {}
    });
    console.log('New conversation created successfully');
  } catch (error) {
    console.error('Error creating new conversation:', error);
    throw error;
  }
};

export const sendMessage = async (conversationId: string, message: string): Promise<void> => {
  try {
    const conversationRef = doc(collection(firestore, "conversations"), conversationId);
    const now: Timestamp = Timestamp.now();
    const messageId = doc(collection(conversationRef, "messages_log")).id; 

    await updateDoc(conversationRef, {
      [`messages_log.${messageId}`]: {
        sender: "influencer",
        body: message,
        time_sent: now,
        type: "message"
      }
    });
    console.log('Message sent successfully');
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const sendFileMessage = async (conversationId: string, file: File): Promise<void> => {
  try {
    const conversationRef = doc(collection(firestore, "conversations"), conversationId);
    const now: Timestamp = Timestamp.now();
    const messageId = doc(collection(conversationRef, "messages_log")).id;

    const randomKey = Math.random().toString(36).substring(2, 15);
    // Create a reference to the file in Firebase Storage
    const fileRef = ref(storage, `conversations/files/${randomKey}/${file.name}`);

    // Upload the file to Firebase Storage
    await uploadBytes(fileRef, file);

    // Get the download URL for the uploaded file
    const fileUrl = await getDownloadURL(fileRef);

    // Update the conversation document with the file message
    await updateDoc(conversationRef, {
      [`messages_log.${messageId}`]: {
        sender: "influencer",
        body: file.name,
        file_url: fileUrl, 
        time_sent: now,
        type: "file", 
      }
    });

    console.log('File message sent successfully');
  } catch (error) {
    console.error('Error sending file message:', error);
    throw error;
  }
};

export const doesConversationExist = async (conversationId: string): Promise<boolean> => {
  try {
    const conversationRef = doc(collection(firestore, "conversations"), conversationId);
    const conversationDoc = await getDoc(conversationRef);
    return conversationDoc.exists();
  } catch (error) {
    console.error('Error checking conversation existence:', error);
    throw error;
  }
};

export const updateMessageReadTime = async (conversationId: string, messageId: string): Promise<void> => {
  try {
    const conversationRef = doc(collection(firestore, "conversations"), conversationId);
    const now: Timestamp = Timestamp.now();

    await updateDoc(conversationRef, {
      [`messages_log.${messageId}.time_read`]: now,
    });

    console.log('Message read time updated successfully');
  } catch (error) {
    console.error('Error updating message read time:', error);
    throw error;
  }
};

export const updateInfluencerContactInformation = async (
  influencerId: string,
  firstName?: string,
  lastName?: string,
  phoneNumber?: string
): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerId);
    const updateData: Record<string, any> = {};

    if (firstName) {
      updateData.firstName = firstName;
    }
    if (lastName) {
      updateData.lastName = lastName;
    }
    if (phoneNumber) {
      updateData.phoneNumber = phoneNumber;
    }

    await updateDoc(influencerRef, updateData);
    console.log('Influencer contact information updated successfully');
  } catch (error) {
    console.error('Error updating influencer contact information:', error);
    throw error;
  }
};

export const checkInstagramVerification = async (instagramHandle: string): Promise<boolean> => {
  try {
    const verificationRef = doc(firestore, "instagramVerifications", instagramHandle);
    const docSnapshot = await getDoc(verificationRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      const isVerified = data.verified;
      const verifiedAt = data.verifiedAt?.toDate();
      
      if (isVerified && verifiedAt) {
        const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
        return isVerified && verifiedAt > twentyFourHoursAgo;
      }
    }
    return false;
  } catch (error) {
    console.error("Error checking Instagram verification:", error);
    throw error;
  }
};

const getAdminForClient = async (clientID: string): Promise<{ adminDoc: any, subscriptionHistory: any } | null> => {
  try {
    const adminRef = collection(firestore, "clientDashboardAdmin");
    const q = query(adminRef, where("clientIDs", "array-contains", clientID));
    const adminSnapshot = await getDocs(q);

    if (adminSnapshot.empty) {
      console.error("No admin found for client:", clientID);
      return null;
    }

    const adminDoc = adminSnapshot.docs[0];
    console.log('Found admin ID:', adminDoc.id);
    const adminData = adminDoc.data();
    return {
      adminDoc,
      subscriptionHistory: adminData.subscriptionHistory || {}
    };
  } catch (error) {
    console.error("Error getting admin for client:", error);
    throw error;
  }
};

const getSubscriptionInfo = (subscriptionHistory: any): { 
  earliestPurchase: Timestamp, 
  mostRecentSubscription: any,
  mostRecentKey: string | null 
} => {
  let earliestPurchase = Timestamp.now();
  let mostRecentSubscription = null;
  let mostRecentDate = new Timestamp(0, 0);
  let mostRecentKey = null;

  Object.entries(subscriptionHistory).forEach(([key, subscription]: [string, any]) => {
    if (subscription.purchase_date) {
      if (subscription.purchase_date < earliestPurchase) {
        earliestPurchase = subscription.purchase_date;
      }
      if (subscription.purchase_date > mostRecentDate) {
        mostRecentDate = subscription.purchase_date;
        mostRecentSubscription = subscription;
        mostRecentKey = key;
      }
    }
  });

  return { earliestPurchase, mostRecentSubscription, mostRecentKey };
};

const updateCollabAndSubscription = async (
  collab: Collab, 
  adminDoc: any, 
  subscriptionHistory: SubscriptionHistory,
  mostRecentKey: string,
  additionalCollabCost: number
): Promise<void> => {
  try {
    // Update collab with additional cost
    const collabRef = doc(firestore, "collabs", collab.id);
    await updateDoc(collabRef, { additionalCollabCost });
    console.log('Added additional collab cost:', additionalCollabCost);

    // Update subscription history
    const currentAdditionalCollabs = subscriptionHistory[mostRecentKey]?.additionalCollabs || 0;
    await updateDoc(doc(firestore, "clientDashboardAdmin", adminDoc.id), {
      [`subscriptionHistory.${mostRecentKey}.additionalCollabs`]: currentAdditionalCollabs + 1
    });
    console.log('Updated additional collabs in subscription history to:', currentAdditionalCollabs + 1);

    // Bill for additional collab
    const billingSuccess = await billForAdditionalCollab(adminDoc.id, collab.id);
    if (!billingSuccess) {
      throw new Error('Failed to bill for additional collab');
    }
    console.log('Successfully billed for additional collab');
  } catch (error) {
    console.error("Error updating collab and subscription:", error);
    throw error;
  }
};

const countAcceptedCollabs = async (clientID: string, earliestPurchase: Timestamp): Promise<number> => {
  try {
    console.log('Starting countAcceptedCollabs for client:', clientID);
    console.log('Earliest purchase date:', earliestPurchase.toDate());

    // First get the admin for this client
    const adminInfo = await getAdminForClient(clientID);
    if (!adminInfo) {
      console.error('No admin found for client:', clientID);
      return 0;
    }
    console.log('Found admin:', adminInfo.adminDoc.id);

    // Get all client IDs for this admin
    const clientIDs = adminInfo.adminDoc.data().clientIDs || [];
    console.log('All client IDs for this admin:', clientIDs);

    // Get campaigns for these clients
    const campaignsRef = collection(firestore, "influencerCampaigns");
    const campaignsQuery = query(
      campaignsRef,
      where("clientID", "in", clientIDs)
    );
    const campaignsSnapshot = await getDocs(campaignsQuery);
    
    // Filter campaigns by start date in memory
    const campaignIDs = campaignsSnapshot.docs
      .filter(doc => {
        const startDate = doc.data().startDate;
        return startDate && startDate >= earliestPurchase;
      })
      .map(doc => doc.id);
    
    console.log('Found campaign IDs after subscription date:', campaignIDs);

    if (campaignIDs.length === 0) {
      return 0;
    }
    // Get collabs for these campaigns in parallel
    const collabQueries = campaignIDs.map(campaignId => {
      const collabsRef = collection(firestore, "collabs");
      const collabsQuery = query(
        collabsRef,
        where("influencerCampaignID", "==", campaignId),
        where("status", "in", [
          "pendingRedemption", 
          "collabRedeemed",
          "pendingSubmission",
          "collabCompleted"
        ])
      );
      return getDocs(collabsQuery);
    });

    const collabSnapshots = await Promise.all(collabQueries);
    const takenSpots = collabSnapshots.reduce((total, snapshot) => total + snapshot.size, 0);

    console.log('Total taken spots found:', takenSpots);

    return takenSpots;
  } catch (error) {
    console.error("Error in countAcceptedCollabs:", error);
    throw error;
  }
};

export const checkAcceptedCollabsLimit = async (collab: any, campaign: any): Promise<boolean> => {
  try {
    if (!campaign || !campaign.clientID) {
      console.error("Campaign or clientID not found");
      return false;
    }

    // Get admin info
    const adminInfo = await getAdminForClient(campaign.clientID);
    if (!adminInfo) return false;

    // Get subscription info
    const { earliestPurchase, mostRecentSubscription, mostRecentKey } = 
      getSubscriptionInfo(adminInfo.subscriptionHistory);

    // Get product catalog
    const productCatalogSnap = await getDocs(collection(firestore, "productCatalog"));
    const productCatalog: { [key: string]: any } = {};
    productCatalogSnap.forEach(doc => {
      productCatalog[doc.id] = doc.data();
    });

    // Calculate total allowed collabs
    let totalAllowedCollabs = 0;
    Object.entries(adminInfo.subscriptionHistory).forEach(([_, subscription]: [string, any]) => {
      const product = productCatalog[subscription.product_id];
      if (product?.collabs) totalAllowedCollabs += product.collabs;
      if (subscription.additionalCollabs) totalAllowedCollabs += subscription.additionalCollabs;
    });

    // Get accepted collabs count
    const acceptedCollabs = await countAcceptedCollabs(campaign.clientID, earliestPurchase);
    console.log('Accepted collabs:', acceptedCollabs, 'Total allowed:', totalAllowedCollabs);

    // Handle additional collab if needed
    if (acceptedCollabs >= totalAllowedCollabs && mostRecentSubscription && mostRecentKey) {
      const mostRecentProduct = productCatalog[mostRecentSubscription.product_id];
      if (mostRecentProduct?.additionalCollabCost) {
        await updateCollabAndSubscription(
          collab,
          adminInfo.adminDoc,
          adminInfo.subscriptionHistory,
          mostRecentKey,
          mostRecentProduct.additionalCollabCost
        );
      }
    }

    return acceptedCollabs < totalAllowedCollabs;
  } catch (error) {
    console.error("Error checking accepted collabs limit:", error);
    throw error;
  }
};

export const updateInfluencerProfile = async (influencerId: string, profileData: Partial<Influencer>): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerId);
    await updateDoc(influencerRef, profileData);
    console.log('Influencer profile updated successfully');
  } catch (error) {
    console.error('Error updating influencer profile:', error);
    throw error;
  }
};

export const uploadMediaKit = async (
  fileData: Uint8Array,
  fileType: string,
  fileName: string
) => {
  try {
    const randomKey = Math.random().toString(36).substring(2, 15);
    const storageRef = ref(storage, `Media-Kits/${randomKey}/${fileName}`);

    const fileBlob = new Blob([fileData], { type: fileType });
    await uploadBytes(storageRef, fileBlob);

    const fileURL = await getDownloadURL(storageRef);
    return fileURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const getTagsList = async (): Promise<string[]> => {
  try {
    const tagsRef = collection(firestore, "tags");
    const tagsSnapshot = await getDocs(tagsRef);
    return tagsSnapshot.docs.map(doc => doc.data().name);
  } catch (error) {
    console.error("Error getting tags list:", error);
    throw error;
  }
};