/**
 * Formats a phone number by removing non-digit characters and ensuring it has the correct country code format
 * @param phoneNumber - The phone number to format
 * @param countryCode - Optional country code (with + prefix). If not provided, assumes it's included in phoneNumber
 * @returns Formatted phone number in international format (e.g., +18472753016)
 */
export const formatPhoneNumber = (phoneNumber: string, countryCode?: string): string => {
  // Remove all non-digit characters except the + sign
  const sanitizedPhone = phoneNumber.replace(/[^\d+]/g, '');
  
  // If the phone already starts with +, return it as is after sanitizing
  if (sanitizedPhone.startsWith('+')) {
    return sanitizedPhone;
  }
  
  // If country code is provided, combine it with the sanitized phone number
  if (countryCode) {
    // Make sure country code starts with +
    const formattedCountryCode = countryCode.startsWith('+') 
      ? countryCode 
      : `+${countryCode}`;
    
    return `${formattedCountryCode}${sanitizedPhone}`;
  }
  
  // If no country code provided and phone doesn't start with +, add + as default
  return `+${sanitizedPhone}`;
};

/**
 * Combines country code and phone number into a properly formatted international phone number
 * @param countryCode - Country code (with or without + prefix)
 * @param phoneNumber - Phone number (will be sanitized)
 * @returns Formatted phone number in international format
 */
export const combinePhoneNumber = (countryCode: string, phoneNumber: string): string => {
  // Sanitize the phone number by removing all non-digit characters
  const sanitizedPhone = phoneNumber.replace(/\D/g, '');
  
  // Ensure country code has + prefix
  const formattedCountryCode = countryCode.startsWith('+') 
    ? countryCode 
    : `+${countryCode}`;
  
  return `${formattedCountryCode}${sanitizedPhone}`;
};
