import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';

interface InstagramVerificationContextProps {
  isVerified: boolean;
  isChecking: boolean;
  currentHandle: string | null;
  watchInstagramHandle: (instagramHandle: string) => void;
  stopWatching: () => void;
}

const InstagramVerificationContext = createContext<InstagramVerificationContextProps | undefined>(undefined);

export const useInstagramVerification = () => {
  const context = useContext(InstagramVerificationContext);
  if (!context) {
    throw new Error('useInstagramVerification must be used within an InstagramVerificationProvider');
  }
  return context;
};

interface InstagramVerificationProviderProps {
  children: ReactNode;
}

export const InstagramVerificationProvider: React.FC<InstagramVerificationProviderProps> = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [currentHandle, setCurrentHandle] = useState<string | null>(null);
  const [unsubscribe, setUnsubscribe] = useState<(() => void) | null>(null);

  useEffect(() => {
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [unsubscribe]);

  const watchInstagramHandle = (instagramHandle: string) => {
    if (unsubscribe) {
      unsubscribe();
    }

    setCurrentHandle(instagramHandle);
    setIsChecking(true);

    const verificationRef = doc(firestore, "instagramVerifications", instagramHandle.replace('@', ''));
    
    const listener = onSnapshot(verificationRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const verifiedAt = data.verifiedAt?.toDate();
        
        if (data.verified && verifiedAt) {
          const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
          setIsVerified(verifiedAt > twentyFourHoursAgo);
        } else {
          setIsVerified(false);
        }
      } else {
        setIsVerified(false);
      }
      setIsChecking(false);
    }, (error) => {
      console.error('Error listening to Instagram verification:', error);
      setIsChecking(false);
      setIsVerified(false);
    });

    setUnsubscribe(() => listener);
  };

  const stopWatching = () => {
    if (unsubscribe) {
      unsubscribe();
      setUnsubscribe(null);
    }
    setCurrentHandle(null);
    setIsChecking(false);
    setIsVerified(false);
  };

  return (
    <InstagramVerificationContext.Provider 
      value={{ 
        isVerified, 
        isChecking,
        currentHandle,
        watchInstagramHandle,
        stopWatching
      }}
    >
      {children}
    </InstagramVerificationContext.Provider>
  );
}; 