import { Campaign } from '../../../../types';
import { getTimezone } from './timezoneUtils';
import { convertToLocalTime } from './timezoneUtils';

export const generateTimeOptionsForDateRange = (
  startDate: Date,
  endDate: Date | null,
  campaign?: Campaign,
  locationId?: string
): Map<string, { time: string; timezone: string; }[]> => {
  const timeOptions = new Map();
  if (!startDate || !campaign?.openHours) return timeOptions;

  const finalEndDate = endDate || new Date(startDate.getTime() + 90 * 24 * 60 * 60 * 1000);
  const timezone = getTimezone(locationId, campaign);
  const twentyFourHoursFromNow = new Date(Date.now() + 24 * 60 * 60 * 1000);

  let currentDate = new Date(startDate);
  while (currentDate <= finalEndDate) {
    const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
    const availableHours = campaign.openHours[dayOfWeek] || [];

    if (availableHours.length > 0) {
      const filteredAndSortedTimes = filterAndSortTimeOptions(
        availableHours,
        currentDate,
        timezone,
        twentyFourHoursFromNow
      );

      if (filteredAndSortedTimes.length > 0) {
        const midnightDate = new Date(currentDate);
        midnightDate.setHours(0, 0, 0, 0);
        timeOptions.set(midnightDate.toISOString(), filteredAndSortedTimes);
      }
    }
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  return timeOptions;
};

const filterAndSortTimeOptions = (
  availableHours: string[], 
  date: Date, 
  timezone: string, 
  twentyFourHoursFromNow: Date
): { time: string; timezone: string; }[] => {
  const filteredHours = availableHours.filter(time => {
    const { localTime } = convertToLocalTime(date, time, timezone);
    return localTime > twentyFourHoursFromNow;
  });

  const timeZoneAbbr = new Date().toLocaleTimeString('en-US', {
    timeZone: timezone,
    timeZoneName: 'short'
  }).split(' ').pop();

  return sortTimeOptions(filteredHours, timeZoneAbbr || timezone);
};

const sortTimeOptions = (hours: string[], timezone: string): { time: string; timezone: string; }[] => {
  return hours
    .sort((a, b) => {

      const [aTime, aModifier] = a.split(' ');
      const [bTime, bModifier] = b.split(' ');
      const [aHours, aMinutes] = aTime.split(':').map(Number);
      const [bHours, bMinutes] = bTime.split(':').map(Number);

      // Convert to minutes since midnight, handling 12 AM/PM specially
      const getMinutes = (hours: number, minutes: number, modifier: string) => {
        if (hours === 12) {
          return modifier === 'AM' ? minutes : (12 * 60) + minutes;
        }
        return ((modifier === 'PM' ? hours + 12 : hours) * 60) + minutes;
      };

      const aTotalMinutes = getMinutes(aHours, aMinutes, aModifier);
      const bTotalMinutes = getMinutes(bHours, bMinutes, bModifier);
      
      return aTotalMinutes - bTotalMinutes;
    })
    .map(time => ({
      time,
      timezone
    }));
}; 