// src/types.ts

import { Timestamp } from "firebase/firestore";

export interface Collab {
  id: string;
  credit: number;
  cash: number;
  deliverableCounts: {
    [key: string]: number;
  };
  deliverableLinks?: {
    [key: string]: DeliverableLink[];
  };
  status: CollabStatus;
  rejectionReason?: string;
  influencerID: string;
  influencerCampaignID: string;
  userConfirmRedemption?: string;
  collabReceivedTime?: Timestamp;
  collabAcceptedTime?: Timestamp;
  collabVerifiedTime?: Timestamp;
  collabRedeemedTime?: Timestamp;
  collabCounteredTime?: Timestamp;
  scheduledCollabDate?: Timestamp;
  paymentClaimed?: boolean;
  selectedLocation?: string;
  shippingAddress?: string;
  proposedInfluencerDeliverableCounts?: {
    [key: string]: number;
  };
  proposedInfluencerCash?: number;
  proposedInfluencerCredit?: number;
  counters?: Array<{
    reason: string | string[];
    timestamp: Timestamp;
    proposedDeliverables?: Record<string, number>;
    proposedCash?: number;
    proposedCredit?: number;
  }>;
  businessRejectedCounter?: boolean;
  partySize?: number;
  influencerNote?: string;
  customCollabType?: CampaignType;
  collabRequestedTime?: Timestamp;
  logs: CollabLog[];
  clientID?: string;
}

export type CollabStatus = 
  'pendingInfluencerApproval' | 
  'pendingClientApproval' |
  'influencerCountered' |
  'pendingSubmission' | 
  'pendingResubmission' | 
  'pendingVerification' | 
  'collabRejected' | 
  'collabCanceled' |
  'pendingRedemption' | 
  'collabRedeemed' | 
  'collabCompleted' |
  'collabExpired' |
  'clientRejected'

export interface Campaign {
  id: string;
  name: string;
  description: string;
  clientID: string;
  endDate: Timestamp;
  startDate: Timestamp;
  rewardType: string,
  expirationTime: number,
  collabImage: string
  openHours: { [key: string]: string[] };
  campaignType?: CampaignType;
  foodfluenceLocations?: string[];
  locations?: FoodfluenceLocation[];
  allowCounters?: boolean; 
  counters?: CollabCounter[];
  maxPartySize?: number;
  cash?: number;
  credit?: number;
  deliverableCounts?: {
    [key: string]: number;
  };
  isPublic?: boolean;
  maxCollabs?: number;
  additionalInfoFile?: string;
  additionalInfoLink?: string;
}

export interface Influencer {
  id: string;
  firstName: string;
  lastName: string;
  instagramHandle: string;
  tiktokHandle: string;
  instagramId: string;
  email_address: string;
  shippingAddress: string;
  phoneNumber: string;
  countryCode: string;
  follower_count: number;
  engagementRate: number;
  profile_pic_url: string;
  city?: string; //can remove eventually
  state?: string; //can remove eventually
  country: string;  // can remove eventually
  location: InfluencerLocation[];
  gender: string;
  pronouns: string[];
  ethnicity: string;
  birthday: string;
  tags: string[];
  collabPreference: string;
  rateRanges: RateRanges;
  bundlePricing?: Bundle[];
  mediaKit?: string;
}

export const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Non-binary', label: 'Non-binary' },
  { value: 'Other', label: 'Other' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const pronounOptions = [
  { value: 'She/her', label: 'She/her' },
  { value: 'He/him', label: 'He/him' },
  { value: 'They/them', label: 'They/them' },
  { value: 'Not Listed', label: 'Not Listed' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const ethnicityOptions = [
  { value: 'Asian', label: 'Asian' },
  { value: 'Black/African Descent', label: 'Black/African Descent' },
  { value: 'Hispanic/Latino', label: 'Hispanic/Latino' },
  { value: 'Middle Eastern/North African', label: 'Middle Eastern/North African' },
  { value: 'Indigenous/Native', label: 'Indigenous/Native' },
  { value: 'Pacific Islander', label: 'Pacific Islander' },
  { value: 'White/European Descent', label: 'White/European Descent' },
  { value: 'Mixed/Multi-Racial', label: 'Mixed/Multi-Racial' },
];

export const tagOptions = [
  { value: 'Alcohol', label: 'Alcohol' },
  { value: 'Athlete', label: 'Athlete' },
  { value: 'Beauty', label: 'Beauty' },
  { value: 'Boba', label: 'Boba' },
  { value: 'Budget Creator', label: 'Budget Creator' },
  { value: 'Business', label: 'Business' },
  { value: 'Chef & Recipes', label: 'Chef & Recipes' },
  { value: 'Coffee', label: 'Coffee' },
  { value: 'Comedy', label: 'Comedy' },
  { value: 'Country', label: 'Country' },
  { value: 'Dairy Free', label: 'Dairy Free' },
  { value: 'Decor', label: 'Decor' },
  { value: 'DIY', label: 'DIY' },
  { value: 'Education', label: 'Education' },
  { value: 'Family', label: 'Family' },
  { value: 'Fashion', label: 'Fashion' },
  { value: 'Fitness', label: 'Fitness' },
  { value: 'Food', label: 'Food' },
  { value: 'Gaming', label: 'Gaming' },
  { value: 'Gluten Free', label: 'Gluten Free' },
  { value: 'GRWM', label: 'GRWM' },
  { value: 'Halal', label: 'Halal' },
  { value: 'Health & Wellness', label: 'Health & Wellness' },
  { value: 'Home', label: 'Home' },
  { value: 'Kosher', label: 'Kosher' },
  { value: 'Lifestyle', label: 'Lifestyle' },
  { value: 'Matcha', label: 'Matcha' },
  { value: 'Model', label: 'Model' },
  { value: 'Pets', label: 'Pets' },
  { value: 'Plant Based', label: 'Plant Based' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Skin Care', label: 'Skin Care' },
  { value: 'SMU College Creator', label: 'SMU College Creator' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Student', label: 'Student' },
  { value: 'Tattoos', label: 'Tattoos' },
  { value: 'Technology', label: 'Technology' },
  { value: 'Theatre', label: 'Theatre' },
  { value: 'Travel', label: 'Travel' },
  { value: 'UGC Content Creator', label: 'UGC Content Creator' },
  { value: 'Unboxing', label: 'Unboxing' },
  { value: 'Vegan', label: 'Vegan' },
  { value: 'Vegetarian', label: 'Vegetarian' }
];

export const collabPreferenceOptions = [
  {value: 'all', label: 'Open to all collaboration offers'},
  {value: 'cashOnly', label: 'Only open to paid collaboration offers'},
  {value: 'cashPreferred', label: 'I mainly do paid collaborations, but feel free to send me gifting collaborations within my minimum gifting value range'}
];

export interface InfluencerProfileData {
  firstName: string;
  lastName: string;
  email_address: string;
  phoneNumber: string;
  instagramHandle: string;
  tiktokHandle: string;
  countryCode: string;
  location: InfluencerLocation[];
  gender: string;
  pronouns: string[];
  ethnicity: string;
  birthday: string;
  nonHiddenTags: string[];
  collabPreference: string;
  rateRanges: RateRanges;
  bundlePricing?: Bundle[];
  mediaKit?: string;
}

export type DeliverableLink = {
  userLink: string;
  verifiedUGCLink: string;
  status: string;
  rejectionReason?: string;
};

export interface Client {
  id: string;
  instagramHandle: string;
  tiktokHandle: string;
  instagramProfilePicture: string;
  accountName: string;
  email: string;
  campaignTypes: CampaignType[];
  foodfluenceLocations?: FoodfluenceLocation[];
  bannerPicture: string;
  adminID?: string;
  externallyAdded?: boolean;
}

export type CampaignType = 'shipToHome' | 'inStore';

export const campaignTypeDisplayNames: Record<CampaignType, string> = {
  'shipToHome': 'Ship to Home',
  'inStore': 'In Store'
};

export interface CollabLog {
  status: CollabStatus;
  timestamp: Timestamp;
}

export interface FoodfluenceLocation {
  docId: string;
  id: string;
  fullAddress: string;
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  lat: number;
  lng: number;
  timezone: string;
  emails: string[];
  clientID: string;
}

export const countryOptions = [
  { code: '+1', name: 'United States/Canada' },
  { code: '+44', name: 'United Kingdom' },
  { code: '+33', name: 'France' },
  { code: '+49', name: 'Germany' },
  { code: '+34', name: 'Spain' },
  { code: '+39', name: 'Italy' },
  { code: '+351', name: 'Portugal' },
  { code: '+353', name: 'Ireland' },
  { code: '+31', name: 'Netherlands' },
  { code: '+32', name: 'Belgium' },
  { code: '+41', name: 'Switzerland' },
  { code: '+46', name: 'Sweden' },
  { code: '+47', name: 'Norway' },
  { code: '+45', name: 'Denmark' },
  { code: '+358', name: 'Finland' },
  { code: '+48', name: 'Poland' },
  { code: '+43', name: 'Austria' },
  { code: '+420', name: 'Czech Republic' },
  { code: '+36', name: 'Hungary' },
  { code: '+30', name: 'Greece' },
  { code: '+7', name: 'Russia' },
  { code: '+81', name: 'Japan' },
  { code: '+82', name: 'South Korea' },
  { code: '+86', name: 'China' },
  { code: '+852', name: 'Hong Kong' },
  { code: '+65', name: 'Singapore' },
  { code: '+91', name: 'India' },
  { code: '+971', name: 'United Arab Emirates' },
  { code: '+966', name: 'Saudi Arabia' },
  { code: '+20', name: 'Egypt' },
  { code: '+27', name: 'South Africa' },
  { code: '+55', name: 'Brazil' },
  { code: '+52', name: 'Mexico' },
  { code: '+54', name: 'Argentina' },
  { code: '+56', name: 'Chile' },
  { code: '+57', name: 'Colombia' },
  { code: '+51', name: 'Peru' },
  { code: '+58', name: 'Venezuela' },
  { code: '+61', name: 'Australia' },
  { code: '+64', name: 'New Zealand' },
  { code: '+62', name: 'Indonesia' },
  { code: '+60', name: 'Malaysia' },
  { code: '+66', name: 'Thailand' },
  { code: '+84', name: 'Vietnam' },
  { code: '+63', name: 'Philippines' }
];

type Reason = {
  description: string;
  timestamp: Date; // or string, depending on how you handle timestamps
};

export interface CollabCounter {
  reason: string | string[];
  timestamp: Timestamp;
  proposedInfluencerDeliverableCounts?: Record<string, number>;
  proposedInfluencerCash?: number;
  proposedInfluencerCredit?: number;
}

export interface Conversation {
  id: string;
  messages_log: {
    [key: string]: Message;
  };
}

export interface PlaceDetails {
  lat: number;
  lng: number;
  fullAddress: string;
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  zip: string;
}

export interface Message {
  id: string;
  body: string;
  sender: string;
  time_sent: Timestamp;
  time_read?: Timestamp;
  file_url?: string;
  type: 'message' | 'file';
}

export interface Subscription {
  product_id: string;
  purchase_date: Timestamp;
  additionalCollabs?: number;
}

export interface SubscriptionHistory {
  [subscriptionId: string]: Subscription;
}

export interface ProductCatalogItem {
  collabs: number;
  additionalCollabCost: number;
}

export interface Bundle {
  deliverableCounts: {
    [key: string]: number;
  };
  cash: number;
}

export interface RateRanges {
  gifting: {
    minRate: number;
    maxRate: number;
  };
  instagramPosts: {
    offered: boolean;
    minRate: number;
    maxRate: number;
  };
  instagramReels: {
    offered: boolean;
    minRate: number;
    maxRate: number;
  };
  instagramStories: {
    offered: boolean;
    minRate: number;
    maxRate: number;
  };
  tiktoks: {
    offered: boolean;
    minRate: number;
    maxRate: number;
  };
  // ugc: {
  //   offered: boolean;
  //   minRate: number;
  //   maxRate: number;
  // };
}


export interface InfluencerLocation {
  lat: number;
  lng: number;
  city: string;
  state: string;
  country: string;
  maxCollabDistance: number;
}