import { Campaign } from '../../../../types';

interface LocationInfo {
  address: string;
  locationEmails: string[];
}

export const getFilteredLocations = (locations: Campaign['locations'], searchQuery: string) => {
  if (!locations) return [];
  return locations.filter(location => {
    return location.fullAddress.toLowerCase().includes(searchQuery.toLowerCase());
  });
};

export const shouldShowLocationSearch = (campaign?: Campaign): boolean => {
  return !!campaign?.locations && campaign.locations.length > 0;
}; 

export const getLocationInfo = (
  selectedLocation: { docId: string; fullAddress: string } | null,
  campaign?: Campaign
): LocationInfo => {
  let address = '';
  let locationEmails: string[] = [];

  if (selectedLocation && campaign?.locations) {
    const location = campaign.locations.find(loc => loc.docId === selectedLocation.docId);
    if (location) {
      address = `${location.streetNumber} ${location.streetName}, ${location.city}, ${location.state}`;
      locationEmails = location.emails || [];
    }
  }

  return { address, locationEmails };
};