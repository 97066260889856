import React from 'react';
import { Box, Text, HStack } from '@chakra-ui/react';
import { Campaign } from '../../../../types';
import { generatePartySizeOptions, getMaxPartySize } from '../utils/partySizeUtils';

interface PartySizeSelectorProps {
  campaign?: Campaign;
  selectedSize: number | null;
  onSizeSelect: (size: number) => void;
}

const PartySizeSelector: React.FC<PartySizeSelectorProps> = ({
  campaign,
  selectedSize,
  onSizeSelect,
}) => {
  const maxPartySize = getMaxPartySize(campaign);
  const partySizeOptions = generatePartySizeOptions(maxPartySize);

  return (
    <Box>
      <Text mb={2}>Party Size:</Text>
      <HStack spacing={2} wrap="wrap">
        {partySizeOptions.map((size) => (
          <Box
            key={size}
            width="40px"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            border="1px solid"
            borderColor={selectedSize === size ? "blue.500" : "gray.300"}
            bg={selectedSize === size ? "blue.100" : "white"}
            cursor="pointer"
            onClick={() => onSizeSelect(size)}
          >
            {size}
          </Box>
        ))}
      </HStack>
    </Box>
  );
};

export default PartySizeSelector; 