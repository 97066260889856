import React, { createContext, useState, useEffect, useContext, ReactNode } from "react";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import { Client, FoodfluenceLocation } from "../types";
import { CollabDataContext } from "./collabDataContext";
import { InfluencerCampaignDataContext } from "./InfluencerCampaignDataContext";

interface ClientDataContextType {
    [clientId: string]: Client;
}

export const ClientDataContext = createContext<ClientDataContextType>({});

interface ClientDataProviderProps {
    children: ReactNode;
}

export const ClientDataProvider: React.FC<ClientDataProviderProps> = ({ children }) => {
    const collabData = useContext(CollabDataContext);
    const campaignData = useContext(InfluencerCampaignDataContext);
    const [clientData, setClientData] = useState<ClientDataContextType>({});

    useEffect(() => {
        const fetchClientData = async () => {
            const clientIds = new Set<string>();
            
            // Get client IDs from collabs and campaigns
            collabData.forEach(collab => {
                if (collab.clientID) {
                    clientIds.add(collab.clientID);
                }
                const campaign = campaignData[collab.id];
                if (campaign?.clientID) {
                    clientIds.add(campaign.clientID);
                }
            });

            const clientPromises = Array.from(clientIds).map(async (clientId) => {
                const clientDocRef = doc(firestore, "clients", clientId);
                const clientDocSnap = await getDoc(clientDocRef);
                
                if (clientDocSnap.exists()) {
                    const locationsRef = collection(firestore, "foodfluenceLocations");
                    const locationsQuery = query(locationsRef, where("clientID", "==", clientId));
                    const locationsSnapshot = await getDocs(locationsQuery);
                    
                    const locations = locationsSnapshot.docs.map(doc => ({
                        docId: doc.id,
                        ...doc.data()
                    } as FoodfluenceLocation));

                    return {
                        [clientId]: {
                            id: clientId,
                            ...clientDocSnap.data(),
                            foodfluenceLocations: locations
                        } as Client
                    };
                }
                return null;
            });

            const clientResults = await Promise.all(clientPromises);
            const clients = clientResults.reduce((acc, result) => {
                if (result) {
                    return { ...acc, ...result };
                }
                return acc;
            }, {});

            setClientData(clients);
        };

        fetchClientData();
    }, [collabData, campaignData]);

    return (
        <ClientDataContext.Provider value={clientData}>
            {children}
        </ClientDataContext.Provider>
    );
}; 