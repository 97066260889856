import React, { useContext } from 'react';
import { Box, Text, VStack, Heading, Flex, Avatar } from '@chakra-ui/react';
import { CollabDataContext } from '../../../contexts/collabDataContext';
import { InfluencerCampaignDataContext } from '../../../contexts/InfluencerCampaignDataContext';
import { Collab } from '../../../types';
import { ClientDataContext } from 'contexts/ClientDataContext';

const PastCollabsList: React.FC = () => {
  const collabData = useContext(CollabDataContext);
  const campaignData = useContext(InfluencerCampaignDataContext);
  const clients = useContext(ClientDataContext);
  const pastCollabs = collabData.filter((collab: Collab) => {
    const campaign = campaignData[collab.id];
    return ['collabCompleted'].includes(collab.status);
  });

  const formatDate = (date: any) => {
    if (date && date.toDate instanceof Function) {
      return date.toDate();
    }
    return null;
  };

  // Group by day and calculate daily totals
  const groupedCollabs = pastCollabs.reduce((acc, collab) => {
    const dateObj = formatDate(collab.collabRedeemedTime);
    const date = dateObj ? dateObj.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }) : 'No Date';
    if (!acc[date]) {
      acc[date] = {
        collabs: [],
        dailyTotal: 0,
        claimedTotal: 0
      };
    }
    acc[date].collabs.push(collab);
    acc[date].dailyTotal += collab.cash || 0;
    if (collab.paymentClaimed) {
      acc[date].claimedTotal += collab.cash || 0;
    }
    return acc;
  }, {} as Record<string, { collabs: Collab[], dailyTotal: number, claimedTotal: number }>);

  return (
    <Box p={4}>
      <VStack spacing={6} align="stretch">
        <Heading size="lg">Past Collaborations</Heading>
        {Object.keys(groupedCollabs).length === 0 ? (
          <Text fontSize="lg" color="gray.500">You have not completed any collabs.</Text>
        ) : (
          Object.entries(groupedCollabs)
            .sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime())
            .map(([date, { collabs, dailyTotal, claimedTotal }]) => (
            <Box key={date} mb={6}>
            <Flex justify="space-between" align="center" mb={2}>
              <Text fontSize="sm" fontWeight="bold" color="gray.500">{date}</Text>
              <VStack align="flex-end" spacing={1}>
                <Text fontSize="lg" fontWeight="semibold" color="green.500">
                  ${dailyTotal.toFixed(2)}
                </Text>
                {claimedTotal > 0 && (
                  <Text fontSize="xs" color="gray.500">
                    ${claimedTotal.toFixed(2)} claimed
                  </Text>
                )}
              </VStack>
            </Flex>
            <VStack spacing={4}>
              {collabs.map(collab => {
                const campaign = campaignData[collab.id];
                const client = clients[campaign?.clientID];
                return (
                  <Box 
                    key={collab.id} 
                    py={4} 
                    bg="white" 
                    width="100%"
                  >
                    <Flex justify="space-between" align="center">
                      <Flex align="center" gap={3} width="50%">
                        <Avatar 
                          size="md" 
                          src={client?.instagramProfilePicture} 
                          name={client?.accountName}
                        />
                        <Text fontWeight="semibold">{client?.accountName}</Text>
                      </Flex>
                      <Box textAlign="right" width="50%">
                        <Flex direction="column" align="flex-end">
                          <Text 
                            fontWeight="bold" 
                            color={collab.cash > 0 ? 'green.500' : 'black'}
                          >
                            +${collab.cash}
                          </Text>
                          {collab.paymentClaimed && (
                            <Text fontSize="xs" color="gray.500">
                              Claimed
                            </Text>
                          )}
                          {collab.credit > 0 && (
                            <Text fontSize="sm" color="gray.500">
                              Dining Credit: ${collab.credit}
                            </Text>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                );
              })}
            </VStack>
            </Box>
          ))
        )}
      </VStack>
    </Box>
  );
};

export default PastCollabsList;
