import { apiRequest } from './apiUtils';
import { CreateCollabRequestDto, UpdateCollabRequestDto, CollabResponseDto } from '@foodfluence/shared';

export const collabApi = {
  createCollabRequest: async (params: CreateCollabRequestDto): Promise<CollabResponseDto> => {
    return apiRequest({
      method: 'POST',
      endpoint: '/collabs/request',
      data: params
    });
  },

  updateCollabRequest: async (
    params: UpdateCollabRequestDto
  ): Promise<void> => {
    await apiRequest({
      method: 'PUT',
      endpoint: `/collabs/request/${params.collabId}`,
      data: params
    });
  }
}; 