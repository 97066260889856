import React, { createContext, useState, useEffect, useContext } from "react";
import { onSnapshot, doc } from "firebase/firestore";
import { firestore } from "../firebase";
import { CollabDataContext } from "./collabDataContext";
export const ConversationsContext = createContext<Record<string, any>>({});

export const ConversationsProvider = ({ children }) => {
    const collabs = useContext(CollabDataContext);
    const [conversations, setConversations] = useState<Record<string, any>>({});

  useEffect(() => {
    if (!collabs || Object.keys(collabs).length === 0) {
      setConversations({});
      return;
    }

    const unsubscribes = [];
    const uniqueConversationIds = new Set(collabs.map(collab => collab.id));

    uniqueConversationIds.forEach(conversationId => {
      const conversationDocRef = doc(firestore, "conversations", conversationId);
      const unsubscribe = onSnapshot(conversationDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setConversations(prev => ({
            ...prev,
            [conversationId]: { id: conversationId, ...data }
          }));
        }
      });

      unsubscribes.push(unsubscribe);
    });

    // Cleanup the listeners when the component is unmounted
    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [collabs]);

  return (
    <ConversationsContext.Provider value={conversations ?? {}}>
      {children}
    </ConversationsContext.Provider>
  );
};

export default ConversationsProvider; 