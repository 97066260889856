import React, { useState, useContext } from 'react';
import { 
  Box, 
  Image, 
  Text, 
  VStack, 
  Flex,
  Icon,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Campaign, Client, Collab } from '@foodfluence/shared';
import { useRandomImage } from '../../../utils/imageUtils';
import deliverableNames from 'utils/deliverableNames';
import SignInSignUpModal from './SignInSignUpModal';
import { auth } from '../../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { formatFirebaseDate } from '../../../utils/dateUtils';
import { FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import { getLocationDisplay } from 'utils/locationUtils';
import { WhiteLabelContext } from '../../../contexts/WhiteLabelContext';

interface CampaignComponentProps {
  campaign: Campaign;
  client: Client;
  requestedCollab?: Collab;
  isExplorePage?: boolean;
  locationFilter?: { lat: number; lng: number };
}

const CampaignComponent: React.FC<CampaignComponentProps> = ({ campaign, client, requestedCollab, isExplorePage, locationFilter }) => {
  const navigate = useNavigate();
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const { getRandomImage, randomImage } = useRandomImage();
  const [user] = useAuthState(auth);
  const { isWhiteLabeled } = useContext(WhiteLabelContext);

  const handleCampaignClick = async () => {
    if (!user) {
      setIsSignInModalOpen(true);
      return;
    }

    const navigationPath = isWhiteLabeled ? "/request-collab" : `/explore/client/${client.id}/request-collab`;
    
    navigate(navigationPath, { 
      state: { 
        campaign: campaign,
        client: client,
        requestedCollab: requestedCollab
      }
    });
  };

  return (
    <Box 
      as="span" 
      display="inline-block" 
      mr={3}
      onClick={handleCampaignClick}
      cursor="pointer"
      transition="all 0.2s"
      _hover={{ transform: 'translateY(-4px)' }}
    >
      <Box
        borderRadius="2xl"
        overflow="hidden"
        boxShadow="lg"
        width="220px"
        height="240px"
        position="relative"
        _hover={{ 
          boxShadow: "2xl",
        }}
      >
        {/* Full-size background image */}
        <Image 
          src={campaign?.collabImage} 
          height="100%"
          width="100%"
          objectFit="cover"
          fallbackSrc={getRandomImage()}
        />

        {/* Gradient overlay */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5))"
        />

        {/* Content overlays */}
        {/* Top section */}
        <VStack
          position="absolute"
          top={0}
          left={0}
          right={0}
          p={2}
          align="stretch"
          spacing={1}
          color="white"
        >
          {/* Campaign status tag */}
          {(requestedCollab || campaign?.endDate) && (
            <Flex 
              alignSelf="flex-end"
              bg="rgba(0,0,0,0.5)" 
              color="white" 
              px={2} 
              py={1} 
              borderRadius="full" 
              align="center"
              gap={1}
              backdropFilter="blur(8px)"
            >
              <Icon as={FaClock} boxSize={2} />
              <Text fontSize="xs" fontWeight="medium">
                {requestedCollab 
                  ? `Sent ${formatFirebaseDate(requestedCollab.collabRequestedTime)}`
                  : `Ends: ${formatFirebaseDate(campaign.endDate)}`
                }
              </Text>
            </Flex>
          )}
        </VStack>

        {/* Bottom section */}
        <VStack
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          p={3}
          align="stretch"
          spacing={2}
          color="white"
          background="linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 70%, transparent 100%)"
        >

          {/* Compensation */}
          <Flex justify="flex-start" gap={2}>
            {(requestedCollab?.proposedInfluencerCash || campaign?.cash) > 0 && (
              <Flex
                bg="rgba(0,0,0,0.3)"
                backdropFilter="blur(8px)"
                px={3}
                py={1} 
                borderRadius="full"
                align="center"
                gap={1}
              >
                <Text fontSize="sm" fontWeight="bold">💵</Text>
                <Text fontWeight="semibold" fontSize="sm">
                  ${requestedCollab?.proposedInfluencerCash || campaign?.cash}
                </Text>
              </Flex>
            )}
            {(requestedCollab?.proposedInfluencerCredit || campaign?.credit) > 0 && (
              <Flex
                bg="rgba(0,0,0,0.3)"
                backdropFilter="blur(8px)"
                px={3}
                py={1} 
                borderRadius="full"
                align="center"
                gap={1}
              >
                <Text fontSize="sm" fontWeight="bold">🎁</Text>
                <Text fontWeight="semibold" fontSize="sm">
                  ${requestedCollab?.proposedInfluencerCredit || campaign?.credit}
                </Text>
              </Flex>
            )}
          </Flex>
          
          {/* Campaign name */}
          <Text 
            fontSize="lg"
            fontWeight="bold"
            noOfLines={2}
            textShadow="0px 2px 4px rgba(0,0,0,0.3)"
            whiteSpace="normal"
            wordBreak="break-word"
            width="100%"
          >
            {isExplorePage ? client.accountName : campaign?.name || "Custom Collab"}
          </Text>

          {/* Campaign name (only shown on explore page) */}
          {isExplorePage && (
            <VStack spacing={0.5} align="stretch">
              <Text fontSize="xs" fontWeight="semibold" opacity={0.9}>
                {campaign.name}
              </Text>
            </VStack>
          )}

          {/* Deliverables */}
          <Text 
            fontSize="xs" 
            opacity={0.9}
            noOfLines={1}
            lineHeight="1.2"
            whiteSpace="normal"
            wordBreak="break-word"
          >
            {Object.entries(requestedCollab?.proposedInfluencerDeliverableCounts || campaign?.deliverableCounts || {})
              .filter(([_, count]) => count > 0)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([type, count]) => `${count}x ${deliverableNames[type]?.short}`)
              .join(' · ')}
          </Text>

          {/* Location */}
          <Flex align="center" gap={1}>
            {campaign?.locations && campaign.locations.length > 0 ? (
              <>
                <Icon as={FaMapMarkerAlt} boxSize={3} />
                <Text fontSize="xs" noOfLines={1} opacity={0.8}>
                  {getLocationDisplay(campaign.locations, locationFilter)}
                </Text>
              </>
            ) : (
              <Text fontSize="xs" noOfLines={1} opacity={0.8}>
                &nbsp;
              </Text>
            )}
          </Flex>
        </VStack>
      </Box>

      <SignInSignUpModal 
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
        returnTo={isWhiteLabeled ? "/request-collab" : `/explore/client/${client.id}/request-collab`}
        returnState={{
          campaign: campaign,
          client: client,
          requestedCollab: requestedCollab
        }}
      />
    </Box>
  );
};

export default CampaignComponent;
