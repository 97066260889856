import { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  VStack,
  Flex,
  IconButton,
  Text,
  Divider,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import AddBundleModal from './EditProfileComponents/AddBundleModal';
import { InfluencerDataContext } from '../../../contexts/InfluencerDataContext';
import { useToast } from '@chakra-ui/react';
import { Bundle, InfluencerLocation } from 'types';
import PersonalInformation from './EditProfileComponents/PersonalInformation';
import SocialProfiles from './EditProfileComponents/SocialProfiles';
import ContentInterests from './EditProfileComponents/ContentInterests';
import CollaborationPreferences from './EditProfileComponents/CollaborationPreferences';
import RatesPricing from './EditProfileComponents/RatesPricing';
import BundlePricing from './EditProfileComponents/BundlePricing';
import { getNonHiddenTags, hasFormChanged, isSubmitEnabled, parsePhone } from '../utils';
import { updateInfluencerProfile } from '../../../services/firebaseService';
import CancelChangesModal from './EditProfileComponents/CancelChangesModal';
import MediaKit from './EditProfileComponents/MediaKit';
import Locations from './EditProfileComponents/Locations';
import { formatPhoneNumber } from '../../../utils/authUtils';
import { getHiddenTags } from '../utils';

const ProfileEditPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { influencerData } = useContext(InfluencerDataContext);

  const RequiredStar = () => <Text as="span" color="red.500" ml={1}>*</Text>

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email_address, setEmail_address] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [birthday, setBirthday] = useState<string>('');
  const [ethnicity, setEthnicity] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [pronouns, setPronouns] = useState<MultiValue<{ value: string; label: string }>>([]);
  const [instagramHandle, setInstagramHandle] = useState<string>('');
  const [tiktokHandle, setTiktokHandle] = useState<string>('');
  const [tags, setTags] = useState<MultiValue<{ value: string; label: string }>>([]);
  const [collabPreference, setCollabPreference] = useState<string>('Open to all collaboration offers');
  const [hiddenTags, setHiddenTags] = useState<string[]>([]);

  const [rateRanges, setRateRanges] = useState({
    gifting: { 
      minRate: undefined, 
      maxRate: undefined 
    },
    instagramPosts: { 
      offered: influencerData?.rateRanges?.instagramPosts?.offered ?? !!influencerData?.instagramHandle,
      minRate: undefined, 
      maxRate: undefined 
    },
    instagramReels: { 
      offered: influencerData?.rateRanges?.instagramReels?.offered ?? !!influencerData?.instagramHandle,
      minRate: undefined, 
      maxRate: undefined 
    },
    instagramStories: { 
      offered: influencerData?.rateRanges?.instagramStories?.offered ?? !!influencerData?.instagramHandle,
      minRate: undefined, 
      maxRate: undefined 
    },
    tiktoks: { 
      offered: influencerData?.rateRanges?.tiktoks?.offered ?? !!influencerData?.tiktokHandle,
      minRate: undefined, 
      maxRate: undefined 
    },
    // ugc: { 
    //   offered: false,
    //   minRate: 50, 
    //   maxRate: 250 
    // },
  });

  const [bundlePricing, setBundlePricing] = useState<Bundle[]>([]);
  const [isAddBundleModalOpen, setIsAddBundleModalOpen] = useState<boolean>(false);
  const [mediaKit, setMediaKit] = useState<string>('');
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const defaultLocation: InfluencerLocation = {
    lat: 0,
    lng: 0,
    city: '',
    state: '',
    country: '',
    maxCollabDistance: 25,
  };

  const [location, setLocation] = useState<InfluencerLocation[]>([defaultLocation]);

  useEffect(() => {
    if (influencerData) {
      const initialPhone = parsePhone(influencerData?.phoneNumber || '');
      setFirstName((!influencerData?.firstName || influencerData?.firstName.toLowerCase() === 'unknown') ? '' : influencerData?.firstName);
      setLastName((!influencerData?.lastName || influencerData?.lastName.toLowerCase() === 'unknown') ? '' : influencerData?.lastName);
      setEmail_address(influencerData?.email_address || '');
      setPhoneNumber(initialPhone.nationalNumber);
      setCountryCode(initialPhone.countryCode);
      setBirthday(influencerData?.birthday || '');
      setEthnicity(influencerData?.ethnicity || '');
      setGender(influencerData?.gender || '');
      setPronouns((influencerData?.pronouns || []).map(p => ({ value: p, label: p })));
      setInstagramHandle(influencerData?.instagramHandle || '');
      setTiktokHandle(influencerData?.tiktokHandle || '');
      setTags(getNonHiddenTags(influencerData?.tags || []).map(t => ({ value: t, label: t })));
      setHiddenTags(getHiddenTags(influencerData?.tags || []));
      setCollabPreference(influencerData?.collabPreference || 'Open to all collaboration offers');
      setRateRanges({
        gifting: {
          minRate: influencerData?.rateRanges?.gifting?.minRate || 0,
          maxRate: influencerData?.rateRanges?.gifting?.maxRate || 0,
        },
        instagramReels: {
          offered: influencerData?.rateRanges?.instagramReels?.offered ?? !!influencerData?.instagramHandle,
          minRate: influencerData?.rateRanges?.instagramReels?.minRate || 0,
          maxRate: influencerData?.rateRanges?.instagramReels?.maxRate || 0,
        },
        instagramPosts: {
          offered: influencerData?.rateRanges?.instagramPosts?.offered ?? !!influencerData?.instagramHandle,
          minRate: influencerData?.rateRanges?.instagramPosts?.minRate || 0,
          maxRate: influencerData?.rateRanges?.instagramPosts?.maxRate || 0,
        },
        instagramStories: {
          offered: influencerData?.rateRanges?.instagramStories?.offered ?? !!influencerData?.instagramHandle,
          minRate: influencerData?.rateRanges?.instagramStories?.minRate || 0,
          maxRate: influencerData?.rateRanges?.instagramStories?.maxRate || 0,
        },
        tiktoks: {
          offered: influencerData?.rateRanges?.tiktoks?.offered ?? !!influencerData?.tiktokHandle,
          minRate: influencerData?.rateRanges?.tiktoks?.minRate || 0,
          maxRate: influencerData?.rateRanges?.tiktoks?.maxRate || 0,
        },
        // ugc: {
        //   offered: false,
        //   minRate: influencerData.rateRanges?.ugc?.minRate || 50,
        //   maxRate: influencerData.rateRanges?.ugc?.maxRate || 250,  
        // },
      });
      setBundlePricing(influencerData?.bundlePricing || []);
      setMediaKit(influencerData?.mediaKit || '');
      setLocation(influencerData?.location?.length ? influencerData?.location : [defaultLocation]);
    }
  }, [influencerData]);

  const handleBackClick = () => {
    if (hasFormChanged(influencerData, {
      firstName,
      lastName,
      email_address,
      instagramHandle,
      tiktokHandle,
      countryCode,
      phoneNumber,
      birthday,
      ethnicity,
      gender,
      pronouns: pronouns.map((p) => p.value),
      location,
      nonHiddenTags: tags.map((t) => t.value),
      collabPreference,
      rateRanges,
      bundlePricing,
      mediaKit,
    })) {
      setIsCancelModalOpen(true);
    } else {
      navigate(-1);
    }
  };

  const handleCancelConfirm = () => {
    setIsCancelModalOpen(false);
    navigate(-1);
  };

  const handleNewBundle = (newBundle: Bundle) => {
    setBundlePricing((prevBundles) => {
      const updatedBundles = [...prevBundles, newBundle];
      return updatedBundles;
    });
  };
  
  const handleDeleteBundle = (index: number) => {
    try {
      setBundlePricing((prevBundles) => prevBundles.filter((_, i) => i !== index));
      toast({
        title: "Bundle deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting bundle:', error);
      toast({
        title: "Error deleting bundle",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRateChange = (platform, type, value) => {
    setRateRanges((prev) => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        [type]: value,
      },
    }));
  };

  const checkSubmit = () => {
    return isSubmitEnabled(influencerData, {
      firstName,
      lastName,
      email_address,
      instagramHandle,
      tiktokHandle,
      countryCode,
      phoneNumber,
      birthday,
      ethnicity,
      gender,
      pronouns: pronouns.map((p) => p.value),
      location,
      nonHiddenTags: tags.map((t) => t.value),
      collabPreference,
      rateRanges,
      bundlePricing,
      mediaKit,
    });
  };

  const setNullRanges = () => {
    // First, create a complete copy of the current rateRanges
    const currentRateRanges = { ...rateRanges };

    // Ensure gifting has valid values
    if (!currentRateRanges.gifting || currentRateRanges.gifting.minRate === undefined || currentRateRanges.gifting.maxRate === undefined) {
      currentRateRanges.gifting = {
        ...(currentRateRanges.gifting || {}),
        minRate: 0,
        maxRate: 0
      };
    }

    // Ensure Instagram Posts has valid values
    if (!currentRateRanges.instagramPosts || currentRateRanges.instagramPosts.minRate === undefined || currentRateRanges.instagramPosts.maxRate === undefined) {
      currentRateRanges.instagramPosts = {
        ...(currentRateRanges.instagramPosts || {}),
        offered: currentRateRanges.instagramPosts?.offered || false,
        minRate: 0,
        maxRate: 0
      };
    }

    // Ensure Instagram Reels has valid values
    if (!currentRateRanges.instagramReels || currentRateRanges.instagramReels.minRate === undefined || currentRateRanges.instagramReels.maxRate === undefined) {
      currentRateRanges.instagramReels = {
        ...(currentRateRanges.instagramReels || {}),
        offered: currentRateRanges.instagramReels?.offered || false,
        minRate: 0,
        maxRate: 0
      };
    }

    // Ensure Instagram Stories has valid values
    if (!currentRateRanges.instagramStories || currentRateRanges.instagramStories.minRate === undefined || currentRateRanges.instagramStories.maxRate === undefined) {
      currentRateRanges.instagramStories = {
        ...(currentRateRanges.instagramStories || {}),
        offered: currentRateRanges.instagramStories?.offered || false,
        minRate: 0,
        maxRate: 0
      };
    }

    // Ensure TikToks has valid values
    if (!currentRateRanges.tiktoks || currentRateRanges.tiktoks.minRate === undefined || currentRateRanges.tiktoks.maxRate === undefined) {
      currentRateRanges.tiktoks = {
        ...(currentRateRanges.tiktoks || {}),
        offered: currentRateRanges.tiktoks?.offered || false,
        minRate: 0,
        maxRate: 0
      };
    }

    // Convert any remaining undefined values to 0
    Object.keys(currentRateRanges).forEach(key => {
      if (currentRateRanges[key]) {
        if (currentRateRanges[key].minRate === undefined) currentRateRanges[key].minRate = 0;
        if (currentRateRanges[key].maxRate === undefined) currentRateRanges[key].maxRate = 0;
        if (key != 'gifting' && currentRateRanges[key].offered === undefined) currentRateRanges[key].offered = false;
      }
    });

    return currentRateRanges;
  };

  const handleSubmit = async () => {
    try {
      const sanitizedPhoneNumber = phoneNumber.replace(/[-()\s]/g, '');
      const fullPhoneNumber = formatPhoneNumber(sanitizedPhoneNumber, countryCode);
      const currentRateRanges = setNullRanges();
      await updateInfluencerProfile(influencerData?.id, {
        firstName,
        lastName,
        email_address,
        phoneNumber: fullPhoneNumber,
        birthday,
        ethnicity,
        gender,
        pronouns: pronouns.map((p) => p.value),
        location, 
        tags: [...tags.map((t) => t.value), ...hiddenTags],
        collabPreference,
        rateRanges: currentRateRanges,
        bundlePricing,
        mediaKit,
      });
      toast({
        title: "Profile updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(-1);
    } catch (error) {
      console.error('Error updating influencer profile:', error);
      toast({
        title: "Error updating profile",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <Box w="100%" maxW="100%" mx="auto" p={6}>
      <Flex justifyContent="space-between" alignItems="center" mb="12px" position="relative" top="-10px">
        <IconButton
          icon={<ArrowBackIcon />}
          size="lg"
          aria-label="Go Back"
          variant="ghost"
          borderRadius="full"
          onClick={handleBackClick}
        />
        <Text fontWeight="bold" fontSize="2xl" position="absolute" left="50%" transform="translateX(-50%)">
          Edit Profile
        </Text>
        <Flex>
        </Flex>
      </Flex>
      <VStack spacing={4} align="stretch">
        <PersonalInformation
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email_address={email_address}
          setEmail_address={setEmail_address}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          gender={gender}
          setGender={setGender}
          pronouns={pronouns}
          setPronouns={setPronouns}
          birthday={birthday}
          setBirthday={setBirthday}
          ethnicity={ethnicity}
          setEthnicity={setEthnicity}
          RequiredStar={RequiredStar}
        />
        <Divider />
        <Locations
          locations={location}
          setLocations={setLocation}
          RequiredStar={RequiredStar}
        />
        <Divider />
        <SocialProfiles
          instagramHandle={instagramHandle}
          tiktokHandle={tiktokHandle}
          rateRanges={rateRanges}
          setRateRanges={setRateRanges}
          RequiredStar={RequiredStar}
        />
        <Divider />
        <ContentInterests
          tags={tags}
          setTags={setTags}
          RequiredStar={RequiredStar}
        />
        <Divider />
        <CollaborationPreferences
          collabPreference={collabPreference}
          setCollabPreference={setCollabPreference}
          RequiredStar={RequiredStar}
        />
        <Divider />
        <RatesPricing
          rateRanges={rateRanges}
          handleRateChange={handleRateChange}
          collabPreference={collabPreference}
          RequiredStar={RequiredStar}
        />
        <Divider />
        <BundlePricing
          bundlePricing={bundlePricing}
          handleDeleteBundle={handleDeleteBundle}
          setIsAddBundleModalOpen={setIsAddBundleModalOpen}
        />
        <Divider />
        <MediaKit
          mediaKit={mediaKit}
          setMediaKit={setMediaKit}
        />
        <Button
          bg={checkSubmit() ? "black" : "gray.300"}
          color={checkSubmit() ? "white" : "gray.500"}
          _hover={{ bg: checkSubmit() ? "gray.700" : "gray.300" }}
          mt={4}
          isDisabled={!checkSubmit()}
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </VStack>

      {isAddBundleModalOpen && (
        <AddBundleModal
          isOpen={isAddBundleModalOpen}
          onClose={() => setIsAddBundleModalOpen(false)}
          onBundleCreate={handleNewBundle}
        />
      )}

      {isCancelModalOpen && (
        <CancelChangesModal
          isOpen={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onConfirm={handleCancelConfirm}
        />
      )}
    </Box>
  );
};

export default ProfileEditPage;
