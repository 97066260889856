import React from 'react';
import { Box, Grid, GridItem, Text, Divider } from '@chakra-ui/react';
import { RateRanges } from 'types';

interface RatesForProfilePageProps {
  rateRanges: RateRanges;
}

const RatesForProfilePage: React.FC<RatesForProfilePageProps> = ({ rateRanges }) => {
  const doesIGContent = rateRanges?.instagramPosts?.offered && rateRanges?.instagramReels?.offered && rateRanges?.instagramStories?.offered;
  const doesTiktokContent = rateRanges?.tiktoks?.offered;
  return (
    <Box>
      <Text fontWeight="bold">Rates</Text>
      {!doesIGContent && !doesTiktokContent && (
        <Text color="gray.500" mt={1}>Update your profile to set them.</Text>
      )}
      {doesIGContent && doesTiktokContent && (
        <Grid templateColumns="repeat(2, 1fr)" gap={4} w="100%">
          <GridItem mr={4}>
            <Text>IG Reel</Text>
            {rateRanges?.instagramReels?.maxRate !== undefined ? (
              <Text>${rateRanges.instagramReels.minRate} - ${rateRanges.instagramReels.maxRate} USD</Text>
            ) : (
              <Text color="gray.500" mt={1}>N/A</Text>
            )}
            <Divider borderColor="gray.300" my={0.5} />
            <Text>IG Post</Text>
            {rateRanges?.instagramPosts?.maxRate !== undefined ? (
              <Text>${rateRanges.instagramPosts.minRate} - ${rateRanges.instagramPosts.maxRate} USD</Text>
            ) : (
              <Text color="gray.500" mt={1}>N/A</Text>
            )}
            <Divider borderColor="gray.300" my={0.5} />
            <Text>IG Story Frame</Text>
            {rateRanges?.instagramStories?.maxRate !== undefined ? (
              <Text>${rateRanges.instagramStories.minRate} - ${rateRanges.instagramStories.maxRate} USD</Text>
            ) : (
              <Text color="gray.500" mt={1}>N/A</Text>
            )}
          </GridItem>
          <GridItem>
            <Text>TikTok Video</Text>
            {rateRanges?.tiktoks?.maxRate !== undefined ? (
              <Text>${rateRanges.tiktoks.minRate} - ${rateRanges.tiktoks.maxRate} USD</Text>
            ) : (
              <Text color="gray.500" mt={1}>N/A</Text>
            )}
          </GridItem>
        </Grid>
      )}
      {doesTiktokContent && !doesIGContent && (
        <Grid templateColumns="repeat(2, 1fr)" gap={4} w="100%">
          <GridItem>
            <Text>TikTok Video</Text>
            {rateRanges?.tiktoks?.maxRate !== undefined ? (
              <Text>${rateRanges.tiktoks.minRate} - ${rateRanges.tiktoks.maxRate} USD</Text>
            ) : (
              <Text color="gray.500" mt={1}>N/A</Text>
            )}
          </GridItem>
        </Grid>
      )}
      {doesIGContent && !doesTiktokContent && (
        <Grid templateColumns="repeat(2, 1fr)" gap={4} w="100%">
          <GridItem mr={4}>
            <Text>IG Reel</Text>
            {rateRanges?.instagramReels?.maxRate !== undefined ? (
              <Text>${rateRanges.instagramReels.minRate} - ${rateRanges.instagramReels.maxRate} USD</Text>
            ) : (
              <Text color="gray.500" mt={1}>N/A</Text>
            )}
            <Divider borderColor="gray.300" my={0.5} />
            <Text>IG Post</Text>
            {rateRanges?.instagramPosts?.maxRate !== undefined ? (
              <Text>${rateRanges.instagramPosts.minRate} - ${rateRanges.instagramPosts.maxRate} USD</Text>
            ) : (
              <Text color="gray.500" mt={1}>N/A</Text>
            )}
            <Divider borderColor="gray.300" my={0.5} />
            <Text>IG Story Frame</Text>
            {rateRanges?.instagramStories?.maxRate !== undefined ? (
              <Text>${rateRanges.instagramStories.minRate} - ${rateRanges.instagramStories.maxRate} USD</Text>
            ) : (
              <Text color="gray.500" mt={1}>N/A</Text>
            )}
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};

export default RatesForProfilePage;
