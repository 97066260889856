import { useState } from 'react';

export const useRandomImage = () => {
  const [randomImage, setRandomImage] = useState<string | null>(null);

  function importAll(r: any) {
    return r.keys().map(r);
  }
  
  const images = importAll((require as any).context('../assets/default_collab_images', false, /\.(png|jpe?g|svg)$/));
  
  const getRandomImage = () => {
    if (!randomImage) {
      const randomIndex = Math.floor(Math.random() * images.length);
      setRandomImage(images[randomIndex]);
      return images[randomIndex];
    }
    return randomImage;
  };

  return { getRandomImage, randomImage };
}; 