import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

interface CancelChangesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CancelChangesModal: React.FC<CancelChangesModalProps> = ({ isOpen, onClose, onConfirm }) => {
  const modalBg = useColorModeValue('white', 'gray.800');
  const headerColor = useColorModeValue('gray.700', 'gray.200');
  const bodyColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width="80%" bg={modalBg} borderRadius="md" boxShadow="lg">
        <ModalHeader color={headerColor}>Cancel Changes</ModalHeader>
        <ModalBody>
          <Text color={bodyColor}>Are you sure? All changes will be discarded.</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={onClose} mr={3}>
            No
          </Button>
          <Button colorScheme="red" onClick={onConfirm}>
            Yes, Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancelChangesModal;
