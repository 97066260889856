import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalCloseButton,
  Input,
  VStack,
  List,
  ListItem,
  Text,
  Box,
  Icon,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { FaMapPin } from 'react-icons/fa';
import { getPlaceSuggestions, getPlaceDetails } from '../../../services/flaskService';
import debounce from 'lodash/debounce';
import { DeleteIcon } from '@chakra-ui/icons';
import { PlaceDetails } from '../../../types';

interface LocationSearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLocationSelect: (location: PlaceDetails, radius: number) => void;
  currentLocation?: PlaceDetails | null;
  onClearLocation?: () => void;
  radius?: number;
}

const LocationSearchModal: React.FC<LocationSearchModalProps> = ({
  isOpen,
  onClose,
  onLocationSelect,
  currentLocation,
  onClearLocation,
  radius = 25,
}) => {
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [localRadius, setLocalRadius] = useState(radius);
  const [selectedPlaceDetails, setSelectedPlaceDetails] = useState<PlaceDetails | null>(null);

  useEffect(() => {
    if (isOpen) {
      setAddress(currentLocation?.fullAddress || '');
      setLocalRadius(radius);
      setSelectedPlaceDetails(currentLocation);
    } else {
      setAddress('');
      setSuggestions([]);
      setSelectedPlaceDetails(null);
    }
  }, [isOpen, currentLocation, radius]);

  const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(0, Math.min(100, Number(e.target.value)));
    setLocalRadius(value);
  };

  const handleSubmit = () => {
    if (selectedPlaceDetails) {
      onLocationSelect(selectedPlaceDetails, localRadius);
      onClose();
    }
  };

  const fetchSuggestions = async (input: string) => {
    if (input.length < 3) {
      setSuggestions([]);
      return;
    }
    try {
      const results = await getPlaceSuggestions(input);
      setSuggestions(results);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const debouncedFetchSuggestions = debounce(fetchSuggestions, 300);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAddress(value);
    debouncedFetchSuggestions(value);
  };

  const handleSuggestionClick = async (suggestion: any) => {
    try {
      const details = await getPlaceDetails(suggestion.placeId);
      setSelectedPlaceDetails(details);
      setAddress(`${details.streetNumber} ${details.streetName}, ${details.city}, ${details.state}, ${details.zip}`);
      setSuggestions([]);
    } catch (error) {
      console.error('Error getting place details:', error);
    }
  };

  const handleClear = () => {
    setAddress('');
    setSuggestions([]);
    if (onClearLocation) {
      onClearLocation();
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx={4} maxWidth="500px">
        <ModalHeader>Search by Location</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4}>
            <Flex width="100%" position="relative">
              <Input
                placeholder="Enter an address..."
                value={address}
                onChange={handleAddressChange}
                pr={currentLocation ? "5rem" : "2rem"}
                autoFocus
              />
              {currentLocation && (
                <IconButton
                  aria-label="Clear location"
                  icon={<DeleteIcon />}
                  size="md"
                  position="absolute"
                  right={2}
                  top="50%"
                  transform="translateY(-50%)"
                  variant="ghost"
                  onClick={handleClear}
                  colorScheme="red"
                  zIndex={2}
                />
              )}
            </Flex>
            
            <FormControl>
              <FormLabel>Search Radius (miles)</FormLabel>
              <Input
                type="number"
                value={localRadius.toString()}
                onChange={handleRadiusChange}
                min="0"
                max="100"
              />
            </FormControl>

            {suggestions.length > 0 && (
              <List spacing={2} width="100%">
                {suggestions.map((suggestion, index) => (
                  <ListItem
                    key={index}
                    p={2}
                    cursor="pointer"
                    _hover={{ bg: "gray.50" }}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <Box display="flex" alignItems="center">
                      <Icon as={FaMapPin} mr={2} color="gray.500" />
                      <Text>{suggestion.description}</Text>
                    </Box>
                  </ListItem>
                ))}
              </List>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="black"
            color="white"
            mr={3}
            onClick={handleSubmit}
            isDisabled={!selectedPlaceDetails}
          >
            Apply Changes
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LocationSearchModal; 