import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Button,
} from '@chakra-ui/react';

interface CampaignEndedModalProps {
  isOpen: boolean;
  onClose: () => void;
  clientName: string;
}

const CampaignEndedModal: React.FC<CampaignEndedModalProps> = ({ isOpen, onClose, clientName }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="white"
        borderRadius="xl"
        boxShadow="xl"
        p={6}
        maxW="90%"
        mx="auto"
      >
        <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold" color="red.500">
          Campaign Ended
        </ModalHeader>
        <ModalBody>
          <Text textAlign="center" fontSize="lg" mb={4}>
            Unfortunately, this campaign from {clientName} has ended. Please tap the help button if you have any questions or would like to extend the time.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            bg="black"
            color="white"
            _hover={{ bg: "gray.800" }}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CampaignEndedModal;